<template>
    <div>
        <div class="box-contain box-containTwo">
            <div class="main-serve-searchBox">
                <div class="search-first">
                    <div class="search-title">精准搜索</div>
                    <el-form :model="form" :rules="rules" ref="form" label-width="75px">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="厂商" prop="manufacturerId">
                                    <el-select v-model="form.manufacturerId" placeholder="请选择厂商" clearable size="small"
                                        :loading="loading1">
                                        <el-option v-for="item in manufacturerList" :key="item.id" :label="item.name"
                                            :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="品牌" prop="brandId">
                                    <el-select v-model="form.brandId" placeholder="请选择品牌" clearable size="small"
                                        :loading="loading2">
                                        <el-option v-for="item in brandList" :key="item.id + '_8'"
                                            :label="item.brandName" :value="item.id" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="车型" prop="vehicleModelId">
                                    <el-select v-model="form.vehicleModelId" placeholder="请选择车型" clearable size="small"
                                        :loading="loading3">
                                        <el-option v-for="item in vehicleModelList" :key="item.id + '_9'"
                                            :label="item.vehicleModelName" :value="item.id" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="款型" prop="vehicleModelStyleId">
                                    <el-select v-model="form.vehicleModelStyleId" placeholder="请选择款型" clearable
                                        size="small" :loading="loading4">
                                        <el-option v-for="item in vehicleModelStyleList" :key="item.id"
                                            :label="item.styleName" :value="item.id" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="18">
                                <el-form-item label="VIN码" prop="vin">
                                    <el-input v-model="form.vin" placeholder="请输入17位VIN码" clearable size="small"
                                        maxlength="17" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" style="text-align: right">
                                <el-form-item label-width="0">
                                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">
                                        查询
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-refresh" size="mini" @click="resetQuery">
                                        重置
                                    </el-button>
                                </el-form-item>

                            </el-col>
                        </el-row>

                    </el-form>
                </div>
            </div>
            <div class="main-serve-searchBoxTwo">
                <div v-if="getIsToken">
                    <div class="main-title">
                        <span style="margin-right: 15px">您查看的车型是</span>
                        <span class="main-title-span" v-if="formName.manufacturerName">{{ formName.manufacturerName
                        }}</span>
                        <span class="main-title-span" v-if="formName.brandName">{{ formName.brandName }}</span>
                        <span class="main-title-span" v-if="formName.vehicleModelName">{{ formName.vehicleModelName
                        }}</span>
                        <span class="main-title-span" v-if="formName.vehicleModelStyleName">{{
                        formName.vehicleModelStyleName }}</span>
                    </div>
                    <div class="main-serve-content" v-if="dictData.length" v-loading="loading">
                        <div class="main-serve-list">
                            <div class="main-serve-list-li"
                                :class="currentClick.dictLabel === item.dictLabel || currentClick.modules === item.dictLabel ? 'clickCss' : ''"
                                v-for="(item, index) in dictData" @click="handelClick(item)">{{ item.dictLabel }}
                            </div>
                        </div>
                        <el-row :gutter="20" class="main-serve-bpttom" v-loading="loadingTwo">
                            <el-col :span="6" class="main-bpttom-left">
                                <div class="treeClass">
                                    <div v-if="currentClick.money" class="chooseTree">
                                        <el-tree :data="dataTreeList" :default-expand-all="false"
                                            :expand-on-click-node="false" node-key="id" @node-click="nodeClick"
                                            @node-expand="keepExpandedNode" :default-expanded-keys="expandedNodes"
                                            ref="tree" :check-strictly="true" highlight-current :props="{
                                                children: 'children',
                                                label: 'name',
                                                isLeaf: false
                                            }">
                                            <span slot-scope="{ node, data }" class="slot-tree-node">
                                                <div>{{ data.name }}</div>
                                                <!--                            <a :href="'#'+data.page">{{ data.name }}</a>-->
                                            </span>
                                        </el-tree>
                                    </div>
                                    <div v-if="!currentClick.money" class="main-serve-two-con"
                                        @click="tipMsg == '空'?false:goHref(form.brandId)">
                                        {{tipMsg == '空'?'此模块暂无信息':'试看结束，购买后查看全部信息'}}
                                    </div>

                                </div>
                            </el-col>
                            <el-col :span="18" class="main-bpttom-right" ref="imgRef">
                                <div v-loading="loadingThree">
                                    <div :ref="'refs' + Number(index + 1)" class="main-div-img"
                                        v-for="(item, index) in imgList">
                                        <img v-lazy="qianzhui+item" @load="loadimg(index)" :ref="'img'+index" />
                                        <!--                                       <img src="http://192.168.3.162:8080/maintenance/profile/money_image/%E4%B8%80%E6%B1%BD%E4%B8%B0%E7%94%B0/image/20220311/%E8%BD%A6%E8%81%94%E7%BD%91%E7%BD%91%E7%BB%9C%E5%AE%89%E5%85%A8%E5%92%8C%E6%95%B0%E6%8D%AE%E5%AE%89%E5%85%A8%E6%A0%87%E5%87%86%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE%E6%8C%87%E5%8D%97-0001.png" />-->

                                    </div>
                                </div>
                                <div v-if="!currentClick.money" class='noBuy'
                                    @click="tipMsg == '空'?false:goHref(form.brandId)"> {{tipMsg ==
                                    '空'?'此模块暂无信息':'试看结束，购买后查看全部信息'}} </div>

                            </el-col>
                        </el-row>
                    </div>
                    <div v-if="!maintenanceList.length" style="min-height: calc(100vh - 600px);"
                        class="main-serve-two-con" v-loading="loading">
                        暂无数据
                    </div>

                </div>
                <div v-else class="main-serve-two">
                    <div class="main-serve-two-con">用户您好，登录后可查看免费维修信息内容！</div>

                </div>
                <div class="fixed-div">
                    <div class="fixed-com fixed-down" @click="downFile">下载</div>
                    <div class="fixed-com fixed-print" @click="printFiles">打印</div>

                </div>
            </div>

        </div>
        <Vfooter />
        <el-dialog title="" :visible.sync="tipOpen" width="30%" center>
            <div style="text-align: center">用户您好，登录后可查看免费维修信息内容！</div>
            <span slot="footer" class="dialog-footer">

                <el-button type="primary" @click="showLogin('1')">登 录</el-button>
                <el-button type="primary" @click="showLogin('2')">还没有账号，去注册</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import Vfooter from "@/components/footer.vue";
import { manufacturer, hotBrand, getVehicle } from "@/api/Index.js";
import { getUserInfo, getToken } from "@/utils/auth.js";
import { dict } from "@/api/dict"
// 获取维修信息请求接口
import {
    getVehicleModelStyle, maintenanceServiceList, getFileTree, getRightImg, printFile, downFile
} from "@/api/maintenanceService/index.js";
//字典管理

export default {
    components: {
        Vfooter,
    },
    watch: {
        'form.manufacturerId': {
            handler(val) {
                if (val) {
                    this.getBrandList(val);
                    this.currentVechile('manufacturer', val, 'name')
                    this.restSetting(['brand', 'vehicleModel', 'vehicleModelStyle'])
                } else {
                    this.brandList = [];
                    this.restSetting(['manufacturer', 'brand', 'vehicleModel', 'vehicleModelStyle'])
                }

            },
        },
        'form.brandId': {
            handler(val) {
                if (val) {
                    this.getVehicleModelList(val);
                    this.currentVechile('brand', val, 'brandName')

                    this.restSetting(['vehicleModel', 'vehicleModelStyle'])
                } else {
                    this.vehicleModelList = [];
                    this.restSetting(['brand', 'vehicleModel', 'vehicleModelStyle'])
                }

            },
        },
        'form.vehicleModelId': {
            handler(val) {
                if (val) {
                    this.getVehicleModelStyleList(val);
                    this.currentVechile('vehicleModel', val, 'vehicleModelName')

                    this.restSetting(['vehicleModelStyle'])

                } else {
                    this.vehicleModelStyleList = [];
                    this.restSetting(['vehicleModel', 'vehicleModelStyle'])
                }

            },
        },
        'form.vehicleModelStyleId': {
            handler(val) {
                if (val) {
                    this.currentVechile('vehicleModelStyle', val, 'vehicleModelStyleName')


                } else {
                    this.restSetting(['vehicleModelStyle'])
                }

            },
        },
        manufacturerList: {
            handler(val) {
                this.currentVechile('manufacturer', this.form.manufacturerId, 'name')
            },
            deep: true,
        },
        brandList: {
            handler(val) {
                this.currentVechile('brand', this.form.brandId, 'brandName')
            },
            deep: true,
        },
        vehicleModelList: {
            handler(val) {

                if (JSON.stringify(this.$route.query) !== '{}' && this.$route.query.vehicleModel === '-1') {
                    if (val && val.length) {
                        this.$set(this.form, 'vehicleModelId', val[0].id);
                        this.handleQuery();
                        this.$router.replace('/maintenanceService/index')
                    } else {
                        this.handleQuery();
                        this.$router.replace('/maintenanceService/index')
                    }
                }
                this.currentVechile('vehicleModel', this.form.vehicleModelId, 'vehicleModelName')
            },
            deep: true,
        },

    },
    data() {
        return {
            // //是否购买
            // isBuy:true,
            getIsToken: false,
            pdfUrl: '',
            chooseTree: {},
            expandedNodes: [],
            loading: false,
            loadingTwo: false,
            loadingThree: false,
            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            tipOpen: false,
            dataTreeList: [],
            currentClick: {},
            form: {
                manufacturerId: '',
                brandId: '',
                vehicleModelId: '',
                vehicleModelStyleId: '',
                vin: '',

            },
            formName: {
                manufacturerName: '',
                brandName: '',
                vehicleModelName: '',
                vehicleModelStyleName: '',
            },
            manufacturerList: [],
            brandList: [],
            vehicleModelList: [],
            vehicleModelStyleList: [],
            //维修列表
            maintenanceList: [],
            imgList: [
                // {img:require('../../assets/img/banner1.png')},
                // {img:require('../../assets/img/banner1.png')},
                // {img:require('../../assets/img/banner1.png')},
                // {img:require('../../assets/img/banner1.png')},
                // {img:require('../../assets/img/banner1.png')},
            ],
            rules: {
                vin: [
                    {
                        min: 17,
                        max: 17,
                        required: true,
                        message: '请输入17位VIN码',
                        trigger: 'blur'
                    },
                ],
            },
            dictData: [],
            tipMsg: "",
            // qianzhui:process.env.NODE_ENV === "production" ? "/wxy/" : ""
            qianzhui: ""
        };
    },
    computed: {},
    created() {
        this.getIsToken = getUserInfo();
        //获取厂商列表
        this.getManufacturer();
        if (JSON.stringify(this.$route.query) !== '{}') {
            this.getSeach(this.$route.query)

        }
    },
    methods: {
        dict(type) {
            return dict(type).then(res => {
                this.dictData = res.data
            })
        },
        getSeach(obj, filed) {
            let arr = ['vin', 'manufacturerId', 'brandId', 'vehicleModelId',];
            arr.forEach(item => {
                if (obj[item]) {
                    if (item === 'vin') {
                        this.$set(this.form, item, obj[item])
                    } else {
                        this.$set(this.form, item, Number(obj[item]))
                    }

                }
            })
            if (this.$route.query.vehicleModel !== '-1') {
                this.handleQuery();
                this.$router.replace('/maintenanceService/index')
            }
            console.log(this.form, 'this.form')

        },
        //是否弹出提示
        getIsDiag() {
            if (!getUserInfo()) {
                this.tipOpen = true
                return true
            } else {
                return false
            }
        },
        //
        translateDataToTree(data) {
            //没有父节点的数据
            let parents = data.filter((value) => value.pName == 'undefined' || value.pName == null || value.pName == 0 || value.pName == '0')

            //有父节点的数据
            let children = data.filter((value) => value.pName !== 'undefined' && value.pName != null)
            //定义转换方法的具体实现
            let translator = (parents, children) => {
                //遍历父节点数据
                parents.forEach((parent) => {
                    //遍历子节点数据
                    children.forEach((current, index) => {
                        //此时找到父节点对应的一个子节点
                        if (current.pName + current.fileId === parent.name + parent.fileId) {
                            //对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
                            let temp = JSON.parse(JSON.stringify(children))
                            //让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
                            temp.splice(index, 1)
                            //让当前子节点作为唯一的父节点，去递归查找其对应的子节点
                            translator([current], temp)
                            //把找到子节点放入父节点的children属性中
                            typeof parent.children !== 'undefined' ? parent.children.push(current) : (parent.children = [current])
                        }
                    })
                })
            }

            //调用转换方法
            translator(parents, children)

            //返回最终的结果
            return parents
        },
        // 节点点击事件
        nodeClick(data, node, el) {
            this.loadingThree = true
            let prevObj = JSON.parse(JSON.stringify(this.chooseTree))

            // if (prevObj.fileId == data.fileId) {
            //     console.log(22)
            //     this.chooseTree = data
            //     if (this.$refs['refs' + data.page][0]) {
            //         this.loadingThree = false
            //         this.$refs.imgRef.$el.scrollTop = this.$refs['refs' + data.page][0].offsetTop - 40
            //     }
            //
            // } else {
                this.chooseTree = data
                getRightImg({ id: data.fileId, money: this.currentClick.money }).then(res => {
                    this.loadingThree = false
                    if (res.code == 200) {
                        this.imgList = res.data
                        // this.$nextTick(()=>{
                        if (this.$refs['refs' + data.page][0]) {

                            this.$refs.imgRef.$el.scrollTop = this.$refs['refs' + data.page][0].offsetTop - 40
                        }


                        // })
                    }

                }).catch(e => {
                    this.loadingThree=false
                })
            // }


        },
        loadimg(index) {
            if (index + 1 == this.chooseTree.page) {
                this.loadingThree = false
            }
            let imgRef = this.$refs['img' + index];
            let w = imgRef[0].naturalWidth
            if (w <= 640) {
                imgRef[0].style.width = w + 'px'
            } else {
                imgRef[0].style.width = '100%'
            }

        },
        keepExpandedNode(nodeData, node, tree) {

            // if (!nodeData.isLeaf) {
            //   this.expandedNodes.push(this.cloneObj(nodeData))
            // // this.expandedNodes.push(nodeData.id)
            // }
            // console.log(this.expandedNodes, 1212121)
        },
        //维修信息
        handelClick(item) {

            this.currentClick = item
            this.maintenanceList.forEach(v => {
                if (v.modules == item.dictLabel) {
                    this.currentClick = v;
                }
            })
            if (this.currentClick.money) {
                this.loadingTwo = true
                getFileTree({ repairId: this.currentClick.id }).then(res => {
                    this.loadingTwo = false
                    if (res.code == 200) {
                        this.dataTreeList = this.translateDataToTree(res.data)
                        if (this.dataTreeList && this.dataTreeList.length) {
                            this.nodeClick(this.dataTreeList[0])
                        } else {
                            this.imgList = []
                            this.chooseTree = {}
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(e => {
                    this.loadingTwo = false
                })
            } else {
                this.loadingThree = true
                getRightImg({ repairId: this.currentClick.id, money: this.currentClick.money }).then(res => {
                    this.loadingThree = false
                    if (res.code == 200) {
                        this.imgList = res.data
                        this.tipMsg = res.msg

                        // })
                    }

                }).catch(e => {
                    this.loadingThree = false
                })
            }

        },
        //清除
        restSetting(arr) {
            arr.forEach(item => {
                this.$set(this.formName, item + 'Name', '')
                this.$set(this.form, item + 'Id', '')
            })

        },
        //获取当前选择车型
        currentVechile(feild, val, label) {
            let currentArr = []
            currentArr = this[feild + 'List'].filter(item => {
                return item.id == val
            })
            if (currentArr.length) {
                this.$set(this.formName, feild + 'Name', currentArr[0][label])
            } else {
                this.$set(this.formName, feild + 'Name', '')
            }

        },
        //获取厂商列表
        getManufacturer() {
            this.loading1 = true;
            manufacturer().then(res => {
                this.loading1 = false;
                this.manufacturerList = res;
                // if (res.code == 200) {
                //     this.manufacturerList = res
                // } else {
                //     this.$message.error(res.msg)
                // }
            }).catch(() => {
                this.loading1 = false
            })
        },
        //获取品牌列表
        getBrandList(val) {
            this.loading2 = true;
            hotBrand({ pageSize: 99999, pageNum: 1, manufacturerId: val }).then(res => {
                this.loading2 = false;

                if (res.code === 200) {
                    this.brandList = res.data;
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(() => {
                this.loading2 = false
            })
        },
        //获取车型列表
        getVehicleModelList(id) {
            this.loading3 = true;
            getVehicle({ brandId: id }).then(res => {
                this.loading3 = false;
                if (res.code === 200) {
                    this.vehicleModelList = res.data
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(() => {
                this.loading3 = false
            })
        },
        //获取款型列表
        getVehicleModelStyleList(id) {
            this.loading4 = true;
            getVehicleModelStyle({ vehicleModelId: id }).then(res => {
                this.loading4 = false;
                if (res.code === 200) {
                    this.vehicleModelStyleList = res.data
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(() => {
                this.loading4 = false
            })
        },
        //查询
        handleQuery() {
            let userInfo = getUserInfo();
            if (!getUserInfo()) {
                this.tipOpen = true;
                return
            }
            let params = {};
            if (!this.form.vin) {
                if (!this.form.manufacturerId) {
                    return this.$message.warning('请选择厂商')
                }
                if (!this.form.brandId) {
                    return this.$message.warning('请选择品牌')
                }
                if (!this.form.vehicleModelId) {
                    return this.$message.warning('请选择车型')
                }
                params = Object.assign({}, this.form)
                delete params.vin
            } else {
                this.form.manufacturerId = ''
                this.form.brandId = ''
                this.form.vehicleModelId = ''
                this.form.vehicleModelStyleId = ''
                params = {
                    vin: this.form.vin
                }
            }

            //获取模块列表
            this.dict('modules').then(() => {
                this.getListMe(params)
            });




        },
        getListMe(params) {
            this.loading = true
            maintenanceServiceList(params).then(res => {
                this.loading = false
                if (res.code == 200) {

                    if (res.data.length>0){
                        this.maintenanceList = res.data
                        this.formName.manufacturerName = res.data[0].manufacturerName
                        this.formName.brandName = res.data[0].brandName
                        this.formName.vehicleModelName = res.data[0].vehicleModelName
                        this.formName.vehicleModelStyleName = res.data[0].vehicleModelStyleName
                    }else{
                        this.maintenanceList = []
                        this.formName.manufacturerName = ''
                        this.formName.brandName = ''
                        this.formName.vehicleModelName = ''
                        this.formName.vehicleModelStyleName = ''
                    }


                    this.handelClick(this.dictData[2])


                    // this.$nextTick(()=>{
                    //   this.form.brandId = res.data[0].brandId
                    //   this.form.vehicleModelId = res.data[0].vehicleModelId
                    // })
                    // if(this.maintenanceList&&this.maintenanceList.length){
                    //      this.isBuy=this.maintenanceList[0].money
                    // }
                    // if (this.maintenanceList.length) {
                    //     this.maintenanceList.forEach(item => {
                    //         if (item.modules == '保养信息') {
                    //             this.handelClick(item)
                    //         }
                    //     })

                    // }


                }
            }).catch(e => {
                this.loading = false
            })
        },
        //显示登录弹窗
        showLogin(val) {
            this.tipOpen = false
            this.$store.dispatch('common/SET_LoginType', val)
            this.$Vlogin({ flag: true, currentTab: val })
        },
        // 重置
        resetQuery() {
            let userInfo = getUserInfo();
            if (!getUserInfo()) {
                this.tipOpen = true
                return
            }
            this.resetForm("form");
            this.restSetting(['manufacturer', 'brand', 'vehicleModel', 'vehicleModelStyle'])
            this.$set(this.form, 'vin', '')
            this.dataTreeList = []
            this.currentClick = {}
            this.chooseTree = {}
            this.maintenanceList = []
            this.imgList = []
            // this.

        },
        //打印
        printFiles() {
            let _this = this
            if (this.getIsDiag()) {
                return
            }
            if (!this.maintenanceList.length || !this.dataTreeList.length) {
                // return this.$message.error('购买后即可下载文件')
                return this.$message.warning('请先选择您要查看的资料')
            }
            if (!this.currentClick.allowOperationFile) {
                // return this.$message.error('购买后即可打印文件')
                return this.$message.warning('请先购买')
            }
            this.loadingTwo = true

            // window.open("dev-api/user/repairFile/printFile?id=" + this.chooseTree.fileId)
            printFile({ id: this.chooseTree.fileId, repairId: this.currentClick.id }).then(res => {
                let readerThr = new FileReader(); // FileReader() 读取文件
                readerThr.readAsText(res); // readAsDataURL() 用于读取指定Blob或File的内容
                readerThr.onload = function (e) {
                    try {
                        let a = JSON.parse(e.target.result);
                        _this.$message.error(a.msg)
                        _this.loadingTwo = false
                        // if(a&&a.respCode=="-1"){
                        //     this.$message.error(res.msg)
                        // }
                    } catch (e) {
                        _this.getObjectURL(res)
                    }
                }

                // if(res.code==500){
                //     this.$message.error(res.msg)
                // }else {
                //     this.getObjectURL(res)
                // }

            })

        },
        // 将返回的流数据转换为url（data: 文档流数据）
        getObjectURL(data) {
            let url = null
            let file = new Blob([data], { type: 'application/pdf;chartset=utf-8' })
            if (window.createObjectURL !== undefined) {
                // 通用
                url = window.createObjectURL(file)
            } else if (window.webkitURL !== undefined) {
                // 兼容谷歌
                try {
                    url = window.webkitURL.createObjectURL(file)
                } catch (error) {

                }
            } else if (window.URL !== undefined) {
                // 兼容其他
                try {
                    url = window.URL.createObjectURL(file)
                } catch (error) {

                }
            }
            // 将转化后url赋值
            this.loadingTwo = false;
            window.open(url)
        },
        goHref(id) {
            this.$router.push({ path: "/technologyBuy/buyChoice", query: { ids: id } })
        },
        compatible(val) {
            let returnVal = '';
            if (window.createObjectURL !== undefined) {
                // 通用
                returnVal = window.createObjectURL(val)
            } else if (window.webkitURL !== undefined) {
                // 兼容谷歌
                try {
                    returnVal = window.webkitURL.createObjectURL(val)
                } catch (error) {

                }
            } else if (window.URL !== undefined) {
                // 兼容其他
                try {
                    returnVal = window.URL.createObjectURL(val)
                } catch (error) {

                }
            }
            return returnVal

        },
        //下载
        downFile() {
            //    downFile
            //     downFile({id:this.currentClick.id}).then(res=>{
            //         // window.open(res)
            //     })
            if (this.getIsDiag()) {
                return
            }
            if (!this.maintenanceList.length) {
                // return this.$message.error('购买后即可下载文件')
                return this.$message.warning('请先选择您要查看的资料')
            }
            if (!this.dataTreeList.length) {
                // return this.$message.error('购买后即可下载文件')
                return this.$message.warning('该模块下无可下载内容')
            }
            if (!this.currentClick.allowOperationFile) {
                // return this.$message.error('购买后即可下载文件')
                return this.$message.warning('请先购买')
            }
            this.loadingTwo = true;
            let _this = this;
            // this.loadingTwo=true
            var url = this.$actionUp + '/user/repairFile/downloadZipFile?repairId=' + this.currentClick.id;		// 请求的url + id
            var xhr = new XMLHttpRequest();
            xhr.open("post", url, true);
            xhr.responseType = "blob";
            // xhr.setRequestHeader("Content-Type", "application/json;charset=utf-8");
            xhr.setRequestHeader("Content-Type", "application/json;charset=utf-8");
            xhr.setRequestHeader("Authorization", getToken());			// 自定义请求头
            // let flagThr=true;
            // xhr.onprogress=function(e){
            //
            //     // if(e.loaded>0){
            //     //     if(flagThr) {
            //     //         flagThr = false;
            //     //         _this.loadingTwo = false
            //     //     }
            //     // }
            //
            // }
            xhr.onload = function () {

                if (this.status === 200) {
                    var blob = this.response;
                    let blobTwo = this.response;
                    let readerThr = new FileReader(); // FileReader() 读取文件
                    readerThr.readAsText(blob); // readAsDataURL() 用于读取指定Blob或File的内容
                    readerThr.onload = function (e) {
                        try {
                            let a = JSON.parse(e.target.result);
                            _this.$message.error(a.msg);
                            _this.loadingTwo = false
                            // if(a&&a.respCode=="-1"){
                            //     this.$message.error(res.msg)
                            // }
                        } catch (e) {
                            var fileName = _this.currentClick.modules + '.zip';
                            if (window.navigator.msSaveOrOpenBlob) {			// IE浏览器下
                                var blob = new Blob([xhr.response], { type: 'application/force-download' });
                                window.navigator.msSaveBlob(blob, fileName);
                                // navigator.msSaveBlob(blob);
                                _this.loadingTwo = false
                                // _this.loadingTwo=false
                            } else {
                                var link = document.createElement("a");
                                // link.href = window.URL.createObjectURL(blob);
                                link.href = _this.compatible(blobTwo);
                                link.download = fileName;
                                link.click();
                                window.URL.revokeObjectURL(link.href);
                                _this.loadingTwo = false
                            }
                        }
                    }

                } else {
                    // _this.loadingTwo=false
                    _this.$message.error('下载失败')
                }
            };
            xhr.send()

        },


    },
};
</script>
<style lang="scss" scoped>
.main-serve-searchBox {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;

    .search-title {
        font-weight: 700;
        line-height: 40px;
    }

}

.main-serve-searchBoxTwo {
    position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 20px;
    padding: 10px 10px;

    .main-serve-content {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 500px;
        padding-left: 1%;

        .main-serve-list {

            .main-serve-list-li {
                border-radius: 8px;
                display: inline-block;
                width: 8%;
                margin-right: 1%;
                line-height: 35px;
                cursor: pointer;
                text-align: center;

            }

            .clickCss {
                background-color: #0D57BC;
                color: #fff;

            }

        }

        .main-serve-bpttom {
            padding-top: 20px;
            padding-bottom: 24px;
            height: 100%;

            .main-bpttom-left {
                padding: 10px 0 20px 10px !important;
                height: 100%;
                background-color: #F0F8FA;
                overflow: auto;
            }
        }
    }

    .main-bpttom-right {
        height: 100%;
        overflow: auto;
        padding: 20px 20px;

        .main-div-img {
            text-align: center;
            margin-bottom: 20px;

            img {
                width: 90%;
            }
        }

    }

}

.main-serve-two {
    margin-top: 20px;
    margin-bottom: 20px;
    height: calc(100vh - 600px);
    padding-left: 1%;

}

.main-serve-two-con {
    font-size: 15px;
    text-align: center;
    line-height: 50px;
}

.main-title {
    line-height: 45px;
    background-color: #0D57BC;
    border-radius: 8px;
    padding: 0 10px;
    color: #fff;

    .main-title-span {
        border-bottom: 1px solid #fff;
        margin: 0 10px;
        font-size: 12px;
    }
}

::v-deep .el-select {
    width: 100%;
}

::v-deep .el-tree {
    background-color: #F0F8FA;
}

.treeClass ::v-deep.el-tree-node>.el-tree-node__children {
    overflow: visible;
}

::v-deep.el-tree-node__label {
    /*padding-right: 10px;*/
}

::v-deep .el-tree-node__content:hover {
    background-color: rgba(0, 0, 255, 0.04);
}

::v-deep .chooseTree .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    background-color: rgba(0, 0, 255, 0.04);
}

.fixed-div {
    /*position: fixed;*/
    position: absolute;
    right: -60px;
    top: 10px;
    /*transform: translateY(-50%);*/

    .fixed-com {
        cursor: pointer;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px solid #f2f2f2;
        background-color: #fff;
        margin-bottom: 10px;
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, .1);
    }

    .fixed-com:hover {
        color: #0D57BC;
    }
}

.noBuy {
    line-height: 30px;
    font-size: 16px;
    text-align: center;
    color: #0D57BC;
    cursor: pointer;
}
</style>
