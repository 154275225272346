const state = {
    qiehuan: "",
    //相关推荐多少未显示
    ListFlagIndex: 0,
    //相关推荐循环几次
    ListIndex: 0,
    //是否显示暂无推荐
    ListFlag: false,
    loginType:'1',
}

const mutations = {
    SET_qiehuan(state, n) {
        state.qiehuan = n
    },
    SET_LoginType(state, n) {
        state.loginType = n
    },
    SET_ListFlagIndex(state) {
        state.ListFlagIndex++
    },
    SET_ListIndex(state) {
        state.ListIndex++
    },
    SET_ListFlagg(state,n) {
        state.ListFlag = n
    },
    SET_ListFlag(state) {
        if (state.ListFlagIndex == state.ListIndex) {
            state.ListFlag = true
        } else {
            state.ListFlag = false
        }
    }
}
const actions = {
    SET_LoginType({ commit }, n) {
        commit('SET_LoginType', n)
    },
    SET_qiehuan({ commit }, n) {
        commit('SET_qiehuan', n)
    },
    SET_ListFlagIndex({ commit }) {
        commit('SET_ListFlagIndex')
    },
    SET_ListIndex({ commit }) {
        commit('SET_ListIndex')
    },
    SET_ListFlag({ commit }) {
        commit('SET_ListFlag')
    },
    SET_ListRest({state}) {
        state.ListFlagIndex= 0
        state.ListIndex= 0
        state.ListFlag= false
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
