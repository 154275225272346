<template>
    <div>
        <div class="box-message-div">
            <div class="message-header">
                <slot name="slotNameVal" class="headerName"></slot>
                <slot name="viewMore" class="headerMore">查看更多</slot>
            </div>
            <div>
                <div style="background-color: #f2f2f2;">
                    <div class="table-ul" v-loading="loading">
                        <div class="table-li"  @click="goToDetail(item)" v-for="(item,index) in tableList" :key="index"
                             v-if="tableList.length">
                            <div class="table-div">
                                <div class="table-title">{{item.title}}</div>
                                <div class="table-time">{{item.publishDate}}</div>
                            </div>

                        </div>
<!--                        <div v-if="!tableList.length" class="no-list">-->
<!--                            <i class="iconfont icon-tupian">-->

<!--                            </i>-->
<!--                            <span class="no-list-word">-->
<!--                                    没有找到此{{typeName}}-->
<!--                                </span>-->

<!--                        </div>-->
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>
<script>
    import {newPolicy} from "@/api/Index.js";

    export default {

        data() {
            return {
                loading: false,
                typeName: '公告',
                total: 0,
                form: {
                    title: '',
                    pageNum: 1,
                    pageSize: 10,
                },
                tableList: []

            };
        },
        props:{
            // tableList:{
            //     type:Array,
            // },
            currentType:{
                type:String,
            },
            currentLoadState:{
                type:Boolean,
            }
        },
        created() {
              this.getPolicy()

        },
        mounted() {
        },
        methods: {
            getPolicy() {
                this.loading=true
                newPolicy({pageNum:1,pageSize:10,type:this.currentType,}).then(res=>{
                    this.loading=false
                    if(res.code==200){
                        this.tableList=res.rows.splice(0,5)
                    }
                }).catch(e=>{
                    this.loading=false
                })
            },
            goToDetail(item){
                this.$router.push({path: '/messageDetail', query: {type: this.currentType, id: item.id}})
            }
        }
    };
</script>
<style lang="scss" scoped>
    .box-message-div {
        border-radius: 8px;

        background-color: #f2f2f2;

    }
    .message-header {
        color: #fff;
        font-size: 16px;
        height: 45px;
        padding: 10px 20px;
        border-radius: 8px 8px 0 0;
        background: url('../../assets/img/policy.png') center center;
        background-size:cover;
    }

    .table-ul {
        position: relative;
        margin-bottom: 20px;
        background-color: #f2f2f2;
        color: #333;
        min-height: 211px;
        border: 1px solid #ccc;
        border-top: none;

        .table-li {
            border-bottom: 1px solid #ccc;

            .table-div {
                padding: 10px 20px;
                display: flex;
                cursor: pointer;
                justify-content: space-between;

                .table-title {

                    max-width: 70%;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space:nowrap;

                }


                .table-time {
                    color: #bbb
                }

            }
            .table-div:hover {
                color: #0D57BC ;
                .table-time {
                    color: #0D57BC
                }
            }


        }
        .table-li:last-child {
            border-bottom: none;
        }

        .no-list {
            width: 400px;
            text-align: center;
            color: #ddd;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            i {
                font-size: 80px;
            }
            .no-list-word {
                font-size: 16px;
                color: #ccc;
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

    }
</style>
