<template>
  <div>
    <div class="box-contain box-containThree">
      <Breadcrumb></Breadcrumb>
      <div class="buy-div box-contain-divThree">
          <div class="buy-list-box" v-for="item in dataList" :key="item.id">
              <div class="buy-list-l">
                <span>{{ item.brandName }}</span>
              </div>
              <div class="buy-list-r">
                  <div class="buy-list-item" v-for="citem in item.setMealList" :key="citem.id">
                    <h1>{{ item.brandName }}资料浏览权益（{{ citem.name }}）</h1>
                    <div class="buy-list-select">
                      <div class="buy-list-select-l">
                        <ul class="buy-list-ul">
                          <li>{{ citem.introduction }}</li>
                          <li>{{ citem.endIntroduction }}</li>
                        </ul>
                        <table border="1" class="buy-list-table">
                            <thead>
                              <tr>
                                <td width="271">会员类型</td>
                                <td width="330">平台售价</td>
                              </tr>
                            </thead>
                          <tbody>
                            <tr>
                              <td>个人会员<template v-if="userType=='1'">（您的身份）</template></td>
                              <td>{{ citem.ordinaryMember }}</td>
                            </tr>
                            <tr>
                              <td>企业会员<template v-if="userType=='2'">（您的身份）</template></td>
                              <td>{{ citem.enterpriseMembe }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="buy-list-select-r">
                        <input  type="radio" class="buy-radio" :name="'caotan'+item.id" :value="citem.id" v-model="form['caotan'+item.id]" @click="onchange(item,citem)"/>
                      </div>
                    </div>
                  </div>
              </div>
          </div>


        <div class="text-center">
            <button class="buy-btn" @click="toHref">立即购买</button>
        </div>

      </div>

    </div>
    <Vfooter/>
  </div>
</template>
<script>
import Vfooter from "@/components/footer.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
// 获取信息购买请求接口
import { technologyBuyList,technologyBuy } from "@/api/technologyBuy/index.js";
//字典管理
import {} from "@/api/dict.js";
import {getUserInfo} from "@/utils/auth";

export default {
  components: {
    Vfooter,
    Breadcrumb
  },
  data() {
    return {
      dataList:[],
      form:{},
      formData:[],
      userType:"1"
    };
  },
  computed: {},
  created() {
    this.userType = getUserInfo().userType
    this.technologyBuyList()
  },
  methods: {
    technologyBuyList(){
      let brandIdListString = {
        brandIdListString:this.$route.query.ids
      }
      technologyBuyList(brandIdListString).then(res=>{
          if (res.code == 200){
              this.dataList = res.data
              this.dataList.forEach(item=>{
                this.formData.push({
                  brandId:item.id,
                  mealId:""
                })
              })
          }
      })
    },
    toHref(){
      for (let i=0;i<this.formData.length;i++){
        if (this.formData[i].mealId==''){
          return this.$message.error('还有权益未选择')
        }
      }
      technologyBuy(this.formData).then(res=>{
        if (res.code == 200){
            sessionStorage.setItem('buyInfo',JSON.stringify(res.data))
            this.$router.push({path:"/technologyBuy/checkOrder"})
        }
      })

    },
    onchange(item,citem){
      this.formData.forEach(el=>{
        if (el.brandId == item.id){
          el.mealId = citem.id
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.buy-div {
  border: 1px solid #c3c3c3;
  padding: 20px;
  background: #fff;
}
.buy-list-box{
  display: flex;
  padding: 20px 0;
  margin-bottom: 20px;
  background: #f1f1f1;
  .buy-list-l{
    display: flex;
    align-items: center;
    color: #000;
    font-size: 15px;
    padding: 0 20px;
    border-right: 1px solid #d7d7d7;
  }
  .buy-list-r{
    flex: 1;
    padding: 0 10px;
    .buy-list-item{
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #797979;
      &:last-child{
        border: none;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
      h1{
        background: #5f5ffa;
        color: #fff;
        line-height: 48px;
        font-weight: bold;
        font-size: 18px;
        padding-left: 15px;
      }
    }
    .buy-list-select{
      display: flex;
      margin: 0 20px;

    }
    .buy-list-select-r{
      align-self: center;
      margin-left: auto;
    }
    .buy-list-ul{
      margin-top: 15px;
      margin-bottom: 15px;
      li{
        position: relative;
        padding-left: 12px;
        margin-bottom: 5px;
        font-size: 15px;
        color: #000;
        &:before{
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -3px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #000;
        }
      }
    }
    .buy-list-table{
      text-align: center;
      font-size: 15px;
      thead{
        background: #4444ce;
        color: #fff;
      }
      tbody{
        background: #fff;
        //tr:first-child td{
        //  height: 81px;
        //}
      }
      tr td{
        height: 55px;
      }
    }
    .buy-radio{
      width: 40px;
      height: 40px;
    }
  }
}
.buy-btn{
  width: 500px;
  height: 60px;
  border: none;
  border-radius: 10px;
  background: #f59a23;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}
</style>
