<template>
    <div class="bif-box">
        <div class="notice-Box">
            <div class="logo-notice"> <img class="logo-login" src="@/assets/img/logoComplete.png" alt=""/></div>
            <div class="notice-div">
                <div class="notice-div-title">关于机动车维修技术信息网升级维护的通知</div>
                <div class="notice-div-name">尊敬的用户：</div>
                <div>您好！</div>
                <div class="notice-div-p">
                    很抱歉的告知您，机动车维修技术信息网（www.weixiuinfo.com）需要进行升级维护，当前已停止服务，您无法正常访问。
                    维修技术信息公开相关功能预计4月中旬恢复正常。在此期间由于网站不能正常运营给您带来的不便，还望见谅。
                </div>
                <div class="notice-div-p">
                    网站升级维护期间，如您有获取相关车辆维修信息或其他业务需求，请您联系网站运营负责人，<br />
                    邮箱：yuhao@catarc.ac.cn;lipuchao@catarc.ac.cn。<br />
                    我们在收到邮件后，将会在第一时间与您取得联系。
                </div>
                <div class="notice-div-p">
                    感谢您对机动车维修技术信息网站的支持和关注！我们将通过本次升级维护，为您提供更好的服务！
                </div>
                <div class="notice-div-right" style="margin-top: 20px;">
                    中汽数据有限公司
                </div>
                <div class="notice-div-right">
                    2022年3月8日
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    export default {

        data() {
            return {

            };
        },

        created() {

        },
        mounted() {
        },
        methods: {
        },
    };
</script>
<style lang="scss" scoped>
    .bif-box{
        box-sizing: border-box;
        padding: 10px 10px 10px 10px;
        background-color: #E9E9EB;
    }
   .notice-Box {

       position: relative;
       /*width: 90%;*/
       min-height: calc(100vh - 20px);
       margin: auto;
       padding-bottom: 20px;
       /*background-color: #E8E9ED;*/
       background: linear-gradient( #E8E9ED,#fbfbfb, #E8E9ED);
       border: 1px solid #fff;
       color: #000;
       font-size: 14px;
       .logo-notice {
           padding-top: 20px;
           padding-left: 20px;
           height: 100px;
           img {
               height: 100%;
           }

       }
       .notice-div {
           padding-left: 10%;
           padding-right: 10%;

           .notice-div-title {
               text-align: center;
               font-weight: 700;
               font-size: 20px;
               margin-top: 40px;
               margin-bottom: 20px;
           }
           .notice-div-name{
               font-weight: 700;
               line-height: 50px;

           }
           .notice-div-p{
               line-height: 30px;
               padding-top: 10px;
               padding-bottom: 10px;

           }
           .notice-div-right {
              text-align: right;
               font-weight: 700;

           }

       }
   }
</style>
