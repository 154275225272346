<template>
    <div>
        <div class="box-contain box-containThree">
<!--            <Breadcrumb></Breadcrumb>-->
            <div class="box-contain-div box-contain-divThree">
                <div class="box-contain-div-tit">关于我们</div>
                <div class="aboutUs">
                    <img src="../../assets/img/aboutUs.png"/>
                </div>
                <div class="aboutUs-p" v-for="item in obj">
                    <div class="aboutUs-title" >{{item.title}}：</div>
                    <div class="aboutUs-content" v-for="it in item.content" v-html="it"></div>
                </div>
                <div  v-loading="loading">
                    <div class="aboutUs-title" >联系我们</div>
                    <p class="connectionUs-p">
                        商务合作：{{content.businessCooperation?content.businessCooperation:'无'}}
                    </p>
                    <p class="connectionUs-p">
                        联系电话：{{content.phoneNumber}}
                    </p>
                    <p class="connectionUs-p">
                        联系邮箱：{{content.email}}
                    </p>
                    <p class="connectionUs-p">
                        联系地址：{{content.address}}
                    </p>
                    <div class="addressImg">
<!--                        <img :src="content.imagePath"/>-->
                                            <img src="../../assets/img/adress.png" />
                    </div>


                </div>
            </div>

        </div>
        <Vfooter/>
    </div>
</template>
<script>
    import Vfooter from "@/components/footer.vue";
    import {connentUs} from "@/api/footer/index.js";
    // import Breadcrumb from "@/components/breadcrumb.vue";

    export default {

        data() {
            return {
                obj: [
                    {
                        title: '网站简介',
                        content: [
                            '机动车维修技术信息网（<a href="http://www.weixiuinfo.com/" target="_blank">http://www.weixiuinfo.com/</a>）是中汽数据有限公司针对汽车后市场行业的政策变化和企业实际需求，精心打造的一站式技术服务平台，服务内容以维修技术信息发布为主，同时具备政策跟踪解读、行业资讯、后市场行业研究、行业会议与论坛组织等多个领域服务能力。面向汽车生产企业、维修厂商、保险机构等行业主体参与者以及普通消费者，提供及时、准确、专业的服务。'
                          ],
                    },
                    {
                        title: '主办单位简介',
                        content: ['中国汽车技术研究中心有限公司（简称中汽中心）是1985年根据国家对汽车行业管理的需要，经国家批准成立的科研院所，现隶属于国务院国有资产监督管理委员会，是行业技术归口单位和国家政府主管部门的技术支撑机构。'],
                    },
                    {
                        title: '中汽数据有限公司',
                        content: [
                            '中汽数据有限公司是中汽中心全资子公司，以汽车大数据为基础，汽车领域模型算法为支柱，深入开展节能低碳、绿色生态、市场研究等工作。面向“新基建”、“新四化”发展，在中国汽车工业云、智能网联、智能座舱、工业互联网(工业软件)等领域精准发力，通过中国汽车产业数据基础设施建设及国家级汽车产业数据体系构建，以“数”驱产业变革，“智”领汽车未来为使命，致力于打造“国家级汽车产业数据中心、国家级汽车产业链决策支撑机构、国家级泛汽车产业数字化支撑机构”。',
                        ],
                    },

                ],
                loading:false,
                content: {
                    companyName: '',
                    businessCooperation: '',
                    phoneNumber: '',
                    email: '',
                    address: '',
                    imageName: '',
                    imagePath: '',
                },
            };
        },
        components: {
            Vfooter,
        },
        created() {
            this.us()
        },
        mounted() {
        },
        methods: {
            us() {
                this.loading=true
                connentUs().then((res) => {
                    this.loading=false
                    if (res.code == 200) {
                        this.content = res.data[0];
                    }
                }).catch(e=>{
                    this.loading=false
                })
            },
        },
    };
</script>
<style lang="scss" scoped>
    .aboutUs {
        margin-top: 20px;
        width: 100%;
        text-align: center;
        padding-left: 50px;
        margin-bottom: 40px;

        img {
            width: 80%;
        }
    }
    .aboutUs-p {
        margin-bottom: 10px;

    }
    .aboutUs-title{
        line-height: 30px;
        font-weight: 600;

    }
    .aboutUs-content {
        line-height: 25px;
        text-indent: 2em;
    }
    .connectionUs-p {
        color: #3A3947;
        line-height: 25px;
        font-size: 14px;
    }

    .addressImg {
        margin-top: 20px;
        width: 100%;
        /*padding-left: 50px;*/
        margin-bottom: 20px;
        text-align: center;

        img {
            max-width: 80%;
            max-height: 500px;
        }
    }
</style>
