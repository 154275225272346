<template>
    <div class="breadcrumb-Box">
        <ul class="breadcrumb-list">
            <li v-for="(item, index) in levelList" :key="index">
                <span v-if="index == levelList.length - 1" class="breadcrumb-style" style="font-weight: 500">{{ getName(item)}}</span>
                <a v-else class="breadcrumb-style ac" @click.prevent="handleLink(item)">{{ item.name }}></a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "breadcrumb",
    data() {
        return {
            levelList: null,
            query: {},
        };
    },
    created() {
        this.query = this.$route.query;
        this.getBreadcrumb();
    },
    methods: {
        getName(item){
            if(item.isMoreUser){
                if(this.$route&&this.$route.query&&this.$route.query.type){
                    if(this.$route.query.type==3){
                        return '公告'+item.name
                    }else  if(this.$route.query.type==2){
                        return '政策法规'+item.name
                    }else  if(this.$route.query.type==1){
                        return '行业资讯'+item.name
                    }
                    return item.name
                }
                return item.name
            }else {

                return item.name
                // return this.$route
            }

        },
        getBreadcrumb() {
            let breadcrumbList = [];
            if (this.$route.meta && this.$route.meta.breadcrumb) {
                breadcrumbList = this.$route.meta.breadcrumb;
            }

            // let matched = this.$route.matched.filter(
            //   (item) => item.meta && item.meta.title && item.path != '#'
            // );
            // const first = matched[0];
            // if (!this.isDashboard(first)) {
            //   matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
            // }
            // this.levelList = matched.filter((item) => item.meta && item.meta.title);
            this.levelList = breadcrumbList;
        },
        isDashboard(route) {
            const name = route && route.name;
            if (!name) {
                return false;
            }
            return name === "首页";
        },
        handleLink(item) {
            const { url } = item;
            // if (redirect) {
            //   if (this.query) {
            //     delete this.query.id;
            //   }
            //   this.$router.push({ path: redirect, query: this.query });
            //   return;
            // }
            this.$router.push({ path: url, query: this.query });
        },
    },
};
</script>
<style lang="scss" scoped>
.breadcrumb-Box {
    width: 100%;
    .breadcrumb-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 1200px;
        height: 48px;
        .breadcrumb-style {
            font-size: 16px;
            line-height: 15px;
            color: #333;
            padding-left: 0px;
            font-weight: 500;
        }
        .breadcrumb-style.ac {
            /*color: #0d57bc;*/
        }
        .breadcrumb-icon {
            padding-left: 32px;
            align-items: center;
        }
    }
}
</style>
