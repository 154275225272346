/**
 * @description: 首页接口
 * @param {*}
 * @return {*}
 */
import request from '@/utils/request'
// 最新公告
export function newAnnounce(data) {
    return request({
        url: '/user/index/noticeList',
        method: 'get',
        params: data,
    })
}
// 最新政策法规最新资讯
export function newPolicy(data) {
    return request({
        url: '/user/index/industryList',
        method: 'get',
        params: data,
    })
}
// 热门品牌
export function hotBrand(data) {
    return request({
        url: '/user/index/brandList',
        method: 'get',
        params: data,
    })
}
// 热门品牌
export function allBrand(data) {
    return request({
        url: '/user/index/queryBrandListByFirstChar',
        method: 'get',
        params: data,
    })
}
// 查询品牌列表
export function listBrand(data) {
    return request({
        url: '/user/brand/list',
        method: 'get',
        params: data,
        headers: { 'isToken': true }
    })
}
//获取车型
export function getVehicle(data) {
    return request({
        url: '/user/index/vehicleModelList',
        method: 'get',
        params: data,
    })
}
//获取车型
export function manufacturer() {
    return request({
        url: '/user/index/list',
        method: 'get',
    })
}
//vin,验证码校验
export function getVinYzm(data) {
    return request({
        url: '/user/repair/selectListByVin',
        method: 'get',
        params: data,
        headers: { 'isToken': true }
    })
}
//前三位
export function getVinQSW(vin) {
    return request({
        url: '/user/index/vin/' + vin,
        method: 'get',
        headers: { 'isToken': true }
    })
}
//获取banner
export function getBanner() {
    return request({
        url: '/user/index/banner/',
        method: 'get',
    })
}