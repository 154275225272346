<template>
    <div>
        <div class="box-contain">
            <Breadcrumb></Breadcrumb>
            <div class="box-contain-div">
                <div class="box-contain-div-tit">注册协议</div>
                <div class="regAgree">
                    <p>
                        本服务协议的双方是机动车维修技术信息网（以下简称“网站”）及希望注册成为网站用户的使用者（以下简称"您"）。您应仔细阅读本协议项下全部内容，
                        并对加粗字体显示部分重点阅读。本协议的效力及于网站在本协议项下为您提供的一切服务。
                    </p>
                    <p class="fwb-p">网站用户注册专用条款</p>
                    <p>
                        <span class="li-num">1.</span>
                        <span class="li-con">
                您确认，您在注册成为网站用户前，您已充分阅读、理解并接受本协议的全部内容，一旦您勾选“同意”，即表示您确认对本协议全部内容已充分理解并完全接受。
            </span>

                    </p>
                    <p>
                        <span class="li-num">2.</span>
                        <span class="li-con">
                 您确认，您同意接受本协议并申请注册成为网站用户时，您具有完全民事权利能力和民事行为能力，能够独立承担民事责任；本协议内容不受您所属国家或地区法律的排斥。
                不具备前述条件的，您应立即停止申请使用本服务。
            </span>
                    </p>
                    <p>
                        <span class="li-num">3.</span>
                        <span class="li-con">
                  您同意，网站有权根据业务发展需要对本协议的内容予以变更，并在网站公告，不再另行通知。您应以最新的规则约束为准。若您不同意网站对本协议所作的变更，您应立即停止使用本协议下服务。
                若您在本协议内容公告变更后继续使用本协议下服务，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本协议下服务。
            </span>
                    </p>
                    <p>
                        <span class="li-num">4.</span>
                        <span class="li-con">
                网站对汽车生产商提供的维修技术信息资料内容部分未做修改，仅负责整理及上传工作，网站对资料信息的准确性不做任何承诺。
                由于资料信息不准确而导致您在车辆维护修理过程中产生的损失，网站不承担任何法律责任。
            </span>
                    </p>
                    <p>
                        <span class="li-num">5.</span>
                        <span class="li-con">
                 您注册网站用户成功后，将获得网站授予您的账户使用权。网站账户的所有权归网站所有。网站账户仅限您个人使用，禁止赠与、借用、租用、转让或出售，
                否则造成的任何损失由您本人自行承担，并且网站有权单方暂停或终止该网站账户的使用。
            </span>
                    </p>
                    <p>
                        <span class="li-num">6.</span>
                        <span class="li-con">
                 您应保证注册网站用户时提供的信息是您本人的信息，并保证该等信息的真实、准确、完整，否则，由此引发的一切后果由您本人自行承担，网站不承担任何责任。
            </span>
                    </p>
                    <p>
                        <span class="li-num">7.</span>
                        <span class="li-con">
                您注册网站用户成功后，可以登录网站。您应妥善保管您的网站账户用户名和密码，并定期更改密码，以保证网站账户的安全性。因您保管不善或未定期更改密码而导致网站账户被他人非法使用，
                您应自行承担一切后果，网站对此不承担任何责任。
            </span>
                    </p>
                    <p>
                        <span class="li-num">8.</span>
                        <span class="li-con">
                您知晓并同意由于网络的特性，网站无法对使用您网站账户的人员进行核对，因此通过您的网站账户在网站和服务产品平台进行的任何行为均视为您本人所为，您将承担一切后果。
                如您发现您的网站账户被恶意使用，您应及时告知网站，网站可以采取冻结账户等措施以保护您的权益。您理解并同意网站对您的冻结请求需要合理期限采取行动，网站对冻结账户前已经产生的损失不承担任何责任。
            </span>
                    </p>
                    <p>
                        <span class="li-num">9.</span>
                        <span class="li-con">
               如您不希望继续使用网站账户，可以致电网站客服热线要求注销。注销后您已经通过网站获取的尚未使用的权益将全部归于无效，同时您将无法继续使用网站在本协议下提供的各项服务。
            </span>
                    </p>
                    <p>
                        <span class="li-num">10.</span>
                        <span class="li-con">
                如您有任何违反本协议或法律法规的行为，您应承担一切法律责任。同时，网站有权停止为您提供本协议下的服务并单方冻结或注销您的网站账户，
                因此导致的任何风险和损失由您自行承担，网站对此不承担任何责任。
            </span>
                    </p>
                    <p>
                        <span class="li-num">11.</span>
                        <span class="li-con">
                您知悉并同意，网站可以获取并收集您在浏览、注册、使用本协议下服务以及使用第三方支付公司消费过程中所产生的个人信息、交易数据及其他数据信息（以下简称“用户信息”）。
                以上用户信息的收集仅限于为实现并优化网站体验所必须的信息。网站将采取必要、合理的措施确保用户信息的安全。如因您自身原因泄露您的用户信息，因此造成的一切后果由您自行承担，网站对此不承担任何责任。
            </span>
                    </p>
                    <p>
                        <span class="li-num">12.</span>
                        <span class="li-con">
                 您同意并授权网站在法律法规允许的范围内无偿使用、分析、处理及加工您的用户信息。此外，无论您的用户信息是在何处被收集，您理解并同意您的用户信息可能因为本协议中提到的目的被传输到其他法律管辖区域。
            </span>
                    </p>
                    <p>
                        <span class="li-num">13.</span>
                        <span class="li-con">
               网站不会对外公开或向任何第三方提供您的用户信息，但以下情形除外：
            </span>
                        <span class="p-div">
               （1）根据本协议的约定已获得您的授权；
            </span>
                        <span class="p-div">
              （2）根据有关法律法规的要求；
            </span>
                        <span class="p-div">
              （3）按照政府主管部门或司法机关的要求；
            </span>
                        <span class="p-div">
              （4）为维护社会公众的利益。
            </span>
                    </p>
                    <p>
                        <span class="li-num">14.</span>
                        <span class="li-con">
                 您有权根据相关法律向网站提出数据访问请求、数据修改请求，以及个人数据删除请求。如有相关要求，请联系网站客服热线。
            </span>
                    </p>
                    <p>
                        <span class="li-num">15.</span>
                        <span class="li-con">
                您在使用本服务时应遵守中华人民共和国或您所在国家或地区的相关法律法规，不得将本服务用于任何非法目的，也不得以任何非法形式使用本服务。
                若网站认为您违反法律、法规的禁止性规定或违反本协议约定，网站有权在不事先通知您的情况下冻结或注销您的账户，因此给您造成的任何损失，网站不承担任何责任。
            </span>
                    </p>
                    <p style="margin-top: 10px;">
                        您认可并同意若本服务协议中的某一部分条款或描述被相关司法机关判定为无效，该判定无效并不影响其他部分的有效性，您仍需依照本协议中的其他部分条款履行相关义务同时行使相关权利。
                        若您在本协议内容或相关附属规则发生修订后，继续使用账户注册服务的，则视为您同意最新修订的协议内容；否则您须立即停止使用本服务。
                    </p>


                </div>
            </div>

        </div>
        <Vfooter/>
    </div>
</template>
<script>
    import Vfooter from "@/components/footer.vue";
    import Breadcrumb from "@/components/breadcrumb.vue";

    export default {

        data() {
            return {

            };
        },
        components: {
            Vfooter,
            Breadcrumb,
        },
        mounted() {
        },
        methods: {},
    };
</script>
<style lang="scss" scoped>
    .regAgree {
        p {
            line-height: 25px;
        }
        /*color: #000;*/
        /*padding: 5px;*/
        /*height: 300px;*/
        /*overflow: auto;*/
        /*border: 1px solid #000;*/
        /*background-color: #eee;*/
    }

    .fwb-p {
        margin-top: 6px;
        font-weight: bold;
        line-height: 30px;
    }

    .li-num {
        display: inline-block;
        width: 25px;
        vertical-align: top;

    }

    .li-con {
        width: calc(100% - 30px);
        display: inline-block;

    }
    .p-div {
        margin-left: 25px;
        display: block;
    }
    .aboutUs {
        margin-top: 20px;
        width: 100%;
        text-align: center;
        padding-left: 50px;
        margin-bottom: 40px;

        img {
            width: 50%;
        }
    }
    .aboutUs-p {
        margin-bottom: 10px;

    }
    .aboutUs-title{
        line-height: 30px;
        font-weight: 600;

    }
    .aboutUs-content {
        line-height: 25px;
        text-indent: 2em;
    }
</style>
