const getters = {
    permission_routes: state=>state.permission.routes,
    scrollFlag:state=>state.scoll.flag,
    loading:state=>state.loading.loading,
    ListFlag:state=>state.common.ListFlag,
    pRouterId:state=>state.permission.pRouterId,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    introduction: state => state.user.introduction,
    roles: state => state.user.roles,
    permissions: state => state.user.permissions,
}
export default getters
