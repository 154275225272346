import { getToken, setToken, removeToken } from '@/utils/auth.js'
import { login, logout, getInfo } from '@/api/login'
import de from "element-ui/src/locale/lang/de";
const state = {
    token: getToken(),
    init: false, // 是否完成初始化 // 默认未完成
    RouterList: [], // 动态路由
    name: '',
    nickName:'',
    avatar: '',
    roles: [],
    permissions: []
}
const mutations = {
    SET_ROTER: (state, RouterList) => {
        state.RouterList = RouterList
    },
    SET_Init: (state, status) => {
        state.init = status
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_NICKNAME: (state, name) => {
        state.nickName = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
        state.permissions = permissions
    }
}
const actions = {
    // 登录
    Login({ commit,dispatch }, userInfo) {
        const username = userInfo.username.trim()
        const password = userInfo.password
        const code = userInfo.code
        const uuid = userInfo.uuid
        return new Promise((resolve, reject) => {
            login(username, password, code, uuid).then(res => {
                setToken(res.token)
                commit('SET_TOKEN', res.token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo().then(res => {
                const user = res.user
                const avatar = user.avatar == "" ? require("@/assets/img/20141229151653_HiM8B.jpeg") : process.env.VUE_APP_BASE_API + user.avatar;
                if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
                    commit('SET_ROLES', res.roles)
                    commit('SET_PERMISSIONS', res.permissions)
                } else {
                    commit('SET_ROLES', ['ROLE_DEFAULT'])
                }
                commit('SET_NAME', user.userName)
                commit('SET_NICKNAME', user.nickName)
                commit('SET_AVATAR', avatar)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 退出系统
    LogOut({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout(state.token).then(() => {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                commit('SET_PERMISSIONS', [])
                removeToken()
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 前端 登出
    FedLogOut({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
