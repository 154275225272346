import Vue from "vue";
import { htmlRestore, htmlString } from "@/utils/htmlRestore.js";
Vue.mixin({
    methods: {
        htmlRestore(str) {
            return str ? htmlRestore(str) : str
        },
        htmlString(str) {
            return str ? htmlString(str) : str
        }
    }
})