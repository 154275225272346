//信息购买
import request from '@/utils/request'

//信息购买列表
export function technologyBuyList(query) {
    return request({
        url: '/order/alipay/queryList',
        method: 'get',
        params:query,
        headers:{'isToken':true}
    })
}
//确认订单
export function technologyBuy(data) {
    return request({
        url: '/order/alipay/confirmBuy',
        method: 'post',
        data:data,
        headers:{'isToken':true}
    })
}
//用户端查询收货信息
export function getAddressListByUserId(query) {
    return request({
        url: '/user/address/getAddressListByUserId',
        method: 'get',
        params:query,
        headers:{'isToken':true}
    })
}
//新增用户地址
export function address(data) {
    return request({
        url: '/user/address',
        method: 'post',
        data:data,
        headers:{'isToken':true}
    })
}

//删除信息
export function deleteAddress(data) {
    return request({
        url: '/user/address/'+data,
        method: 'DELETE',
        headers:{'isToken':true}
    })
}

//修改收货地址信息

export function updateAddress(data) {
    return request({
        url: '/user/address/update',
        method: 'post',
        data:data,
        headers:{'isToken':true}
    })
}
//设置默认地址
export function setDefaultAddress(data) {
    return request({
        url: '/user/address/setDefaultAddress',
        method: 'post',
        data:data,
        headers:{'isToken':true}
    })
}
//设置默认地址
export function addOrder(data) {
    return request({
        url: '/order/alipay/addOrder',
        method: 'post',
        data:data,
        headers:{'isToken':true}
    })
}
//支付前校验
export function goPayBefore(query) {
    return request({
        url: '/order/alipay/goPayBefore',
        method: 'get',
        params:query,
        headers:{'isToken':true}
    })
}
//支付
export function goPay(query) {
    return request({
        url: '/order/alipay/goPay',
        method: 'get',
        params:query,
        headers:{'isToken':true}
    })
}
