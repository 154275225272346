<template>
    <div>
        <div class="box-contain">
            <Breadcrumb></Breadcrumb>
            <div class="box-contain-div ql-editor" v-loading="loading">
                <div class="box-contain-div-title">{{messageObj.noticeTitle?messageObj.noticeTitle:messageObj.title}}</div>
                <div class="box-contain-div-time">{{messageObj.publishDate?messageObj.publishDate:messageObj.createTime}}</div>
                <div class="aboutUs" v-if="messageObj.img">
                    <img :src="messageObj.img"/>
                </div>
                <div class="box-contain-contnet" v-html="messageObj.noticeContent?messageObj.noticeContent:messageObj.content">

                </div>

            </div>

        </div>
        <Vfooter/>
    </div>
</template>
<script>
    import Vfooter from "@/components/footer.vue";
    import Breadcrumb from "@/components/breadcrumb.vue";
    import {announceDetail,policyDetail} from "@/api/messageList.js";

    export default {

        data() {
            return {
                messageObj:{
                    title:'',
                    img:'',
                    createTime:'',
                    content:'',

                },
                loading:false,
            };
        },
        components: {
            Vfooter,
            Breadcrumb,
        },
        created() {
            this.getDetail(this.$route.query.id,this.$route.query.type)
        },
        mounted() {
        },
        methods: {
            getDetail(id,type){
                this.loading=true
                if(type==3){
                    announceDetail(id).then(res=>{
                        this.loading=false
                        if(res.code==200){
                            this.messageObj=res.data
                        }else {
                            this.$message.error(res.msg)
                        }
                    }).catch(e=>{
                        this.loading=false
                    })
                }else {
                    policyDetail(id).then(res=>{
                        this.loading=false
                        if(res.code==200){
                            this.messageObj=res.data
                        }else {
                            this.$message.error(res.msg)
                        }
                    }).catch(e=>{
                        this.loading=false
                    })
                }

            },
        },
    };
</script>
<style lang="scss" scoped>
    .aboutUs {
        margin-top: 20px;
        width: 100%;
        text-align: center;
        padding-left: 50px;
        margin-bottom: 40px;

        img {
            width: 70%;
        }
    }
    .box-contain-div-title{
        margin-top: 10px;
        line-height: 40px;
        font-weight: 700;
        font-size: 18px;
        color: #333;
        text-align: center;

    }
    .box-contain-div-time {
        line-height: 30px;
        color: #666;
        text-align: center;
    }
    .box-contain-contnet {
        width: 80%;
        margin: auto;
    }
   ::v-deep img {
        max-width: 100%;
    }
</style>
