//字典
import $http from '@/plugins/http.js'
import request from '@/utils/request'
//字典
export function selectDictDataByType(params) {
    return $http.get('/api/dict/sysDictData/selectDictDataByType', params)
}

//字典查询
export function dict(query){
  return request({
    url: '/system/dict/data/type/'+query,
    method: 'get',
    headers:{'isToken':true}
  })
}

//纬度
export function ttDemension(params) {
    return $http.get('/api/dict/ttDemension', params)
}