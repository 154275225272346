//个人中心
import request from '@/utils/request'

//个人中心
export function myOrders(query) {
    return request({
        url: '/user/order/myOrders/'+query,
        method: 'get',
        headers:{'isToken':true}
    })
}

export function feedbackList(query) {
    return request({
        url: '/user/feedback/listId',
        method: 'get',
        params:query,
        headers:{'isToken':true}
    })
}

export function updatePersonalCenterMyProfile(data) {
    return request({
        url: '/user/order/updatePersonalCenterMyProfile',
        method: 'post',
        data:data,
        headers:{'isToken':true}
    })
}
//新增用户转换申请
export function conversion(data) {
    return request({
        url: '/user/conversion',
        method: 'post',
        data:data,
        headers:{'isToken':true}
    })
}

//订单详情
export function myOrdersDetails(data) {
    return request({
        url: '/user/order/myOrdersDetails/'+data,
        method: 'get',
        headers:{'isToken':true}
    })
}

//用户反馈详情
export function feedbackDetails(data) {
    return request({
        url: '/user/feedback/'+data,
        method: 'get',
        headers:{'isToken':true}
    })
}
