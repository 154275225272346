<template>
  <div>
    <div class="other-list">
      <h1 class="other-list-title">我的订单</h1>
      <div class="other-list-table">
        <el-table :data="tableData" :header-cell-style="{background:'#4b4bfe',color:'#fff'}">
          <el-table-column
              type="index"
              width="50"
              label="序号"></el-table-column>
          <el-table-column
              prop="orderId"
              label="订单号"></el-table-column>
          <el-table-column
              prop="brandName"
              label="购买商品">
            <template slot-scope="{row}">
              {{row.brandName.join(',')}}
            </template>
          </el-table-column>
          <el-table-column
              prop="orderStaus"
              label="订单状态"
              width="180">
            <template slot-scope="{row}">
              <span v-if="row.orderStaus == '1'">已支付</span><span v-else-if="row.orderStaus == '2'">未支付</span><span v-else>已取消</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="money"
              label="订单金额"
              width="100"></el-table-column>
          <el-table-column
              prop="createTime"
              label="下单时间"
              width="100"></el-table-column>
          <el-table-column
              label="操作"
              width="50">
            <template slot-scope="{row}">
              <a href="javascript:;" @click="show(row)">查看</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

  </div>
</template>
<script>
// 获取个人中心请求接口
import { myOrders } from "@/api/personalCenter/index.js";

import {getUserInfo} from "@/utils/auth.js";
//字典管理
import {} from "@/api/dict.js";

export default {
  components: {},
  data() {
    return {
      tableData: [{orderId:"66",brandName:['4465'],orderStaus:'0'}],
      userInfo:{}
    };
  },
  computed: {},
  created() {
    this.userInfo = getUserInfo()
    this.myOrders()
  },
  methods: {
    myOrders(){
      myOrders(this.userInfo.userId).then(res=>{
          if (res.code == 200){
            this.tableData = res.data.data
          }
      })
    },
    show(item){
      this.$router.push({path:"/personalCenter/otherDetails",query:{id:item.orderId}})
    }
  },
};
</script>
<style lang="scss" scoped>
.other-list {
  .other-list-title {
    margin: 0 20px 25px;
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #555;
    font-weight: bold;
    font-size: 20px;
  }
  .other-list-table{
    margin: 0 20px;
  }
}

</style>
