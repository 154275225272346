import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken,removetUserInfo } from '@/utils/auth.js'
import Vlogin  from '@/components/login/index.js'
import errorCode from '@/utils/errorCode'
import qs from 'qs'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 3000000
})
// 封装de请求
export const del = (url, data) => {
  // let formData = new FormData()
  // for (let key in data) {
  //   if (data.hasOwnProperty(key)) {
  //     let ele = data[key]
  //     formData.append(key, ele)
  //   }
  // }
  return service({
    url: url,
    method: 'delete',
    headers: { 'Content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data)
  })
}

// request拦截器
service.interceptors.request.use(
  config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken
    if (getToken()&&isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // 首尾去空格
    if (config.params) {
      isTrim(config.params)
    }
    if (config.data) {
      isTrim(config.data)
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
       if(!config.data){
         let url = config.url + '?'
         // get请求 新增时间戳 防止get缓存问题
         config.params.ts = new Date().getTime()
         for (const propName of Object.keys(config.params)) {
           const value = config.params[propName]
           var part = encodeURIComponent(propName) + '='
           if (value !== null && typeof value !== 'undefined') {
             if (typeof value === 'object') {
               for (const key of Object.keys(value)) {
                 let params = propName + '[' + key + ']'
                 var subPart = encodeURIComponent(params) + '='
                 // 2021-05-14 解决前端get请求  拼接的时候去除首尾空格  新增trim

                 if(typeof(value[key]) == "string"){
                   url += subPart + encodeURIComponent(value[key].trim()) + '&'
                 }else {
                   url += subPart + encodeURIComponent(value[key]) + '&'
                 }

               }
             } else {
               url += part + encodeURIComponent(value) + '&'
             }
           }
         }
         url = url.slice(0, -1)
         // url += '&ts=' + new Date().getTime()
         config.params = {}
         config.url = url
       }else {
         console.log(111)
         // config.url = config.url + '&ts=' + new Date().getTime()
       }
    }else if( /\?/.test(config.url)){
      config.url = config.url + '&ts=' + new Date().getTime()
    }
    else if (config.method === 'get') {
      let url = config.url + '?ts=' + new Date().getTime()
      config.url = url
    }
    // console.log(config,12312)
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)
/**
 * @description: 2021-05-14 请求参数首尾去空格
 * @param {*} data
 * @return {*}
 */
function isTrim(data) {
  // 首先需要判断当前的config中是否存在data值
  if (data && data instanceof Object) {
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        // 此处我们不要使用   let element = data[key] 注意  如果采用这种方式的话对应trim改变的值和data[key]将不再会是一个同一个内存地址
        // 在需要判断一下当前数据是否是数组
        if (Array.isArray(data[key])) {
          // 就将数组放进去
          data[key] = isTrim(data[key])
        } else if (data[key] && data[key] instanceof Object) {
          // 如果对象里面套对象的话
          data[key] = isTrim(data[key])
        } else if (data[key] && Object.prototype.toString.call(data[key]) == '[object String]') {
          // 如果对象里面的数据是String的话那么就直接trim只对String进行操作
          data[key] = data[key].trim()
        }
      }
    }
    return data
  } else if (data && Object.prototype.toString.call(data) == '[object String]') {
    // 如果是字符串说明是JSON.parse需要转换
    let dataObj = JSON.parse(data)
    // 转成对象之后在抛出去
    dataObj = isTrim(dataObj)
    return JSON.stringify(dataObj)
  } else if (data && data instanceof Array) {
    // 如果是数组  那就forin一下  判断里面的数据类型
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        if ((data && data instanceof Object) || (data && data instanceof Array)) {
          data[key] = isTrim(data[key])
        }
      }
    }
    return data
  }
}
// 响应拦截器
service.interceptors.response.use(
  res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/LogOut').then((res) => {
          removetUserInfo();
          store.dispatch('common/SET_LoginType','1')
          Vlogin({ flag: true,currentTab:'1' });

        })
      }).catch(e=>{
        store.dispatch('user/LogOut').then((res) => {
          removetUserInfo();
          location.reload()
        })
      })
    } else if (code === 500) {
      console.log('500报错')
      if(msg!='系统未知错误，请反馈给管理员'){
        Message({
          message: msg,
          type: 'error',
          showClose: true,
        })
      }else {
        Message({
          message: msg,
          type: 'error',
        })
      }

      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      Notification.error({
        title: msg,

      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    let { message } = error
    if (message == 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
