<template>
    <div class="bigBox">
        <div class="regis-tit">注 册</div>
        <el-form :model="form" :rules="rules" ref="form" class="form-registered">
            <el-form-item label="" prop="username">
                <el-input placeholder="输入登录名" v-model="form.username" maxlength="18"></el-input>
            </el-form-item>
            <!--            <el-form-item label="" prop="nickName">-->
            <!--                <el-input placeholder="输入用户名" v-model="form.nickName"></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item label="" class="is-hidden">
                <el-input type="text"></el-input>
            </el-form-item>
            <el-form-item label="" class="is-hidden">
                <el-input type="password"></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
                <el-input placeholder="密码必须包含大小写字母、数字、特殊字符中的3种,且8位以上" type="password"
                          v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="" prop="confirmPassword">
                <el-input placeholder="请输入确认密码" type="password" v-model="form.confirmPassword"></el-input>
            </el-form-item>
            <el-form-item label="" prop="email">
                <el-input placeholder="输入邮箱" v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="" prop="phonenumber">
                <el-input placeholder="输入手机号" v-model="form.phonenumber"></el-input>
            </el-form-item>
            <el-form-item prop="code2">
                <el-input v-model="form.code2" auto-complete="off" placeholder="验证码"
                          style="width: 70%;vertical-align: middle">
                    <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon"/>
                </el-input>
                <div class="login-code">
                    <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                </div>
            </el-form-item>
            <el-form-item label="" prop="code">
                <el-input placeholder="请输入短信验证码" v-model="form.code">
                    <template slot="append">
                        <div :class="codeClick?'getCodeMess':''" v-if="!isGetting" @click="getCodeMess">获取短信验证码</div>
                        <div class="codeTimeReduce" v-else>重新获取（<span>{{codeTime}}</span>）</div>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item prop="agreeUser" class="agreeClass" style="margin-top: -10px">
                <el-checkbox v-model="form.agreeUser">我同意<span class="getArgee" @click.stop.prevent="userAgreement">《汽车维修技术注册协议》</span>
                </el-checkbox>
            </el-form-item>
            <el-form-item class="text-center" style="margin-top: -15px">
                <el-button
                        type="primary"
                        @click="handleRegistered()"
                        style="width: 100%"
                        :loading="loading"
                        :disabled="!form.agreeUser"
                >注册
                </el-button
                >
            </el-form-item>
            <div class="dl-regis-findPass">
                <span><span>已有账号，</span><span class="registered" @click="handelLogin">立即登录</span></span>
            </div>
        </el-form>

        <el-dialog
                class="regAgreeDig"
                title="维修信息网注册协议"
                :visible.sync="userAgreeOpen"
                append-to-body
                :close-on-click-modal="false"
                width="50%"
        >
            <RegAgreement></RegAgreement>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="userAgreeOpen = false">阅读完毕并关闭</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
    import {validEmail, validPhone} from '@/utils/validate'
    import {register, phoneMessage, getCodeImg, isPass} from "@/api/login.js";
    import RegAgreement from '@/components/RegAgreement/index.vue'

    export default {
        name: "Registered",
        data() {
            var validateloginname = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入登录名'));
                }
                if (value.length >= 8) {
                    callback();
                } else {
                    callback(new Error('登录名长度8-18位'));
                }
            };
            var validPassword = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入密码'));
                }
                let reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z!@#\^*]+$)(?![a-z0-9]+$)(?![a-z!@#\^*]+$)(?![0-9!@#\^*]+$)[a-zA-Z0-9!@#\^*]{8,}$/
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error('密码必须包含大小写字母、数字、特殊字符中的3种,且8位以上'));
                }
            };
            var validateAgreeUser = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请查看并勾选此注册协议'));
                }
                callback();
            };
            var validateUsername = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入用户名'));
                }
                if (value.length >= 8) {
                    callback();
                } else {
                    callback(new Error('用户名长度至少8位'));
                }
            };
            var checkEmail = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入邮箱'));
                }
                setTimeout(() => {
                    if (validEmail(value)) {
                        callback();

                        callback(new Error('请输入正确的邮箱格式'));
                    }
                }, 100);
            };
            const equalToPassword = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入确认密码'));
                }
                if (this.form.password !== value) {
                    callback(new Error("密码不一致，请重新输入"));
                } else {
                    callback();
                }
            };
            var validateUserPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入手机号码'));
                }
                setTimeout(() => {
                    if (validPhone(value)) {
                        callback();
                    } else {
                        callback(new Error('手机格式不正确'));
                    }
                }, 100);
            };

            return {
                //1:登录，2注册，3：找回密码
                currentTab: '1',
                userAgreeOpen: false,
                codeClick: false,
                form: {
                    nickName: "",
                    username: "",
                    confirmPassword: "",
                    password: "",
                    phonenumber: "",
                    email: "",
                    code: "",
                    code2: "",
                    uuid: "",
                    uuid2: "",
                    agreeUser: false,
                    userType: '1',
                    frontAfter:1
                },
                isGetting: false,
                codeTime: 60,
                codeUrl: '',
                rules: {
                    code: [{
                        required: true,
                        message: '请输入短信验证码',
                        trigger: 'blur'
                    }],
                    code2: [{
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur'
                    }],
                    agreeUser: [
                        {validator: validateAgreeUser, trigger: "blur"},
                    ],
                    username: [{validator: validateloginname, trigger: "blur"}],
                    nickName: [{validator: validateUsername, trigger: "blur"}],
                    email: [{validator: checkEmail, trigger: "blur"}],
                    password: [
                        {validator: validPassword, trigger: "blur"},
                        // {
                        //     required: true,
                        //     message: '请输入密码',
                        //     trigger: 'blur'
                        // },
                        // {
                        //     // pattern: /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*\$])[a-z\d#@!~%^&*\$]{8,}/i,
                        //     pattern: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#*]).{8,16}/,
                        //     message: "密码必须包含大小写字母、数字、特殊字符中的3种,且8位以上",
                        //     trigger: "blur"
                        // },
                    ],
                    confirmPassword: [{validator: equalToPassword, trigger: "blur"}],
                    phonenumber: [{validator: validateUserPhone, trigger: "blur"}],

                },
                loading: false,
            };
        },
        components: {
            RegAgreement
        },
        watch: {
            'form.phonenumber': {
                handler(val) {
                    if (validPhone(val)) {
                        this.codeClick = true
                    } else {
                        this.codeClick = false
                    }
                },
            },
        },

        created() {
            this.getCode()
        },
        methods: {
            getCode() {
                getCodeImg().then((res) => {
                    this.codeUrl = 'data:image/gif;base64,' + res.img
                    this.form.uuid2 = res.uuid
                })
            },
            //获取短信验证码
            getCodeMess() {
                this.form.uuid = ''
                if (!validPhone(this.form.phonenumber)) {
                    return
                }
                if (!this.form.code2) {
                    return this.$message.error('请先输入动态校验码')
                }
                isPass({code: this.form.code2, uuid: this.form.uuid2}).then(res => {
                    this.isGetting = true
                    phoneMessage({phonenumber: this.form.phonenumber}).then(res => {
                        if (res.code == 200) {
                            this.isGetting = true
                            this.codeTime = 59
                            this.$message.success('手机验证码已发送')
                            this.form.uuid = res.uuid
                            let index = setInterval(() => {
                                if (this.codeTime > 0) {
                                    this.codeTime--
                                } else {
                                    clearInterval(index)
                                    this.isGetting = false
                                }

                            }, 1000)
                        }
                    })
                }).catch(e => {
                    this.getCode()
                    this.loading1 = false
                })


            },
            //注册
            handleRegistered() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let form = Object.assign({},this.form)
                        form.password = this.$MD5(form.password)
                        register(form).then(res => {
                            this.loading = false
                            if (res.code == 200) {

                                this.$emit('handelRegister', '1')
                                this.$message.success('注册成功')

                            }
                        }).catch(e => {
                            this.loading = false
                            this.getCode()
                        })
                    }
                })
            },
            //立即登录
            handelLogin() {
                this.$emit('handelRegister', '1')
            },
            //协议
            userAgreement() {
                this.userAgreeOpen = true

            },
        },
    };
</script>
<style lang="scss" scoped>
    .login-code {
        vertical-align: middle;
        position: absolute;
        right: 0;
        top: 0;
        display: inline-block;
        width: 23%;

        img {
            width: 100%;
        }

    }

    .bigBox {
        padding: 50px 20px 20px;
    }

    .form-registered {
        width: 440px;
        margin: 20px auto;
    }

    .regis-tit {
        font-size: 18px;
        margin: auto;
        text-align: center;
        font-weight: 700;
    }

    ::v-deep .el-input-group__append .getCodeMess {
        cursor: pointer;
        color: #0D57BC;

    }

    .getArgee {
        cursor: pointer;
        color: #0D57BC;
    }

    .codeTimeReduce {

    }

    .dl-regis-findPass {
        margin-top: -15px;
        color: #333;

        .registered {
            /*float: left;*/
            color: #169BD5;
            cursor: pointer;
        }

        .findPassword {
            float: right;
            color: #169BD5;
            cursor: pointer;
        }
    }

    ::v-deep .el-form-item {
        margin-bottom: 20px;
    }

    ::v-deep .agreeClass .el-form-item__error {
        margin-top: -10px;
    }

    .regAgreeDig ::v-deep.el-dialog__body {
        padding: 10px 20px;
    }

    .is-hidden {
        position: absolute;
    }
</style>
