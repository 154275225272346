import store from './store'
import user from "./store/modules/user";
import router from './router'
// import menuData from './assets/jsondata/menu.json'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import Layout from './layout'
// import { findMenu } from "./api/menu.js"
// import { getUserInfo } from "@/utils/auth.js";
NProgress.configure({ showSpinner: false })
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()
  if (!user.state.init) {
    // const accessRoutes = await store.dispatch('permission/generateRoutes', menuData)
    // let userId = ''
    // if (getUserInfo()) {
    //   userId = getUserInfo().userId
    // }
    // let menuData = null
    // menuData = await findMenu({ userId })
    // console.log(menuData)

    // const accessRoutes = await store.dispatch('permission/generateRoutes', menuData.data)
    const accessRoutes = await store.dispatch('permission/generateRoutes', '')
    accessRoutes.push(
      {
        path: '/404',
        name: 'index',
        component: Layout,
        redirect: '/404',
        children: [
          {
            path: '/404',
            component: () => import('@/views/error-page/404'),
            name: '404',
            meta: {
              hidden: 1,
            },
          },
        ]
      },
      {
        path: '*', redirect: '/404', meta: {
          hidden: 1,
        },
      })
    //最后添加404
    router.addRoutes(accessRoutes)
    store.commit("user/SET_Init", true)
    next({ ...to, replace: true })
  } else {
    next()
  }

})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

