<template>
  <div>
    <div class="box-contain box-containThree">

      <div class="buy-div box-contain-divThree">
          <div class="order-info">
              <div class="order-info-box">
                <div class="oder-info-title">
                  <span>付款信息</span>
                </div>
                <div class="oder-info-inner">
                  <img class="oder-img" src="@/assets/img/dh.png" alt="">
                  <div class="oder-info-inner-text">
                    <h1 class="h1">订单已提交成功，请您立即付款。</h1>
                    <h2 class="h2">请您在<span class="red">6</span>小时内完成付款，超过后系统将自动取消订单</h2>
                    <h1 class="h1">
                      订单编号：<span>{{ payInfo.orderId }}</span>
                    </h1>
                    <h1 class="h1">
                      订单金额：<span class="red">￥ {{ payInfo.money }}</span>
                    </h1>
                  </div>
                </div>

                <div class="oder-info-title">
                  <span>支付方式</span>
                </div>

                <div class="payment-info">
                    <div class="payment-info-list">
                      <img src="@/assets/img/zfb.png" alt="">
                      农行网银支付
                      <img src="@/assets/img/dddh.png" alt="" class="dddh">
                    </div>
                </div>

                <div class="buy-btn-info">
                  <button class="buy-btn" @click="submit">立即付款</button>
<!--                  <p>注意事项：收到您的付款后，我们会尽快安排最近的配货商与您取得联系。</p>-->
                </div>
              </div>
          </div>

      </div>

      <div v-html="ceshi"></div>


    </div>
    <Vfooter/>
  </div>
</template>
<script>
import Vfooter from "@/components/footer.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
// 获取信息购买请求接口
import { goPayBefore,goPay } from "@/api/technologyBuy/index.js";
//字典管理
import {} from "@/api/dict.js";

export default {
  components: {
    Vfooter,
    Breadcrumb
  },
  data() {
    return {
      formData:{},
      payInfo:{},
      ceshi:""
    };
  },
  computed: {},
  created() {
    this.payInfo = JSON.parse(sessionStorage.getItem('payInfo'))
  },
  methods: {
    submit(){
      goPayBefore({orderId:this.payInfo.orderId}).then(res=>{
          if (res.code == 200){
            // window.open('http://192.168.3.202:8080/maintenance/order/alipay/goPay?orderId='+this.payInfo.orderId,'_self')
            goPay({orderId:this.payInfo.orderId}).then(cres=>{
              let result = cres.data;
              let returnCode = result.ReturnCode;
              let errorMessage = result.ErrorMessage;
              if ('0000' == returnCode){
                let paymentURL = result.PaymentURL;
                window.open(paymentURL,'_blank');
              }else {
                alert(errorMessage)
              }
              // const div=document.createElement('divform');
              // div.innerHTML=cres.data; // data就是接口返回的form 表单字符串
              // document.body.appendChild(div);
              // let len = document.forms.length-1
              //
              // document.forms[len].setAttribute('target', '_self') // 新开窗口跳转
              // document.forms[len].submit();
            })
          }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.buy-div {
  border: 1px solid #c3c3c3;
  padding: 20px;
  background: #fff;
}


.order-info-box{
  width: 980px;
  margin-left: 25px;

}
.oder-info-title{
  border-bottom: 1px solid #333;
  span{
    display: inline-block;
    padding: 10px 35px;
    background: #333;
    color: #fff;
    font-size: 14px;
  }
}
.oder-info-inner{
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  .oder-info-inner-text{
    margin-top: 30px;
  }
  .h1{
    font-weight: bold;
    span{
      font-weight: normal;
    }
  }
  .h1,h2{
    font-size: 17px;
    margin-bottom: 15px;
  }
  .h2{
    color: #666;
  }
  .red{
    color:#FF0000;
  }
}
.payment-info{
  display: flex;
  margin-left: 130px;
  margin-top: 50px;
  .payment-info-list{
    position: relative;
    border: 1px solid #666;
    font-size: 17px;
    font-weight: bold;
    padding: 10px 90px 10px 40px;
    border-radius: 5px;
  }
  .dddh{
    position: absolute;
    right: 30px;
    bottom: 0;
  }
}

.buy-btn-info{
  margin-top: 52px;
  margin-left: 130px;
  margin-bottom: 130px;
  .buy-btn{
    width: 200px;
    height: 70px;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    color: #fff;
    background: #169bd5;
    cursor: pointer;
  }
  p{
    color: #ff6600;
    font-size: 18px;
    margin-top: 20px;
  }
}

</style>
