<template>
  <div>
    <div class="box-contain box-containThree">
      <Breadcrumb></Breadcrumb>
      <div class="buy-div box-contain-divThree">
        <div class="buy-title">
          全部品牌
        </div>
        <div class="buy-classify">
          <div class="buy-classify-title">按首字母查找：</div>
          <div class="buy-classify-zm">
            <span v-for="item in allFirstName" :key="item" :class="{act:zmAct==item}" @click="zmBtn(item)">{{ item }}</span>
          </div>
        </div>
        <el-row class="buy-classify-container" v-loading="loading" >
          <template v-for="item in filterBarandList">
            <el-col :span="5" :key="item.id" >
              <div  class="car-icon" :class="{actClass:filterClass(item.id)}" @click="addPP(item)">
                <img :src="item.logoUrl" alt="" width="150" height="150">
                <h2 style="font-size: 15px">{{ item.brandName }}</h2>
              </div>
            </el-col>
          </template>
        </el-row>
        <div class="buy-btn">
            <button @click="toHref">
              购买（{{ ppList.length }}）
            </button>
        </div>
        <!--                <div class="box-contain-div-tit">用户反馈</div>-->
        <!--                <div class="noOpen">暂未开放</div>-->

      </div>

    </div>
    <Vfooter/>
  </div>
</template>
<script>
import Vfooter from "@/components/footer.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
// 获取信息购买请求接口
import {} from "@/api/technologyBuy/index.js";

// 获取首页请求接口
import {allBrand} from "@/api/Index.js";
//字典管理
import {} from "@/api/dict.js";

export default {
  components: {
    Vfooter,
    Breadcrumb
  },
  data() {
    return {
      zmAct: 'A',
      allBarandList:[],
      allFirstName:[],
      filterBarandList:[],
      loading:false,
      ppList:[]
    };
  },
  computed: {},
  created() {
      this.getBrand()
  },
  methods: {
    getBrand(){
      this.loading = true
      allBrand().then(res => {
        this.loading = false
        if (res.code == 200) {
          this.allBarandList = res.data
          this.allBarandList.forEach(item=>{
            item.brandListJ = JSON.parse(item.brandList)
          })
          this.allFirstName = res.data.map(item=>item.firstChar)
          this.zmAct = this.allFirstName[0]
          this.zmBtn(this.allFirstName[0])
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.loading = false
      })
    },
    addPP(item){
      let index = this.ppList.indexOf(item.id)
      if (index == -1){
        this.ppList.push(item.id)
      }else {
        this.ppList.splice(index,1)
      }
    },
    zmBtn(item) {
      this.zmAct = item
      this.filterBarandList = this.allBarandList.filter(citem=>citem.firstChar == item)[0].brandListJ
    },
    toHref(){
      if(this.ppList.length<=0){
       return this.$message.warning('请选择品牌')
      }
       this.$router.push({path:"/technologyBuy/buyChoice",query:{ids:this.ppList.join(',')}})
    },
    filterClass(id){
      return this.ppList.includes(id)
    }
  },
};
</script>
<style lang="scss" scoped>
.buy-div {
  border: 1px solid #c3c3c3;
  background: #fff;

}

.buy-title {
  padding: 20px 0 20px 20px;
  background: #0D57BC;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.buy-classify {
  display: flex;
  padding: 20px;
  font-weight: bold;
  font-size: 15px;
  .buy-classify-title {
    color: #1e1eff;
  }

  .buy-classify-zm {
    flex: 1;
    display: flex;
    //justify-content: space-between;

    span {
      padding:0 5px;
      cursor: pointer;
    }

    span.act {
      color: #1e1eff;
    }
  }
}
.buy-classify-container{
  .car-icon{
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      left: 54px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #c3c3c3;
    }
    &.actClass{
      &:after{
        background: #1e1eff;
      }
    }
  }

}
.buy-btn{
  padding: 20px;
  display: flex;
  justify-content: end;
  button{
    width: 150px;
    height: 50px;
    border: none;
    background: #169BD5;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
  }
}

</style>
