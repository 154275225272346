<template>
    <div class="new-footer">
        <div class="new-footer-b">
            <div class="new-footer-inner">
                <div class="footer-us">
                    <span class="footer-us-span">关联监管平台：</span>
                    <span class="footer-us-a" onclick="window.open('https://carti.rioh.cn')">全国汽车维修技术信息公开服务网</span>
                    <span class="footer-us-a"  onclick="window.open('http://www.catarc.info')">中汽数据有限公司</span>
                    <span class="footer-us-a"  onclick="window.open('http://cx.cnca.cn/CertECloud/index/index/page')">全国认证认可信息公共服务平台</span>
                    <span class="footer-us-a"  onclick="window.open('https://www.mot.gov.cn')">中华人民共和国交通运输部</span>
                </div>
                <div class="connent-us">
                    <span class="connent-us-span" v-for="(item,index) in connentList" :key="index" @click="gotoNewPage(item.routeHref)">{{item.name}}</span>
<!--                    <span class="connent-us-span">关于我们</span>-->
<!--                    <span class="connent-us-span">版权说明</span>-->
<!--                    <span class="connent-us-span">使用说明</span>-->
                </div>
                <div class="record">
                    京ICP备16016372号-1
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            connentList:[
                // {name:'联系我们',routeHref:'/us'},
                // {name:'关于我们',routeHref:'/aboutUs'},
                {name:'版权说明',routeHref:'/copyright'},
                {name:'使用说明',routeHref:'/instructions'},
                {name:'注册协议',routeHref:'/regArgee'},
            ],
        };
    },
    created() {
    },
    methods: {
        gotoNewPage(val){
           this.$router.push(val)
        },
    },
};
</script>
<style lang="scss" scoped>
.new-footer {
    .new-footer-b {
        width: 100%;
        /*color: #fff;*/
        color: #d1d1d1;
        background: #0D57BC;
        padding: 32px 0;
        .new-footer-inner {
            width: 1200px;
            margin: 0 auto;
            .footer-us {
                padding: 8px 0;
                /*width: 1200px;*/
                display: flex;
                justify-content: center;
                .footer-us-span {
                   display: inline-block;
                }
                .footer-us-a {
                    display: inline-block;
                    padding: 0 30px 0 10px;
                    cursor: pointer;

                }
                .footer-us-a:hover {
                   color: #fff;

                }
            }
            .connent-us {
                padding: 8px 0;
                /*width: 1200px;*/
                display: flex;
                justify-content: center;
                .connent-us-span {
                    cursor: pointer;
                    display: inline-block;
                    padding: 0 40px;
                }
                .connent-us-span:hover {
                    color: #fff;
                }
            }
            .record {
                text-align: center;
               margin-top: 10px;
            }
        }
    }
}

</style>
