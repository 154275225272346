import $http from '@/plugins/http.js'
import request from '@/utils/request'
import CryptoJS from '@/utils/crypto-js.js'
import de from "element-ui/src/locale/lang/de";
import {del} from "../utils/request";
// 加密
let key = 'TJzhongqiyan1006';
key = CryptoJS.enc.Utf8.parse(key);
// export function login(query) {
//     return $http.post('/lo/login', query)
// }
// 登录方法
export function login(username, password, code, uuid) {
    let data = {
        username,
        password,
        code,
        uuid,
        encryptionPwd:true,
        frontAfter:1
    }
    // let content = CryptoJS.enc.Utf8.parse(data.password);
    // let encryptResult = CryptoJS.AES.encrypt(content, key, {
    //     mode: CryptoJS.mode.ECB, //aes加密模式
    //     padding: CryptoJS.pad.Pkcs7, //填充
    // });
    // let formData = CryptoJS.enc.Base64.stringify(encryptResult.ciphertext);
    // data.password=formData
    return request({
        url: '/login',
        method: 'post',
        data: data
        // data: formData
    })
}

// 注册方法
export function register(data) {
    let param=JSON.parse(JSON.stringify(data))
    delete param.confirmPassword

    // let content = CryptoJS.enc.Utf8.parse(param.password);
    // let encryptResult = CryptoJS.AES.encrypt(content, key, {
    //     mode: CryptoJS.mode.ECB, //aes加密模式
    //     padding: CryptoJS.pad.Pkcs7, //填充
    // });
    // let formData = CryptoJS.enc.Base64.stringify(encryptResult.ciphertext);
    // param.password=formData
    return request({
        url: '/api/user/register',
        method: 'post',
        data: param
    })
}
// 找回密码
export function findPassword(data) {
    let param=JSON.parse(JSON.stringify(data))
    delete param.confirmPassword
    // let content = CryptoJS.enc.Utf8.parse(param.password);
    // let encryptResult = CryptoJS.AES.encrypt(content, key, {
    //     mode: CryptoJS.mode.ECB, //aes加密模式
    //     padding: CryptoJS.pad.Pkcs7, //填充
    // });
    // let formData = CryptoJS.enc.Base64.stringify(encryptResult.ciphertext);
    // param.password=formData
    return request({
        url: '/api/user/resetPwd',
        method: 'post',
        params: param
    })
}
// 短信验证码
export function phoneMessage(data) {
    return request({
        url: '/sendSms',
        method: 'get',
        params: data
    })
}
// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/getInfo',
        method: 'get',
        headers:{'isToken':true}
    })
}
// 找回密码判断是否注册
export function isRegAgree(data) {
    return request({
        url: '/api/user/judgePhoneNumber',
        method: 'post',
        params:data,

    })
}
// 验证验证码
export function isPass(data) {
    return request({
        url: '/api/user/judgeValidate',
        method: 'post',
        params:data,

    })
}

// 退出方法
export function logout() {
    return request({
        url: '/logout',
        method: 'post',
        headers:{'isToken':true}
    })
}
// 获取验证码
export function getCodeImg() {
    return request({
        url: '/captchaImage',
        method: 'get',
    })
}


