import Vue from 'vue'
import VueRouter from 'vue-router'
import {getUserInfo} from "@/utils/auth.js";
import Layout from '../layout'

Vue.use(VueRouter)
export const constantRoutes = [
    {
        path: '/',
        name: '首页',
        component: Layout,
        redirect: '/',
        meta: {
            hidden: 0,
        },
        children: [
            {
                path: '/',
                component: () => import('@/views/index/index'),
                name: '首页',
                meta: {
                    hidden: 1,
                    acName: '首页',
                }
            },
            // {
            //   path: '/us',
            //   name: "联系我们",
            //   component: () => import('@/views/us/index'),
            //   meta: {
            //     hidden: 1,
            //     breadcrumb: [
            //       { name: "首页", url: '/' },
            //       { name: "联系我们" },
            //     ],
            //   }
            // },
            // {
            //   path: '/aboutUs',
            //   name: "关于我们",
            //   component: () => import('@/views/aboutUs/index'),
            //   meta: {
            //     hidden: 0,
            //     breadcrumb: [
            //       { name: "首页", url: '/' },
            //       { name: "关于我们" },
            //     ],
            //   }
            // },
            {
                path: '/copyright',
                name: "版权说明",
                component: () => import('@/views/copyright/index'),
                meta: {
                    hidden: 1,
                    breadcrumb: [
                        {name: "首页", url: '/'},
                        {name: "版权说明"},
                    ],
                }
            }, {
                path: '/instructions',
                name: "使用说明",
                component: () => import('@/views/instructions/index'),
                meta: {
                    hidden: 1,
                    breadcrumb: [
                        {name: "首页", url: '/'},
                        {name: "使用说明"},
                    ],
                }
            }, {
                path: '/regArgee',
                name: "注册协议",
                component: () => import('@/views/regArgee/index'),
                meta: {
                    hidden: 1,
                    breadcrumb: [
                        {name: "首页", url: '/'},
                        {name: "注册协议"},
                    ],
                }
            },
            {
                path: '/businessIntroduction',
                name: "业务介绍",
                component: () => import('@/views/businessIntroduction/index'),
                meta: {
                    hidden: 1,
                    breadcrumb: [
                        {name: "首页", url: '/'},
                        {name: "业务介绍"},
                    ],
                }
            },
            {
                path: '/messageList',
                name: "公告列表",
                component: () => import('@/views/messageList/index'),
                meta: {
                    hidden: 1,
                    breadcrumb: [
                        {name: "首页", url: '/'},
                        {name: "公告列表",},
                    ],
                }
            },
            {
                path: '/messageListPocy',
                name: "列表",
                component: () => import('@/views/messageListPocy/index'),
                meta: {
                    hidden: 1,
                    breadcrumb: [
                        {name: "首页", url: '/'},
                        {name: "列表", isMoreUser: 1,},
                    ],
                }
            },
            {
                path: '/messageDetail',
                name: "文章",
                component: () => import('@/views/messageDetail/index'),
                meta: {
                    hidden: 1,
                    breadcrumb: [
                        {name: "首页", url: '/'},
                        {name: "文章", isMoreUser: 1,},
                    ],
                }
            },
        ]
    },
    {
        path: '/notice',
        component: () => import('@/views/notice'),
        meta: {
            hidden: 0,
        },
    },
]

export const asyncRoutes = [
    {
        path: "/maintenanceService/index",
        name: "维修信息",
        component: Layout,
        redirect: '/maintenanceService/index',
        meta: {
            hidden: 0,
        },
        children: [
            {
                path: "/maintenanceService/index",
                name: "维修信息",
                component: () => import('@/views/maintenanceService/index'),
                meta: {
                    hidden: 1,
                    acName: "维修信息"
                }
            },

        ]
    },
    {
        path: "/technologyBuy/index",
        name: "信息购买",
        component: Layout,
        redirect: '/technologyBuy/index',
        meta: {
            hidden: 0,
        },
        children: [
            {
                path: "/technologyBuy/index",
                name: "信息购买",
                component: () => import('@/views/technologyBuy/index'),
                meta: {
                    hidden: 1,
                    acName: "信息购买",
                    breadcrumb: [
                        {name: "首页", url: '/'},
                        {name: "信息购买", isMoreUser: 1,},
                    ],
                }
            },
            {
                path: "/technologyBuy/buyChoice",
                name: "信息购买选购",
                component: () => import('@/views/technologyBuy/buyChoice'),
                meta: {
                    hidden: 1,
                    acName: "信息购买选购",
                    breadcrumb: [
                        {name: "首页", url: '/'},
                        {name: "信息购买", isMoreUser: 1,},
                    ],
                }
            },
            {
                path: "/technologyBuy/checkOrder",
                name: "核对订单页面",
                component: () => import('@/views/technologyBuy/checkOrder'),
                meta: {
                    hidden: 1,
                    acName: "核对订单页面",
                }
            },
            {
                path: "/technologyBuy/confirmPayment",
                name: "确认订单页面",
                component: () => import('@/views/technologyBuy/confirmPayment'),
                meta: {
                    hidden: 1,
                    acName: "确认订单页面",
                }
            },

        ]
    },
    {
        path: "/userFeedback/index",
        name: "用户反馈",
        component: Layout,
        redirect: '/userFeedback/index',
        meta: {
            hidden: 0,
        },
        children: [
            {
                path: "/userFeedback/index",
                name: "用户反馈",
                component: () => import('@/views/userFeedback/index'),
                meta: {
                    hidden: 1,
                    acName: "用户反馈"
                }
            },

        ]
    },
    {
        path: '/aboutUs/index',
        name: "关于我们",
        component: Layout,
        redirect: '/aboutUs/index',
        meta: {
            hidden: 0,
        },
        children: [
            {
                path: '/aboutUs/index',
                name: "关于我们",
                component: () => import('@/views/aboutUsTwo/index'),
                meta: {
                    hidden: 1,
                    acName: "关于我们"
                }
            },

        ]
    },
    {
        path: "/personalCenter/index",
        name: "个人中心",
        component: Layout,
        redirect: '/personalCenter/index',
        meta: {
            hidden: getUserInfo() ? 0 : 1,
        },
        children: [
            {
                path: "/personalCenter/index",
                name: "个人中心",
                component: () => import('@/views/personalCenter/index'),
                redirect: '/personalCenter/info',
                meta: {
                    hidden: 1,
                    acName: "个人中心"
                },
                children: [
                    {
                        path: "/personalCenter/info",
                        name: "个人信息",
                        component: () => import('@/views/personalCenter/info'),
                        meta: {
                            hidden: 1,
                            acName: "个人信息"
                        }
                    },
                    {
                        path: "/personalCenter/otherList",
                        name: "订单信息",
                        component: () => import('@/views/personalCenter/otherList'),
                        meta: {
                            hidden: 1,
                            acName: "订单信息"
                        }
                    },
                    {
                        path: "/personalCenter/otherDetails",
                        name: "订单详情",
                        component: () => import('@/views/personalCenter/otherDetails'),
                        meta: {
                            hidden: 1,
                            acName: "订单信息"
                        }
                    },
                    {
                        path: "/personalCenter/feedback",
                        name: "用户反馈",
                        component: () => import('@/views/personalCenter/feedback'),
                        meta: {
                            hidden: 1,
                            acName: "用户反馈"
                        }
                    },
                    {
                        path: "/personalCenter/feedbackDetails",
                        name: "用户详情",
                        component: () => import('@/views/personalCenter/feedbackDetails'),
                        meta: {
                            hidden: 1,
                            acName: "用户反馈"
                        }
                    },
                    {
                        path: "/personalCenter/feedbackAdd",
                        name: "用户添加反馈",
                        component: () => import('@/views/personalCenter/feedbackAdd'),
                        meta: {
                            hidden: 1,
                            acName: "用户反馈"
                        }
                    },
                ]
            },

        ]
    },
    {
        path: "/technologyBuy/success",
        name: "支付成功",
        component: Layout,
        redirect: '/technologyBuy/success',
        meta: {
            hidden:1,
        },
        children: [
            {
                path: "/technologyBuy/success",
                name: "支付成功",
                component: () => import('@/views/technologyBuy/success'),
                meta: {
                    hidden: 1,
                    acName: "支付成功"
                },
            }
        ]
    },
]

let login = []

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const createRouter = () => new VueRouter({
    scrollBehavior: () => ({y: 0}),
    // routes: constantRoutes.concat(defaultRoutes)
    routes: login.concat(constantRoutes)
})
const router = createRouter()

// 重置路由
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
