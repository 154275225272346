<template>
    <div class="nav-box">
        <div flex="sb">
            <div class="nav-box-l">
                <div class="nav-box-menu">
                    <template v-for="item in menu">
                        <div :key="item.name" v-if="item.meta.hidden == 0" class="link-box">
                            <router-link :to="item.path" :class="{ ac: item.name == acName }">
                                {{ item.name }}</router-link>
                            <span class="menu-icon" v-if="item.name!='首页'"></span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="line-1" v-show="$route.path !='/'"></div>
    </div>
</template>
<script>
export default {
    props: {
        menu: [],
        show: {
            type: Boolean,
            default: false,
        },
        userName: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            searchValue: "",
        };
    },
    created() {
        console.log(this.menu)
    },
    computed: {
        acName() {
            let routeName = this.$route.matched[0].name || "";
            this.$store.dispatch("permission/getRouter", routeName);
            return routeName;
        },
    },
    methods: {
        handleClick() {
            this.$emit("click");
        },
        handleCommand(command) {
            this.$emit("command", command);
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-box-l,
.nav-box-menu {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
}
.nav-box {
    height: 100%;
    overflow: hidden;
}
.nav-box-menu {
    margin-left: 32px;
}
.nav-box-menu a {
    position: relative;
    display: block;
    /*color: #333;*/
    color: #fff;
    font-size: 16px;
    line-height: 15px;
    padding-bottom: 18px;
    font-weight: bold;
}
.nav-box-menu a:hover {
    color: #00cef3;
}
.nav-box-menu a.ac {
    /*color: #0d57bc;*/
    color: #00cef3;
}
.nav-box-menu a.ac::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    /*background: #0d57bc;*/
    background: #00cef3;
}
.nav-box-menu a:hover::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    /*background: #0d57bc;*/
    background: #00cef3;
}
.nav-box-menu .menu-icon {
    padding: 0 17px;
    font-size: 16px;
    line-height: 15px;
    color: #bdbdbd;
}
.nav-box-menu .link-box:last-child .menu-icon {
    display: none;
}
.nav-box-menu .link-box {
    display: flex;
    align-items: flex-start;
    margin-top: 7px;
}
.nav-box-l {
    img {
        margin-top: 4px;
        margin-left: 16px;
    }
}
.line-1 {
    width: 100%;
    height: 1px;
    background: #0000cd;
    opacity: 0.06;
}
.nav-search {
    position: relative;
    width: 290px;
    height: 44px;
    background: rgba(13, 87, 188, 0.03);
    padding: 0 35px 0 17px;
    margin-right: 20px;
    .search-input {
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
    }
    .search-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 23px;
        margin-top: -8px;
        cursor: pointer;
    }
    .search-input:focus {
        outline: 0px;
    }
}
</style>
