<template>
    <div>
        <div class="box-contain box-containThree">
            <div class="box-contain-div box-contain-divThree">
                <div class="box-contain-div-tit">用户反馈</div>
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item label="反馈类型:" prop="type">
                        <el-radio v-model="form.type" label="0">投诉</el-radio>
                        <el-radio v-model="form.type" label="1">建议</el-radio>
                        <el-radio v-model="form.type" label="2">问题反馈</el-radio>
                    </el-form-item>
                    <el-form-item label="反馈内容:" prop="content" class="editorBox">
<!--                        <quill-editor style="margin-top: 40px;height: 300px" ref="text" v-model="form.content" class="myQuillEditor"-->
<!--                                      :options="editorOption">-->
<!--                            &lt;!&ndash; 自定义toolar &ndash;&gt;-->
<!--                            <div id="toolbar" slot="toolbar">-->
<!--                                &lt;!&ndash; Add a bold button &ndash;&gt;-->
<!--                                <button class="ql-bold" title="加粗">Bold</button>-->
<!--                                <button class="ql-italic" title="斜体">Italic</button>-->
<!--                                <button class="ql-underline" title="下划线">underline</button>-->
<!--                                <button class="ql-strike" title="删除线">strike</button>-->
<!--                                <button class="ql-blockquote" title="引用"></button>-->
<!--                                <button class="ql-code-block" title="代码"></button>-->
<!--                                <button class="ql-header" value="1" title="标题1"></button>-->
<!--                                <button class="ql-header" value="2" title="标题2"></button>-->
<!--                                &lt;!&ndash;Add list &ndash;&gt;-->
<!--                                <button class="ql-list" value="ordered" title="有序列表"></button>-->
<!--                                <button class="ql-list" value="bullet" title="无序列表"></button>-->
<!--                                &lt;!&ndash; Add font size dropdown &ndash;&gt;-->
<!--                                <select class="ql-header" title="段落格式">-->
<!--                                    <option selected>段落</option>-->
<!--                                    <option value="1">标题1</option>-->
<!--                                    <option value="2">标题2</option>-->
<!--                                    <option value="3">标题3</option>-->
<!--                                    <option value="4">标题4</option>-->
<!--                                    <option value="5">标题5</option>-->
<!--                                    <option value="6">标题6</option>-->
<!--                                </select>-->
<!--                                <select class="ql-size" title="字体大小">-->
<!--                                    <option value="10px">10px</option>-->
<!--                                    <option value="12px">12px</option>-->
<!--                                    <option value="14px">14px</option>-->
<!--                                    <option value="16px" selected>16px</option>-->
<!--                                    <option value="18px">18px</option>-->
<!--                                    <option value="20px">20px</option>-->
<!--                                </select>-->
<!--                                <select class="ql-font" title="字体">-->
<!--                                    <option value="SimSun">宋体</option>-->
<!--                                    <option value="SimHei">黑体</option>-->
<!--                                    <option value="Microsoft-YaHei">微软雅黑</option>-->
<!--                                    <option value="KaiTi">楷体</option>-->
<!--                                    <option value="FangSong">仿宋</option>-->
<!--                                    <option value="Arial">Arial</option>-->
<!--                                </select>-->
<!--                                &lt;!&ndash; Add subscript and superscript buttons &ndash;&gt;-->
<!--                                <select class="ql-color" value="color" title="字体颜色"></select>-->
<!--                                <select class="ql-background" value="background" title="背景颜色"></select>-->
<!--                                <select class="ql-align" value="align" title="对齐"></select>-->
<!--                                <button class="ql-clean" title="还原"></button>-->
<!--                                &lt;!&ndash; You can also add your own &ndash;&gt;-->
<!--                                <button class="ql-image" title="图片">图片</button>-->
<!--                            </div>-->
<!--                        </quill-editor>-->
                    </el-form-item>
                    <editor v-model="form.content" :min-height="300"/>
                    <el-form-item class="text-center" style="margin-top: 60px;">
                        <el-button
                                type="primary"
                                @click="handleSub"
                        >提交
                        </el-button
                        >
                        <el-button
                                @click="handleCanel"
                        >取消
                        </el-button
                        >
                    </el-form-item>
                </el-form>

            </div>

        </div>
        <el-dialog
                title=""
                :visible.sync="tipOpen"
                width="30%"
                center>
            <div style="text-align: center">用户您好，登录后可进行用户反馈！</div>
            <span slot="footer" class="dialog-footer">

            <el-button type="primary" @click="showLogin('1')">登 录</el-button>
                 <el-button type="primary" @click="showLogin('2')">还没有账号，去注册</el-button>
          </span>
        </el-dialog>
        <Vfooter/>
    </div>
</template>
<script>
    import { feedback } from "@/api/userFeedback/index"
    import {getUserInfo} from "@/utils/auth.js";
    import Vfooter from "@/components/footer.vue";
    import {getToken} from "@/utils/auth";
    import { Quill,quillEditor} from 'vue-quill-editor'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    // 自定义字体大小
    // let Size = Quill.import('attributors/style/size');
    // Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px'];
    // Quill.register(Size, true);
    //引入font.css
    import '@/assets/css/font.css'
    // 自定义字体类型
    var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif',
        '宋体', '黑体'
    ];
    var Font = Quill.import('formats/font');
    Font.whitelist = fonts;
    Quill.register(Font, true);
    // 工具栏配置
    // const toolbarOptions = [
    //     ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
    //     // 引用  代码块-----['blockquote', 'code-block']
    //     [{header: 1}, {header: 2}], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
    //     // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
    //     // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
    //     [{indent: '-1'}, {indent: '+1'}], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
    //     [{'direction': 'rtl'}], // 文本方向-----[{'direction': 'rtl'}]
    //     [{size: ['small', false, 'large', 'huge']}], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
    //     [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
    //     [{color: []}, {background: []}], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
    //     [{font: []}], // 字体种类-----[{ font: [] }]
    //     [{align: []}], // 对齐方式-----[{ align: [] }]
    //     // 清除文本格式-----['clean']
    //     ['image'] // 链接、图片、视频-----['link', 'image', 'video']
    // ];
    export default {
        components: {
            Vfooter,
           // quillEditor,
        },
        data() {
            return {
                tipOpen: false,
                form: {
                    type: '0',
                    content: '',
                },
                uploadHeaders: {
                    Authorization: 'Bearer ' + getToken()
                },
                editorOption: {
                    placeholder: '请输入反馈内容...',
                    theme: 'snow',
                    modules: {
                        toolbar: {
                            container: '#toolbar'
                            // container: toolbarOptions
                        }
                    }
                },
                rules: {
                  content:[
                    { required: true, message: '反馈内容不能为空', trigger: 'blur' },
                  ]
                }
            };
        },
        computed: {},
        created() {

        },
        methods: {
            //显示登录弹窗
            showLogin(val) {
                this.tipOpen = false;
                this.$store.dispatch('common/SET_LoginType', val);
                this.$Vlogin({flag: true, currentTab: val})
            },
            getIsDiag() {
                if (!getUserInfo()) {
                    this.tipOpen = true;
                    return true
                } else {
                    return false
                }
            },
            handleSub(){
                if(this.form.content===''){
                    this.$message({
                        message: '请填写反馈信息',
                        type: 'warning'
                    });
                }else{
                    // console.log('图片的大小改变',this.form.content);
                    this.$refs.form.validate(valid=>{
                      if (valid) {
                        feedback({
                          feedbackType:this.form.type,
                          feedbackContent:this.form.content
                        }).then(res=>{
                          if (res.code === 200){
                            this.$message.success('提交成功');
                            this.$refs.form.resetFields()
                          }
                        })
                      }
                    })
                }

            },
            handleCanel(){
                this.resetForm("form");

            },
        },
    };
</script>
<style lang="scss" scoped>
    .box-contain-divThree {
        min-height: 600px;
    }
    ::v-deep .el-form-item {
        margin-bottom: 0;
    }
</style>
