//维修信息
import request from '@/utils/request'

//维修信息列表
export function feedback(data) {
    return request({
        url: '/user/feedback',
        method: 'post',
        data:data,
        headers:{'isToken':true}
    })
}
