<template>
    <section class="layout-container" id="layout-container">
        <!--        <div class="top-tr">-->
        <!--                <div style="float: right;margin-right: 33px;">-->
        <!--                   <div style="display: inline-block">-->
        <!--                       <div class="user-info user-infoTwo" v-if="show">-->
        <!--                           <el-dropdown @command="commandTopNav">-->
        <!--                               <div class="el-dropdown-link">-->
        <!--                                   &lt;!&ndash;                                <img src="@/assets/img/20141229151653_HiM8B.jpeg" alt="" />&ndash;&gt;-->
        <!--                                   <span>{{ userName }}</span>-->
        <!--                                   <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
        <!--                               </div>-->
        <!--                               <el-dropdown-menu slot="dropdown">-->
        <!--                                   <el-dropdown-item command="logout">退出登录</el-dropdown-item>-->
        <!--                               </el-dropdown-menu>-->
        <!--                           </el-dropdown>-->
        <!--                       </div>-->
        <!--                   </div>-->
        <!--                    <span class="top-tr-com top-tr-login" v-if="!show"  @click="handleClick('1')">你好，请登录</span>-->
        <!--                    <span  class="top-tr-com top-tr-reg" v-if="!show" @click="handleClick('2')">我要注册</span>-->
        <!--                    <span  class="top-tr-com top-tr-shop">-->
        <!--                        <i class="el-icon-shopping-cart-2"></i>-->
        <!--                        购物车</span>-->
        <!--                </div>-->
        <!--        </div>-->
        <div class="bigBgcBox">
           <div style="position: relative" class="w1200">
               <div class="top_nav ">
                   <div class="top_nav_logo">
                       <img src="@/assets/img/logo1.png" class="logo-icon" @click="gotoNewPage('/')">
                       <span class="logo_title clearfix" @click="gotoNewPage('/')">机动车维修技术信息网</span>
                       <span class="logo_recommend_browser clearfix">为获得最佳浏览体验，建议使用谷歌浏览器84版本及以上</span>
                   </div>
                   <div class="top_nav_r">
                       <header class="layout-header">
                           <Vnav :menu="routerMenu"/>
                       </header>
                       <div class="rightLogin">
                           <div class="user-info " v-if="show">
                               <el-dropdown @command="commandTopNav">
                                   <div class="el-dropdown-link">
                                       <img src="@/assets/img/20141229151653_HiM8B.jpeg" alt=""/>
                                       <span>{{ userName }}</span>
                                       <i class="el-icon-arrow-down el-icon--right"></i>
                                   </div>
                                   <el-dropdown-menu slot="dropdown">
                                       <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                                   </el-dropdown-menu>
                               </el-dropdown>
                           </div>
                           <el-button type="primary" @click="handleClick('1')" v-if="!show" class="login">
                               登录
                           </el-button>
                           <el-button type="primary" @click="handleClick('2')" v-if="!show" class="login">
                               注册
                           </el-button>
                       </div>
                   </div>


               </div>

           </div>
        </div>
        <section class="layout-container layout-container-has-sider">
            <!--            <el-aside width="208px" class="layout-sider" v-if="hidden != 1">-->
            <!--                <el-menu :default-active="menuKey" @select="handleSelect">-->
            <!--                    <template v-for="item in childrenMenu">-->
            <!--                        <el-menu-item :index="item.name" :key="item.name" v-if="item.name !== '企业信息详细'" @click="ceshi(item)">-->
            <!--                            <i :class="item.meta.icon ? item.meta.icon : 'el-icon-menu'"></i>-->
            <!--                            <span slot="title">{{ item.name }}</span>-->
            <!--                        </el-menu-item>-->
            <!--                    </template>-->
            <!--                </el-menu>-->
            <!--            </el-aside>-->
            <section class="layout-container">
                <main class="layout-main" ref="layoutMain" id="layoutMain" @scroll="scrollFun" v-loading="loading"
                      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(0, 0, 0, 0.3)">
                    <keep-alive>
                        <router-view v-if="$route.meta.keepAlive"></router-view>
                    </keep-alive>
                    <router-view v-if="!$route.meta.keepAlive"></router-view>
                </main>
            </section>
        </section>

    </section>
</template>
<script>
    import Vnav from "../components/nav.vue";
    import {getUserInfo, removetUserInfo} from "@/utils/auth.js";
    import {logout} from "@/api/login.js";
    import {mapGetters} from "vuex";
    import store from "@/store";
    //菜单管理
    // import { selectFirstMenu } from "@/api/menu.js";

    //收藏

    export default {
        components: {
            Vnav,
        },
        data() {
            return {
                childrenMent: [],
                menuKey: "",
                show: false,
                userName: "",
                hidden: 1,
                checkLoginName: "",
                shows: false,
                //收藏
                colShow: false,
            };
        },
        computed: {
            ...mapGetters({routerMenu: "permission_routes", loading: "loading"}),
        },
        created() {
            this.checkLoginName = process.env.VUE_APP_CHECKLOGIN;
            let pId = this.$route.meta.acName || "";
            this.menuKey =
                this.$route.name == "企业信息详细" ? "企业信息" : this.$route.name;
            this.hidden = this.$route.meta.hidden;
            this.filterPath(pId);
            let userInfo = getUserInfo();
            if (getUserInfo()) {
                this.userName = userInfo.userName;
                this.show = true;
            } else {
                this.show = false;
            }
        },
        watch: {
            $route(to) {
                this.$refs.layoutMain.scrollTop = 0;
                this.menuKey = to.name == "企业信息详细" ? "企业信息" : to.name;
                this.hidden = to.meta.hidden;
                this.filterPath(to.meta.acName);
            },
        },
        mounted() {
            // this.selectFirstMenu();
            store.commit("scoll/getPheight", this.$refs.layoutMain.offsetHeight);
        },
        methods: {
            gotoNewPage(val) {
                this.$router.push(val)
            },
            //获取子菜单
            filterPath(name) {
                let filter = this.routerMenu.filter((item) => {
                    return item.name == name;
                });
                if (filter.length > 0 && filter[0].children) {
                    this.childrenMenu = filter[0].children.filter(
                        (item) => item.meta.hidden == 0
                    );
                } else {
                    this.childrenMenu = [];
                }
            },

            //显示登录弹窗
            showLogin(val) {
                this.$store.dispatch('common/SET_LoginType', val)
                this.$Vlogin({flag: true, currentTab: val});
            },
            //下拉菜单
            scrollFun(e) {
                store.dispatch("scoll/setPosition", e.target.scrollTop);
            },
            // selectFirstMenu() {
            //     selectFirstMenu().then((res) => {
            //         if (res.ok) {
            //             this.selectFirstMenuData = res.data;
            //         }
            //     });
            // },
            commandTopNav(name) {
                if (name == "contact") {
                    this.$refs.layoutMain.scrollTop =
                        this.$refs.layoutMain.scrollHeight;
                    return;
                }
                if (!getUserInfo()) {
                    this.showLogin('1');
                    return false;
                }
                if (name == "feedback") {
                    this.shows = true;
                }

                if (name == "logout") {
                    this.$confirm("是否退出登录", "提示", {
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.$store.dispatch('user/LogOut').then((res) => {
                            removetUserInfo();
                            window.location = "/";
                        })
                        // logout().then((res) => {
                        //     if (res.ok) {
                        //         if (this.checkLoginName == "zgqcgyxxw") {
                        //             removetUserInfo();
                        //             window.location = "/";
                        //         } else {
                        //             removetUserInfo();
                        //             this.$router.push("/login");
                        //         }
                        //     }
                        // });
                    });
                }
            },
            handleSelect() {
                this.$store.dispatch("notice/SET_PC", 0);
                this.$store.dispatch("notice/SET_CPLB", "");
                this.$store.dispatch("common/SET_qiehuan", "");
            },
            ceshi(item) {
                this.$router.push(item.path);
            },
            // command(name) {
            //     this.$emit("command", name);
            // },
            handleClick(val) {
                this.showLogin(val);
            },
        },
    };
</script>
<style lang="scss" scoped>
    .layout-container {
        height: 100%;
        min-height: 0;
    }

    .layout-container {
        background: #f6f6f6;
    }

    .layout-main {
        position: relative;
        /*height: 100%;*/
        height: calc(100% + 40px);
        min-height: 0;
        overflow-x: hidden;
        // padding: 0 24px;
    }

    .layout-main.hide {
        margin: 0;
    }

    .layout-container .layout-container-has-sider {
        // margin-top: 10px;
        height: calc(100% - 135px);
    }

    .layout-container .layout-container-has-sider-fh {
        height: calc(100% - 55px);
    }

    #layout-container .layout-header {
        position: relative;
        z-index: 1;

        // box-shadow: 0px 4px 8px rgba(214, 214, 214, 0.25);
    }

    #layout-container .layout-header,
    #layout-container .layout-sider {
        /*background: #fff;*/
        background: #0D57BC;
    }

    #layout-container .layout-sider {
        float: left;
        height: calc(100% - 10px);
        margin-right: 24px;
        margin-top: 10px;
        overflow-y: auto;
        //  box-shadow: 0px 4px 8px rgba(214, 214, 214, 0.5);
        .el-menu-item.is-active,
        .el-menu-item:hover {
            background: rgba($color: #0d57bc, $alpha: 0.03);
            color: #0d57bc;
        }
    }

    #layout-container .layout-header {
        padding: 0;
        padding-top: 10px;
    }

    #layout-container .el-menu {
        border-right: none;
    }

    #layout-container .el-menu .el-menu-item {
        font-weight: bold;
    }

    .top_nav {
        display: flex;
        justify-content: space-between;
        /*justify-content: flex-start;*/
        align-items: flex-start;
        /*width: 100%;*/
        /*background: #fff;*/
        background: #0D57BC;
        padding-right: 20px;
        position: relative;
        z-index: 1;

        .top_nav_logo {
            position: relative;
            /*width: 500px;*/
            width: 380px;
            height: 82px;
        }

        .logo_title {
            cursor: pointer;
            color: #fff;
            position: absolute;
            font-size: 20px;
            font-weight: bold;
            top: 15px;
            /*left: 76px;*/
            left: 63px;

        }

        .logo_recommend_browser {
            color: #fff;
            position: absolute;
            font-size: 10px;
            bottom: 17px;
            left: 63px;
        }

        .logo-icon {
            cursor: pointer;
            margin-top: 13px;
            /*margin-left: 20px;*/
            margin-left: 0px;
            margin-bottom: 13px;
            height: 56px;
        }

        .top_nav_r {
            display: flex;
            margin-top: 15px;
        }

    }
    /*.rightLogin {*/
    /*   position: absolute;*/
    /*    top:15px;*/
    /*    right: 0;*/
    /*    z-index: 100;*/
    /*    margin-right: 20px;*/
    /*}*/

    .user-info {
        font-weight: bold;
        color: #333;
        height: 36px;
        margin-left: 37px;
        padding-top: 10px;

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }

        span {
            /*color: #333;*/
            color: #fff;
            padding-left: 8px;


        }

        i {
            /*color: #333;*/
            color: #fff;
        }

    }

    ::v-deep .user-infoTwo {
        display: inline-block;
    }

    .login {
        width: 70px;
        margin-top: 8px;
        margin-left: 20px;
        height: 40px;
        font-weight: bold;
        /*border: 2px solid #0d57bc;*/
        border: 2px solid #fff;
        color: #fff;
        background-color: #0D57BC;
    }

    .login:hover {
        border: 2px solid #fff;
        color: #fff;
        background-color: #0D57BC;
    }

    .login:active {
        border: 2px solid #fff;
        color: #fff;
        background-color: #0D57BC;
    }

    .top-tr {
        width: 100%;
        height: 20px;
        font-size: 10px;
        background-color: #F2DDDD;
        /*background-color:rgba();*/

    }

    .top-tr-com {
        display: inline-block;
        padding: 0 10px;
        cursor: pointer;

    }

    .top-tr-login {
        color: #0D57BC

    }

    .top-tr-reg:hover {
        color: #0D57BC
    }

    .top-tr-shop:hover {
        color: #0D57BC
    }
</style>
