<template>
    <div class="bigBox">
        <div class="regis-tit">找回密码</div>
        <el-form :model="form" :rules="rules" ref="form" class="form-FindPassword">
            <el-form-item label="" prop="phonenumber">
                <el-input placeholder="输入注册手机号" v-model="form.phonenumber"></el-input>
            </el-form-item>
            <el-form-item prop="code2">
                <el-input v-model="form.code2" auto-complete="off" placeholder="验证码"
                          style="width: 70%;vertical-align: middle" >
                    <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon"/>
                </el-input>
                <div class="login-code">
                    <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                </div>
            </el-form-item>
            <el-form-item label="" prop="code">
                <el-input placeholder="请输入短信验证码" v-model="form.code">
                    <template slot="append">
                        <div  :class="codeClick?'getCodeMess':''" v-if="!isGetting" @click="getCodeMess" :loading="loading1">获取短信验证码</div>
                        <div  class="codeTimeReduce" v-else>重新获取（<span>{{codeTime}}</span>）</div>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item label="" class="is-hidden">
                <el-input   type="text"  ></el-input>
            </el-form-item>
            <el-form-item label="" class="is-hidden" >
                <el-input    type="password" ></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
                <el-input  placeholder="密码必须包含大小写字母、数字、特殊字符中的3种,且8位以上"  type="password"  v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="" prop="confirmPassword">
                <el-input placeholder="请输入确认密码"  type="password"  v-model="form.confirmPassword"></el-input>
            </el-form-item>

            <el-form-item class="text-center" style="margin-top: -5px">
                <el-button
                        type="primary"
                        @click="handleFindPassword()"
                        style="width: 100%"
                        :loading="loading"
                >找回密码
                </el-button
                >
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import { validPhone} from '@/utils/validate'
    import {phoneMessage,findPassword,getCodeImg,isRegAgree,isPass} from "@/api/login.js";

    import CryptoJS from '@/utils/crypto-js.js'
    let key = 'TJzhongqiyan1006';
    key = CryptoJS.enc.Utf8.parse(key);


    export default {
        name: "FindPassword",
        data() {
            const equalToPassword = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入确认密码'));
                }
                if (this.form.password !== value) {
                    callback(new Error("密码不一致，请重新输入"));
                } else {
                    callback();
                }
            };
            var validPassword = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入密码'));
                }
                // var a = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])$/ ;
                // var a1 = /^(?=.*\d)(?=.*[a-z])(?=.*[!@#\^*]+)$/ ;
                // var b=/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\^*]+)$/ ;
                // var c=/^(?=.*\d)(?=.*[A-Z])(?=.*[!@#\^*]+)$/;
                // var d=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\^*]+)$/;
                let reg=/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z!@#\^*]+$)(?![a-z0-9]+$)(?![a-z!@#\^*]+$)(?![0-9!@#\^*]+$)[a-zA-Z0-9!@#\^*]{8,}$/
                if(reg.test(value)){
                    callback();
                }else {
                    callback(new Error('密码必须包含大小写字母、数字、特殊字符中的3种,且8位以上'));
                }
            };
            var validateUserPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入手机号码'));
                }
                setTimeout(() => {
                    if (validPhone(value)) {
                        callback();
                    } else {
                        callback(new Error('手机格式不正确'));
                    }
                }, 100);
            };

            return {
                codeClick:false,
                form: {
                    confirmPassword: "",
                    password: "",
                    phonenumber: "",
                    code: "",
                    code2: "",
                },
                isGetting:false,
                codeUrl: '',
                codeTime:60,
                rules: {
                    code: [{
                        required: true,
                        message: '请输入短信验证码',
                        trigger: 'blur'
                    }],
                    code2: [{
                        required: true,
                        message: '请输入短信验证码',
                        trigger: 'blur'
                    }],
                    password: [
                        {validator: validPassword, trigger: "blur"},
                        // {
                        //     required: true,
                        //     message: '请输入密码',
                        //     trigger: 'blur'
                        // },
                        // {
                        //     // pattern: /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*\$])[a-z\d#@!~%^&*\$]{8,}/i,
                        //     pattern: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#*]).{8,16}/,
                        //     message: "密码必须包含大小写字母、数字、特殊字符中的3种,且8位以上",
                        //     trigger: "blur"
                        // },
                    ],
                    confirmPassword: [{validator: equalToPassword, trigger: "blur"}],
                    phonenumber: [{validator: validateUserPhone, trigger: "blur"}],

                },
                loading: false,
                loading1: false,
            };
        },
        watch:{
            'form.phonenumber':{
                handler(val){
                    if(validPhone(val)){
                        this.codeClick=true
                    }else {
                        this.codeClick=false
                    }
                },
            },
        },

        created() {
            this.getCode()
        },
        methods: {
            getCode() {
                getCodeImg().then((res) => {
                    this.codeUrl = 'data:image/gif;base64,' + res.img
                    this.form.uuid2 = res.uuid
                })
            },
            //获取短信验证码
            getCodeMess(){
                this.form.uuid=''
                if(!validPhone(this.form.phonenumber)){
                    return
                }
                if(!this.form.code2){
                    return this.$message.error('请先输入动态校验码')
                }
                this.loading1=true
                isPass({code: this.form.code2,uuid:this.form.uuid2}).then(res=>{
                    phoneMessage({phonenumber: this.form.phonenumber}).then(res => {
                        this.loading1=false
                        if (res.code == 200) {
                            this.isGetting = true
                            this.$message.success('手机验证码已发送')
                            this.codeTime = 59
                            this.form.uuid = res.uuid
                            let index = setInterval(() => {
                                if (this.codeTime > 0) {
                                    this.codeTime--
                                } else {
                                    clearInterval(index)
                                    this.isGetting = false
                                }

                            }, 1000)
                        }
                    }).catch(e=>{
                        this.loading1=false
                    })
                }).catch(e=>{
                    this.getCode()
                    this.loading1=false
                })


            },
            //找回密码
            handleFindPassword(){
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.loading = true
                        isRegAgree({phonenumber:this.form.phonenumber}).then(res=>{
                            if(res.code==200){
                              let form = Object.assign({},this.form)
                              // let content = CryptoJS.enc.Utf8.parse(form.password);
                              // let encryptResult = CryptoJS.AES.encrypt(content, key, {
                              //   mode: CryptoJS.mode.ECB, //aes加密模式
                              //   padding: CryptoJS.pad.Pkcs7, //填充
                              // });
                              // let formData = CryptoJS.enc.Base64.stringify(encryptResult.ciphertext);
                              form.password= this.$MD5(form.password)

                                findPassword(form).then(res => {
                                    this.loading = false
                                    if (res.code == 200) {
                                        this.$emit('handelRegister', '1')
                                        // this.$message.success('注册成功')

                                    }
                                }).catch(e => {
                                    this.loading = false
                                    this.getCode()
                                })
                            }else {
                                this.loading = false
                                this.$message.error(res.msg)
                            }

                        }).catch(e => {
                            this.loading = false
                            this.getCode()
                        })


                    }
                })
            },

        },
    };
</script>
<style lang="scss" scoped>
    .login-code {
        vertical-align: middle;
        position: absolute;
        right: 0;
        top: 0;
        display: inline-block;
        width: 23%;

        img {
            width: 100%;
        }

    }
    .bigBox {
        padding: 50px 20px 20px;
    }

    .form-FindPassword {
        width: 440px;
        margin: 20px auto;
    }

    .regis-tit {
        font-size: 18px;
        margin: auto;
        text-align: center;
        font-weight: 700;
    }
    ::v-deep .el-input-group__append .getCodeMess {
        cursor: pointer;
        color: #0D57BC;

    }
    .getArgee {
        cursor: pointer;
        color: #0D57BC;
    }
    .codeTimeReduce {

    }
    .dl-regis-findPass {
        margin-top: -15px;
        color: #333;
        .FindPassword {
            /*float: left;*/
            color: #169BD5;
            cursor: pointer;
        }
        .findPassword {
            float: right;
            color: #169BD5;
            cursor: pointer;
        }
    }
    ::v-deep .el-form-item {
        margin-bottom: 20px;
    }
    ::v-deep .agreeClass .el-form-item__error {
        margin-top: -10px;
    }
    .is-hidden {
        position: absolute;
    }
</style>
