<template>
  <div>
    <div class="other-list">
      <h1 class="other-list-title">提交反馈</h1>
    </div>
    <div class="feedbacks-table">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="反馈类型:" prop="type">
          <el-radio v-model="form.type" label="0">投诉</el-radio>
          <el-radio v-model="form.type" label="1">建议</el-radio>
          <el-radio v-model="form.type" label="2">问题反馈</el-radio>
        </el-form-item>
        <el-form-item label="反馈内容:" prop="content" class="editorBox">
          <!--                        <quill-editor style="margin-top: 40px;height: 300px" ref="text" v-model="form.content" class="myQuillEditor"-->
          <!--                                      :options="editorOption">-->
          <!--                            &lt;!&ndash; 自定义toolar &ndash;&gt;-->
          <!--                            <div id="toolbar" slot="toolbar">-->
          <!--                                &lt;!&ndash; Add a bold button &ndash;&gt;-->
          <!--                                <button class="ql-bold" title="加粗">Bold</button>-->
          <!--                                <button class="ql-italic" title="斜体">Italic</button>-->
          <!--                                <button class="ql-underline" title="下划线">underline</button>-->
          <!--                                <button class="ql-strike" title="删除线">strike</button>-->
          <!--                                <button class="ql-blockquote" title="引用"></button>-->
          <!--                                <button class="ql-code-block" title="代码"></button>-->
          <!--                                <button class="ql-header" value="1" title="标题1"></button>-->
          <!--                                <button class="ql-header" value="2" title="标题2"></button>-->
          <!--                                &lt;!&ndash;Add list &ndash;&gt;-->
          <!--                                <button class="ql-list" value="ordered" title="有序列表"></button>-->
          <!--                                <button class="ql-list" value="bullet" title="无序列表"></button>-->
          <!--                                &lt;!&ndash; Add font size dropdown &ndash;&gt;-->
          <!--                                <select class="ql-header" title="段落格式">-->
          <!--                                    <option selected>段落</option>-->
          <!--                                    <option value="1">标题1</option>-->
          <!--                                    <option value="2">标题2</option>-->
          <!--                                    <option value="3">标题3</option>-->
          <!--                                    <option value="4">标题4</option>-->
          <!--                                    <option value="5">标题5</option>-->
          <!--                                    <option value="6">标题6</option>-->
          <!--                                </select>-->
          <!--                                <select class="ql-size" title="字体大小">-->
          <!--                                    <option value="10px">10px</option>-->
          <!--                                    <option value="12px">12px</option>-->
          <!--                                    <option value="14px">14px</option>-->
          <!--                                    <option value="16px" selected>16px</option>-->
          <!--                                    <option value="18px">18px</option>-->
          <!--                                    <option value="20px">20px</option>-->
          <!--                                </select>-->
          <!--                                <select class="ql-font" title="字体">-->
          <!--                                    <option value="SimSun">宋体</option>-->
          <!--                                    <option value="SimHei">黑体</option>-->
          <!--                                    <option value="Microsoft-YaHei">微软雅黑</option>-->
          <!--                                    <option value="KaiTi">楷体</option>-->
          <!--                                    <option value="FangSong">仿宋</option>-->
          <!--                                    <option value="Arial">Arial</option>-->
          <!--                                </select>-->
          <!--                                &lt;!&ndash; Add subscript and superscript buttons &ndash;&gt;-->
          <!--                                <select class="ql-color" value="color" title="字体颜色"></select>-->
          <!--                                <select class="ql-background" value="background" title="背景颜色"></select>-->
          <!--                                <select class="ql-align" value="align" title="对齐"></select>-->
          <!--                                <button class="ql-clean" title="还原"></button>-->
          <!--                                &lt;!&ndash; You can also add your own &ndash;&gt;-->
          <!--                                <button class="ql-image" title="图片">图片</button>-->
          <!--                            </div>-->
          <!--                        </quill-editor>-->
        </el-form-item>
        <editor v-model="form.content" :min-height="300"/>
        <el-form-item class="text-center" style="margin-top: 60px;">
          <el-button @click="closeBtn">关闭</el-button>
          <el-button type="primary" @click="handleSub">提交</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>
<script>
// 获取个人中心请求接口
import { feedback } from "@/api/userFeedback/index"
import {getToken, getUserInfo} from "@/utils/auth.js";
import { Quill,quillEditor} from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '@/assets/css/font.css'
// 自定义字体类型
var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif',
  '宋体', '黑体'
];
var Font = Quill.import('formats/font');
Font.whitelist = fonts;
Quill.register(Font, true);

export default {
  components: {},
  data() {
    return {
      tipOpen: false,
      form: {
        type: '0',
        content: '',
      },
      uploadHeaders: {
        Authorization: 'Bearer ' + getToken()
      },
      editorOption: {
        placeholder: '请输入反馈内容...',
        theme: 'snow',
        modules: {
          toolbar: {
            container: '#toolbar'
            // container: toolbarOptions
          }
        }
      },
      rules: {
        content:[
          { required: true, message: '反馈内容不能为空', trigger: 'blur' },
        ]
      }
    };
  },
  computed: {},
  created() {
  },
  methods: {
    getIsDiag() {
      if (!getUserInfo()) {
        this.tipOpen = true;
        return true
      } else {
        return false
      }
    },
    handleSub(){
      if(this.form.content===''){
        this.$message({
          message: '请填写反馈信息',
          type: 'warning'
        });
      }else{
        // console.log('图片的大小改变',this.form.content);
        this.$refs.form.validate(valid=>{
          if (valid) {
            feedback({
              feedbackType:this.form.type,
              feedbackContent:this.form.content
            }).then(res=>{
              if (res.code === 200){
                this.$message.success('提交成功');
                this.$refs.form.resetFields()
                this.closeBtn()
              }
            })
          }
        })
      }

    },
    closeBtn(){
      this.$router.push({path:"/personalCenter/feedback"})
    },
    handleCanel(){
      this.resetForm("form");

    },

  },
};
</script>
<style lang="scss" scoped>
.other-list {
  .other-list-title {
    margin: 0 20px 25px;
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #555;
    font-weight: bold;
    font-size: 20px;
  }
}
.feedbacks-btn-box{
  text-align: right;
  margin: 10px 20px;
}
.feedbacks-btn{
  width: 143px;
  height: 43px;
  border: none;
  font-size: 20px;
  background: #0099ff;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
}
.feedbacks-table{
  margin: 0 20px;
}
</style>
