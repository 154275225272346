//维修信息
import request from '@/utils/request'

//维修信息列表
export function maintenanceServiceList(data) {
    return request({
        url: '/user/repair/selectListByQuery',
        method: 'get',
        params:data,
        headers:{'isToken':true}
    })
}
//获取款型
export function getVehicleModelStyle(data) {
    return request({
        url: '/user/index/styleList',
        method: 'get',
        params:data,
    })
}
//获取文件树
export function getFileTree(data) {
    return request({
        url: '/user/repairFile/queryFileDirList',
        method: 'get',
        params:data,
        headers:{'isToken':true}
    })
}
//获取右侧图片
export function getRightImg(data) {
    return request({
        url: '/user/repairFile/previewFile',
        method: 'get',
        params:data,
        headers:{'isToken':true}
    })
}
//打印
export function printFile(data) {
    return request({
        url: '/user/repairFile/printFile',
        method: 'get',
        params:data,
        responseType: 'blob',
        headers:{'isToken':true}
    })
}
//下载
export function downFile(data) {
    return request({
        url: '/user/repairFile/downloadZipFile',
        method: 'post',
        params:data,
        responseType: 'blob',
        headers:{'isToken':true}
    })
}
