<template>
    <div>
        <div class="box-contain">
            <!--            <div class="w1200">-->
            <banner :autoTime="4000" :banner="banner"></banner>
            <!--            公告-->
            <div class="index-announce">
                <span class="announce-left">
                    <span class="announce-left-tit">最新公告</span>
                    <i class="iconfont icon-shengyin announceImg"></i>
                </span>
                <span class="announce-center" @click="announceDetail(announceObj)" v-loading="loading">
                    <div class="announce-center-box">
                        <span class="announce-left-title clearfix">{{ announceObj.noticeTitle }} </span>
                        <span class="announce-left-time clearfix">{{ announceObj.createTime ?
                                announceObj.createTime.substring(0, 10) : ''
                        }}</span>
                    </div>
                </span>
                <span class="viewMore" @click="announceList('3')">
                    查看更多公告
                </span>

            </div>
            <!--                VIN查询-->
            <div class="Vin-big-box">
                <el-form class="Vin-small-box" :model="queryParams" :rules="rules" ref="queryForm" :inline="true">
                    <div class="vin-input">
                        <el-form-item prop="vin">
                            <el-input v-model="queryParams.vin" placeholder="请输入17位VIN码" clearable size="small"
                                maxlength="17" />
                        </el-form-item>
                    </div>
                    <div class="vin-code">
                        <div class="vin-code-div">
                            <el-form-item prop="code">
                                <el-input v-model="queryParams.code" auto-complete="off" placeholder="请输入右侧验证码"
                                    style="width: 67%;vertical-align: middle" size="small">
                                    <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
                                </el-input>
                                <div class="login-code">
                                    <img :src="codeUrl" @click="getCode" class="login-code-img" />
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="vin-btn">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery(1)"
                                :loading="loadingFour">VIN查询
                            </el-button>
                        </el-form-item>

                    </div>
                </el-form>
            </div>
            <!--                维修信息-->
            <div class="maintenance-services">
                <div class="main-serve-title">维修信息</div>
                <div class="main-serve-content">
                    <div class="hot-brand">
                        <div class="main-serve-content-head">热门品牌</div>
                        <div class="h-b-i-d-b">
                            <div class="hot-brand-img-div" v-loading="loadingOne">
                                <!--                               <img src="../../assets/img/hotbrand1.jpg" v-for="(item,index) in hotBarandList" @click="handelVehicle(2,item)" :key="index+'_3'" />-->
                                <div class="hot-brand-div-div" v-for="(item, index) in hotBarandList"
                                    @click="handelVehicle(2, item)" :key="index + '_3'">
                                    <img :src="item.logoUrl" />
                                    <span class="hot-span">{{ item.brandName }}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="all-brand">
                        <div class="main-serve-content-head">全部品牌</div>
                        <div class="all-brand-content">
                            <div class="englishWord">
                                <!--                                <div class="En-word" :class="currentClickWord==item?'currentWord':''" v-for="(item,index) in englishWord" :key="index+'_4'" @click="handelEnglishWord(item)">{{item}}</div>-->
                                <div class="En-word" :class="currentClickWord == item.firstChar ? 'currentWord' : ''"
                                    v-for="(item, index) in allBarandList" :key="index + '_4'"
                                    @click="handelEnglishWord(item.firstChar)">{{ item.firstChar }}
                                </div>
                            </div>

                            <el-row class="englishWordBrand" v-loading="loadingTwo" :gutter="20">
                                <el-col class="en-word-col"
                                    :class="currentClickBrand.id == item.id ? 'en-word-col-click' : ''" :span="2.5"
                                    v-for="(item, index) in allBarandListWord" :key="index + '_5'">
                                    <span @click="handelBrand(item)">{{ item.brandName }}</span>
                                </el-col>
                            </el-row>

                        </div>

                    </div>
                    <div class="models" v-if="currentClickBrand && currentClickBrand.id">
                        <div class="main-serve-content-head">车型</div>
                        <el-row class="englishWordBrand" v-loading="loadingThree" :gutter="20">
                            <el-col class="en-word-col" :span="4" v-for="(item, index) in vehicleList"
                                :key="index + '_5'">
                                <span @click="handelVehicle(3, item)">{{ item.vehicleModelName }}</span>
                            </el-col>
                            <el-col v-if="!vehicleList.length" class="en-word-col" style="color: #ccc" :span="24">
                                该品牌下暂无车型
                            </el-col>
                        </el-row>
                    </div>
                </div>

            </div>


            <!--                政策法规，资讯-->
            <el-row :gutter="40">
                <el-col :span="12">
                    <messagelistNew :tableList="currentObjInf" currentType="1" :currentLoadState="currentLoad">
                        <div slot="slotNameVal" class="nameVal">行业资讯</div>
                        <div slot="viewMore">
                            <div class="viewMoreClass" @click="announceList('1')">查看更多</div>
                        </div>
                    </messagelistNew>

                </el-col>
                <el-col :span="12">
                    <messagelistNew :tableList="currentObjPoc" currentType="2">
                        <div slot="slotNameVal" class="nameVal">政策法规</div>
                        <div slot="viewMore">
                            <div class="viewMoreClass" @click="announceList('2')">查看更多</div>
                        </div>
                    </messagelistNew>
                </el-col>
            </el-row>
        </div>
        <el-dialog title="" :visible.sync="tipOpen" width="30%" center>
            <div style="text-align: center">用户您好，登录后可查看免费维修信息内容！</div>
            <span slot="footer" class="dialog-footer">

                <el-button type="primary" @click="showLogin('1')">登 录</el-button>
                <el-button type="primary" @click="showLogin('2')">还没有账号，去注册</el-button>
            </span>
        </el-dialog>


        <!--        </div>-->
        <Vfooter />
    </div>
</template>
<script>
import Vfooter from "@/components/footer.vue";
import banner from "@/components/banner.vue";
import messagelistNew from "@/components/messagelistNew/index.vue";
// 获取首页请求接口
import { newAnnounce, hotBrand, allBrand, getVehicle, getVinYzm, listBrand, getVinQSW,getBanner } from "@/api/Index.js";
import { getCodeImg } from "@/api/login.js";
import { getUserInfo } from "@/utils/auth.js";

export default {
    components: {
        Vfooter,
        banner,
        messagelistNew
    },
    data() {
        return {
            tipOpen: false,
            currentClickWord: 'A',
            currentClickBrand: {},
            currentClickVehicle: {},
            loading: false,
            loadingOne: false,
            loadingTwo: false,
            loadingThree: false,
            loadingFour: false,
            banner: [],
            announceObj: {
                noticeId: 1,
                noticeTitle: '',
                noticeContent: "",
                time: '',
            },
            queryParams: {
                vin: '',
                code: '',
            },
            codeUrl: '',
            uuid: '',
            currentObjInf: [],
            currentObjPoc: [],
            currentLoad: false,
            hotBarandList: [],
            allBarandList: [],
            rules: {
                vin: [
                    {
                        min: 17,
                        max: 17,
                        required: true,
                        message: '请输入17位VIN码',
                        trigger: 'blur'
                    },
                ],
                code: [{
                    required: true,
                    message: '请输入右侧验证码',
                    trigger: 'blur'
                },]
            },
            englishWord: [
                'A', 'B', 'C', 'D', 'E', 'F', 'G',
                'H', 'I', 'J', 'K', 'L', 'M', 'N',
                'O', 'P', 'Q', 'R', 'S', 'T',
                'U', 'V', 'W', 'X', 'Y', 'Z',
            ],
            allBarandListWord: [],
            vehicleList: [],

        };
    },
    computed: {},
    watch: {
        'currentClickBrand': {
            handler(val) {
                this.getVehicle(val.id)
            },
            deep: true,
        }

    },
    created() {
        this.getAnnouce()
        this.getCode()
        this.getHotBrand()
        this.getAllBrand()
    },
    mounted() {
        this.getBanner()

    },
    methods: {
        getBanner(){
            getBanner().then(res=>{
                if(res.code == 200){
                    this.$nextTick(()=>{
                        this.banner = res.data
                    })
                }
            })
        },
        getIsDiag() {
            if (!getUserInfo()) {
                this.tipOpen = true
                return true
            } else {
                return false
            }
        },
        //显示登录弹窗
        showLogin(val) {
            this.tipOpen = false
            this.$store.dispatch('common/SET_LoginType', val)
            this.$Vlogin({ flag: true, currentTab: val })
        },
        //点击英文单词
        handelEnglishWord(item) {
            this.currentClickWord = item
            let arr = []
            arr = this.allBarandList.filter((el) => {
                return el.firstChar == item
            });
            if (arr.length) {
                arr.forEach((item, index) => {
                    this.allBarandListWord = JSON.parse(item.brandList)
                })
            } else {
                this.allBarandListWord = []
            }

        },
        //点击品牌
        handelBrand(item) {
            this.currentClickBrand = item
        },

        //获取车型
        getVehicle(id) {
            this.loadingThree = true
            getVehicle({ brandId: id }).then(res => {
                this.loadingThree = false
                if (res.code == 200) {
                    this.vehicleList = res.data
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(e => {
                this.loadingThree = false
            })
        },
        //获取热门品牌
        getHotBrand() {
            this.loadingOne = true
            listBrand({ pageSize: 7, pageNum: 1, hot: '1' }).then(res => {
                this.loadingOne = false
                if (res.code == 200) {
                    console.log(res.rows);
                    this.hotBarandList = res.rows
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(e => {
                this.loadingOne = false
            })
        },
        //获取全部品牌
        getAllBrand() {
            this.loadingTwo = true
            allBrand().then(res => {
                this.loadingTwo = false
                if (res.code == 200) {
                    this.allBarandList = res.data
                    // this.currentClickWord=this.allBarandList[0].firstChar
                    this.handelEnglishWord(this.allBarandList[0].firstChar)
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(e => {
                this.loadingTwo = false
            })
        },
        getCode() {
            getCodeImg().then((res) => {
                this.codeUrl = 'data:image/gif;base64,' + res.img
                this.queryParams.uuid = res.uuid
            })
        },
        //获取最新公告
        getAnnouce() {
            this.loading = true
            newAnnounce({ pageNum: 1, pageSize: 10 }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.announceObj = res.rows[0]
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(e => {
                this.loading = false
            })
        },
        //
        //查看公告详情
        announceDetail(val) {
            this.$router.push({ path: '/messageDetail', query: { type: '3', id: val.noticeId } })

        },
        //查看更多
        announceList(type) {
            if (type == 3) {
                this.$router.push({ path: '/messageList' })
            } else {
                this.$router.push({ path: '/messageListPocy', query: { type: type } })
            }


        },
        //VIN查询
        handleQuery(type, val) {
            if (this.getIsDiag()) {
                return
            }
            this.$refs.queryForm.validate((valid) => {
                if (valid) {
                    this.loadingFour = true
                    getVinYzm(this.queryParams).then(res => {
                        this.loadingFour = false
                        if (res.code == 200) {
                            if (res.data && res.data.length > 0) {
                                this.$router.push({ path: '/maintenanceService/index', query: { vin: this.queryParams.vin } });
                            } else {
                                getVinQSW(this.queryParams.vin).then(res => {
                                    if (res.code == 200) {
                                        this.$router.push({ path: '/maintenanceService/index', query: { vin: this.queryParams.vin } });
                                        this.getCode()
                                    } else {
                                        this.getCode()
                                    }
                                })
                            }
                            this.getCode()
                        } else {
                            this.getCode()
                        }
                    }).catch(e => {
                        this.loadingFour = false
                        this.getCode()

                    })

                }
            })

        },
        //点击品牌
        handelVehicle(type, item) {
            if (this.getIsDiag()) {
                return
            }
            if (type == 2) {
                this.$router.push({
                    path: '/maintenanceService/index',
                    query: { manufacturerId: item.manufacturerId, brandId: item.id, vehicleModel: '-1' }
                });
            } else if (type == 3) {
                this.$router.push({
                    path: '/maintenanceService/index',
                    query: { manufacturerId: item.manufacturerId, brandId: item.brandId, vehicleModelId: item.id }
                });
            }

            // this.handelVehicle=item
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__error {
    padding-top: 0;
    top: 100%;
}

.box-contain {
    margin-top: 20px;
}

/*公告*/
.index-announce {
    background-color: rgba(242, 242, 242, 1);
    border: 1px solid rgba(215, 215, 215, 1);
    border-radius: 8px;
    height: 50px;
    margin: 20px 0;
    padding: 5px 1.5%;
    display: flex;
    justify-content: space-between;

    .announce-left {
        display: inline-block;
        line-height: 40px;
        vertical-align: middle;
        /*float: left;*/

        .announce-left-tit {
            font-weight: 700;
            margin-right: 10px;

        }

        .announceImg {
            color: #0D57BC;
        }
    }

    .announce-center {
        display: inline-block;
        vertical-align: middle;
        line-height: 40px;


        width: 70%;
        text-align: center;
        margin: auto;

        .announce-center-box {
            display: block;
            margin: auto;
            cursor: pointer;
            /*width: 700px;*/
            width: 100%;
        }

        .announce-left-title {

            float: left;
            /*width: 600px;*/
            width: 80%;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;


        }

        .announce-left-time {
            float: right;
        }

    }

    .announce-center:hover {

        color: #0D57BC;


    }

    .viewMore {
        line-height: 40px;
        /*float: right;*/
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        /*margin-top: 3px;*/
        font-size: 12px;
        color: #169BD5;
    }
}

/*vin查询*/
.Vin-big-box {
    border-radius: 8px;
    padding: 15px 30px 16px 1.5%;
    background-color: rgba(242, 242, 242, 1);
    border: 1px solid rgba(215, 215, 215, 1);
    margin-bottom: 20px;

    .Vin-small-box {
        display: flex;
        justify-content: space-between;

        ::v-deep .el-form-item {
            margin-bottom: 0;
            width: 100%;

        }

        ::v-deep.el-form--inline,
        ::v-deep.el-form-item__content {
            display: block;

        }

        .vin-input {
            width: 40%;
        }

        .vin-code {
            width: 30%;


        }

        .vin-btn {
            text-align: right;
            width: 20%;
        }
    }
}

.nameVal {
    display: inline-block;
    float: left;

}

.viewMoreClass {
    display: inline-block;
    cursor: pointer;
    float: right;
    margin-top: 5px;
    font-size: 12px;
}

.login-code {
    vertical-align: middle;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    height: 28px;
    width: 28%;

    img {
        height: 100%;
        /*width: 80%;*/
    }

}

/*    维修信息*/
.maintenance-services {
    background-color: #f2f2f2;
    border-radius: 8px;
    margin-bottom: 20px;


    .main-serve-title {
        color: #fff;
        font-size: 16px;
        height: 45px;
        padding: 10px 20px;
        border-radius: 8px 8px 0 0;
        background: url('../../assets/img/brand.png') center center;
        background-size: cover;
        /*background-color: #0D57BC;*/

    }

    .main-serve-content {
        border: 1px solid #ccc;
        border-top: none;
        padding: 10px 20px 20px;

        .hot-brand {
            border-bottom: 1px solid #ccc;
            margin-bottom: 20px;

            .h-b-i-d-b {
                margin-top: 10px;
                margin-bottom: 9px;
                text-align: center;
            }

            .hot-brand-img-div {
                display: inline-block;
                padding: 10px 0px;
                /*height: 120px;*/
                min-width: 70%;
                /*width: 80%;*/
                margin: auto;

                /*background-color: #ccc;*/
                .hot-brand-div-div {
                    width: 70px;
                    display: inline-block;
                    margin: 0 15px;
                    cursor: pointer;
                }

                .hot-brand-div-div:hover .hot-span {
                    color: #0D57BC;
                }

                .hot-span {
                    /*font-size: 12px;*/
                    margin-top: 8px;
                }

                img {
                    cursor: pointer;
                    width: 80px;
                    height: 80px;
                    margin-bottom: 5px;
                    /*height: 100%;*/
                    /*max-width: 143px;*/
                    /*margin: 0 10px;*/
                }
            }

        }

        .all-brand {

            .all-brand-content {
                margin-top: 10px;
                margin-bottom: 10px;

                .englishWord {
                    /*display: flex;*/
                    /*justify-content: space-between;*/
                    width: 80%;
                    margin: auto;

                    .En-word {
                        margin-right: 10px;
                        box-sizing: border-box;
                        cursor: pointer;
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                        text-align: center;
                        background-color: #fff;
                        font-weight: 700;
                        /*color: #0D57BC;*/
                        /*font-size: 16px;*/



                    }

                    /*.En-word:hover {*/
                    /*    border: 1px solid #0D57BC;*/
                    /*    color: #0D57BC;*/
                    /*}*/

                    .currentWord {
                        /*color: red;*/
                        border: 1px solid #0D57BC;
                        color: #0D57BC;
                    }

                }

            }


        }

        .models {}

        .englishWordBrand {
            //height: 144px;
            max-height: 72px;
            /*min-height: 120px;*/
            overflow: auto;
            margin-top: 20px;
            padding-top: 20px;
            /*padding-bottom: 20px;*/

            width: 85%;
            margin-left: auto !important;
            margin-right: auto !important;
            border: 1px solid #ccc;
            background-color: #fff;

            .en-word-col {
                text-align: center;
                margin-bottom: 20px;
                line-height: 20px;
                cursor: pointer;
                /*color: #0D57BC;*/
            }

            .en-word-col:hover {
                color: #0D57BC;
            }

            .en-word-col-click {
                color: #0D57BC;
                /*color:red;*/
            }

        }

        .main-serve-content-head {
            padding-left: 20px;
            line-height: 30px;
            font-weight: 700;

        }

    }

}
</style>
