<template>
    <div>
        <div class="info-header">
          <h1  class="info-header-h1">登录名：{{ userInfo.userName }}</h1>
          <button class="info-header-btn" @click="editBtn">编辑资料</button>
        </div>
        <div class="info-center">
            <img class="info-center-img" src="@/assets/img/20141229151653_HiM8B.jpeg" alt="" width="100" height="100">
            <div class="info-center-l">
              <div class="info-center-text">
                <span>类型：</span>{{userType=='1'?'个人会员':'企业会员'}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="javascript:;" style="color: #0099ff" @click="dialogVisible = true" v-if="!editShow && userType=='1'">升级企业会员</a>
              </div>
              <div class="info-center-text"><span>手机号：</span>{{ userInfo.phonenumber }}</div>
              <div class="info-center-text" v-if="editShow"><span style="flex: 0 0 60px;">邮箱：</span>
                <el-input v-model="form.email"></el-input>
              </div>
              <div class="info-center-text" v-else><span>邮箱：</span>{{ userInfo.email }}</div>
            </div>
        </div>

        <div class="inf-btn-box" v-if="editShow">
            <button class="btn blue" @click="submit">提交</button>
            <button class="btn" @click="editShow = false">返回</button>
        </div>

      <el-dialog
          :visible.sync="dialogVisible"
          width="700px"
      :close-on-click-modal="false" :show-close="false">
          <el-form :model="formQy" label-width="130px" :rules="rules" ref="formQy">
              <el-form-item label="企业名称：" placeholder="请输入企业名称" prop="corporationName">
                  <el-input v-model="formQy.corporationName"></el-input>
              </el-form-item>
            <el-form-item label="组织代码：" placeholder="请输入组织代码" prop="code">
              <el-input v-model="formQy.code"></el-input>
            </el-form-item>
            <el-form-item label="公司地址：" placeholder="请输入公司地址" prop="companyAddress">
              <el-input v-model="formQy.companyAddress"></el-input>
            </el-form-item>
            <el-form-item label="上传营业执照："  prop="file">
              <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="httpRequest"
                  :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitQy">确认提交</el-button>
          <el-button  @click="closeQy">取 消</el-button>
        </div>
      </el-dialog>

    </div>
</template>
<script>
    // 获取个人中心请求接口
    import {
      updatePersonalCenterMyProfile,conversion
    } from "@/api/personalCenter/index.js";
    import {getUserInfo,setUserInfo} from "@/utils/auth.js";
    export default {
        components: {
        },
        data() {
            return {
              dialogVisible:false,
              form:{
                email:"",
                userId:""
              },
              rules:{
                corporationName:[
                  { required: true, message: '请输入企业名称', trigger: 'blur' },
                ],
                code:[
                  { required: true, message: '请输入组织机构代码', trigger: 'blur' },
                  {min: 9, max: 17, message: '请输入正确的组织机构代码', trigger: 'blur' },
                ],
                companyAddress:[
                  { required: true, message: '请输入公司地址', trigger: 'blur' },
                ],
                file:[
                  { required: true, message: '请上传资质证书', trigger: 'blur' },
                ],
              },
              userInfo:{},
              editShow:false,
              formQy:{
                corporationName:"",//企业名称
                userId:"",//用户ID
                code:"",//组织机构代码
                companyAddress:"", //公司地址
                file:""
              },
              imageUrl: '',
              userType:"1"
            };
        },
        created() {
          this.userInfo = getUserInfo()
          this.userType = getUserInfo().userType
          this.form.email = getUserInfo().email
          this.formQy.userId = this.form.userId = getUserInfo().userId
        },
        methods: {
          editBtn(){
            this.form.email = getUserInfo().email
            this.editShow = !this.editShow
          },
          submit(){
            updatePersonalCenterMyProfile(this.form).then(res=>{
                if (res.code == 200){
                    this.$message.success('修改成功')
                    this.userInfo.email = this.form.email
                    setUserInfo(this.userInfo)
                  this.editShow = false
                }
            })
          },
          httpRequest (data) {
            let _this = this
            let rd = new FileReader() // 创建文件读取对象
            let file = data.file
            this.formQy.file = file
            rd.readAsDataURL(file) // 文件读取装换为base64类型
            rd.onloadend = function (e) {
              _this.imageUrl = this.result // this指向当前方法onloadend的作用域
            }
          },
          beforeAvatarUpload(file) {
            let fileData = ['image/jpeg','image/png']
            const isJPG =fileData.includes(file.type);
            if (!isJPG) {
              this.$message.error('请上传图片');
            }
            return isJPG;
          },
          submitQy(){
            this.$refs.formQy.validate((valid)=>{
                if (valid){
                  let formData = new FormData()
                  formData.append('corporationName',this.formQy.corporationName)
                  formData.append('userId',this.formQy.userId)
                  formData.append('code',this.formQy.code)
                  formData.append('companyAddress',this.formQy.companyAddress)
                  formData.append('file',this.formQy.file)
                  conversion(formData).then(res=>{
                    if (res.code == 200){
                      this.dialogVisible = false
                      this.$message.success('上传成功')
                    }
                  })
                }
            })

          },
          closeQy(){
            this.dialogVisible = false
            this.$refs.formQy.resetFields();

          }
        },
    };
</script>
<style lang="scss" scoped>
.info-header{
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  border-bottom: 1px solid #f2f2f2;
  padding-top: 30px;
  font-size: 17px;

  .info-header-h1{
    line-height: 28px;
    margin-left: 138px;
  }
  .info-header-btn{
    width: 110px;
    height: 45px;
    border: none;
    color: #fff;
    background: #0099ff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 17px;
  }
}
.info-center{
  display: flex;
  padding: 0 20px;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 15px;
  font-size: 17px;
  .info-center-img{
    border-radius: 50%;
  }
  .info-center-l{
    margin-left: 40px;
    .info-center-text{
      display: flex;
      margin-bottom: 30px;
    }
  }
}
.dialog-footer{
  text-align: center;
}
.inf-btn-box{
  text-align: center;
  margin-top: 50px;
  .btn{
    width: 150px;
    height: 40px;
    border: 1px solid #000;
    color: #000;
    font-size: 20px;
    border-radius: 5px;
    background: #fff;
    margin-right: 20px;
    cursor: pointer;
    &.blue{
      background: #0099ff;
      color: #fff;
      border: none;
    }
  }

}

.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
