<template>
    <div>
        <div class="box-contain box-containThree box-container">
            <div class="personal-sidebar">
                <h1  class="personal-sidebar-h1">个人中心</h1>
                <div class="personal-sidebar-list">
                  <router-link to="/personalCenter/info" class="personal-sidebar-link" :class="{'actClass':$route.meta.acName == '个人信息'}">帐号资料</router-link>
                  <router-link to="/personalCenter/otherList" class="personal-sidebar-link" :class="{'actClass':$route.meta.acName == '订单信息'}">我的订单</router-link>
                  <router-link to="/personalCenter/feedback" class="personal-sidebar-link" :class="{'actClass':$route.meta.acName == '用户反馈'}">用户反馈</router-link>
                </div>
            </div>
            <div class="personal-sidebar-container">
              <router-view></router-view>
            </div>
        </div>
        <Vfooter />
    </div>
</template>
<script>
    import Vfooter from "@/components/footer.vue";
    // 获取个人中心请求接口
    import {

    } from "@/api/personalCenter/index.js";
    //字典管理
    import {  } from "@/api/dict.js";
    export default {
        components: {
            Vfooter,
        },
        data() {
            return {

            };
        },
        computed: {

        },
        created() {
        },
        methods: {

        },
    };
</script>
<style lang="scss" scoped>
.box-container{
  display: flex;
}
.personal-sidebar{
  flex: 0 0 230px;
  border: 1px solid #ccc;
  min-height: calc(100vh - 304px);
  .personal-sidebar-h1{
    width: 100%;
    line-height: 70px;
    background: #d7d7d7;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }
  .personal-sidebar-list{
    padding: 0 30px;
    .personal-sidebar-link{
      display: block;
      line-height: 70px;
      color: #666;
      &.actClass{
        color: #0000ff;
      }
    }
  }
}
.personal-sidebar-container{
  margin-left: 25px;
  background: #fff;
  flex: 1;
}
</style>
