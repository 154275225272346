<template>
    <div>
        <div class="box-contain">
            <Breadcrumb></Breadcrumb>
            <div class="box-contain-div">
                <div>
                    <div style="margin-top: 15px;">
                        <el-input placeholder="搜索文章" v-model="form.noticeTitle" class="input-with-select searchContent" clearable
                                  @keyup.enter.native="getList" @clear="getList">
                            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
                        </el-input>
                        <div class="table-ul" v-loading="loading">
                            <div class="table-li" v-for="(item,index) in tableList" :key="index"
                                 v-if="tableList.length">
                                <div class="table-div" @click="goToDetail(item)">
                                    <div class="table-title">{{item.noticeTitle}}</div>
                                    <div class="table-time">{{item.createTime?item.createTime.substring(0,10):''}}</div>
                                </div>

                            </div>
                            <!--                            <div  v-if="!tableList.length" class="no-list">-->
                            <!--                            <div  class="no-list">-->
                            <!--                                <i class="iconfont icon-tupian">-->

                            <!--                                </i>-->
                            <!--                                <span class="no-list-word">-->
                            <!--                                    没有找到此公告-->
                            <!--                                </span>-->

                            <!--                            </div>-->
                            <div v-if="!tableList.length" >
                                <div class="no-message">
                                    没有找到匹配记录
                                </div>
                            </div>
                        </div>
                        <pagination
                                v-show="total>0"
                                :total="total"
                                :page.sync="form.pageNum"
                                :limit.sync="form.pageSize"
                                @pagination="getList"
                        />
                    </div>
                </div>

            </div>

        </div>
        <Vfooter/>
    </div>
</template>
<script>
    import Vfooter from "@/components/footer.vue";
    import Breadcrumb from "@/components/breadcrumb.vue";
    import {newAnnounce} from "@/api/Index.js";

    export default {

        data() {
            return {
                loading: false,
                total: 0,
                form: {
                    noticeTitle: '',
                    pageNum: 1,
                    pageSize: 10,
                },
                tableList: []

            };
        },
        components: {
            Vfooter,
            Breadcrumb,
        },
        created() {

            this.getList()
        },
        mounted() {
        },
        methods: {

            getList() {
                this.loading = true
                newAnnounce(this.form).then(res => {
                    this.loading = false
                    if (res.code == 200) {
                        this.tableList = res.rows
                        this.total = res.total

                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(e => {
                    this.loading = false
                })


            },
            goToDetail(item) {
                this.$router.push({path: '/messageDetail', query: {type: '3', id: item.noticeId}})
            }
        },
    };
</script>
<style lang="scss" scoped>
    .table-ul {
        position: relative;
        margin-top: 20px;
        margin-bottom: 0px;
        color: #333;
        min-height: calc(100vh - 580px);

        .table-li {
            border-bottom: 1px solid #ccc;

            .table-div {
                padding: 15px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;

                .table-title {

                    max-width: 70%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }

                .table-time {
                    color: #bbb
                }

            }

            .table-div:hover {
                color: #0D57BC;

                .table-time {
                    color: #0D57BC
                }

            }


        }

        .no-list {
            width: 400px;
            text-align: center;
            color: #ddd;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            i {
                font-size: 80px;
            }

            .no-list-word {
                font-size: 16px;
                color: #ccc;
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .no-message {
            width: 100%;
            text-align: center;
            height: 60px;
            line-height: 60px;
            background-color: #F2F2F2;


        }

    }
    ::v-deep .searchContent .el-input__inner, {
       border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
    }
    .el-input.is-active .el-input__inner, .el-input__inner:focus {
        border-color: #ccc;
    }

    ::v-deep .el-input-group__append, ::v-deep .el-input-group__prepend {
       border: none;
        background-color: #fff;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
    }

</style>
