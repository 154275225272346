<template>
  <div>
    <div class="box-contain box-containThree">

      <div class="buy-div box-contain-divThree">
        <h1 class="order-title">填写并核对订单信息</h1>
        <div class="order-info">
          <div class="order-info-box">
            <div class="oder-info-title">
              <span>商品信息</span>
            </div>
            <ul class="order-info-list">
              <li v-for="item in buyInfo.productInfoList">
                <img :src="item.brandLogoUrl" alt="" width="180" height="120">
                <div class="oder-info-l">
                  <h2 class="oder-info-list-title">{{ item.brandName }}</h2>
                  <div flex="c" class="oder-info-l-b">
                    <div style="width: 145px">
                      <span>下载：</span>
                      <span style="color: #666">{{ item.downNumber }}次</span>
                    </div>
                    <div style="width: 145px">
                      <span>打印：</span>
                      <span style="color: #666">{{ item.printNumber }}次</span>
                    </div>
                  </div>
                </div>
                <div class="oder-info-c">
                  <span style="color: #666">价格</span>
                  <p>￥ {{ item.money }}</p>
                </div>
                <div class="oder-info-r">
                  <span style="color: #666;">价格</span>
                  <p style="font-size: 14px">{{ item.mealName }}</p>
                </div>
              </li>
            </ul>
            <div class="oder-info-title">
              <span>结算信息</span>
            </div>
            <div class="oder-info-text">
              <div class="oder-info-text-box">
                <div style="margin-bottom: 15px">
                  <span>付款类型：</span>
                  <span style="color: #ff0000">全款</span>
                </div>
                <div>
                  <span>应付金额：</span>
                  <span style="color: #ff0000">￥ {{ buyInfo.moneySum }}</span>
                </div>
              </div>
              <div class="buy-btn-info">
                <button class="buy-btn" @click="toHref">提交订单</button>
                <p>注意：提交订单后请尽快支付。</p>
              </div>
            </div>
          </div>
        </div>
        <div class="buy-line"></div>
        <div class="invoice-inner">
          <div class="invoice-inner-l">是否开具发票：</div>
          <div class="invoice-inner-r">
            <label class="invoice-inner-radio">
              <input name="isInvoice" type="radio" value="1" v-model="orderData.invoiceStatus">
              是
            </label>
            <label class="invoice-inner-radio">
              <input name="isInvoice" type="radio" value="0" v-model="orderData.invoiceStatus">
              否
            </label>
          </div>
        </div>
        <div class="invoice-inner" v-show="orderData.invoiceStatus==1">
          <div class="invoice-inner-l">发票抬头：</div>
          <div class="invoice-inner-r">
            <input class="invoice-inner-input" v-model="orderData.invoiceHeader"/>
          </div>
        </div>


        <div class="address-info" v-show="orderData.invoiceStatus==1">
          <div flex="sb" class="address-top">
            <h1>收货人信息：</h1>
            <a href="javascript:;" @click="adddz">新增收货地址</a>
          </div>
          <ul class="address-list" :class="{'isOpen':isOpen}">

            <li v-for="item in addressList" :key="item.id">
              <input type="radio" name="shdz" v-model="defaultAddress" :value="item.id" class="radioClass"/>
              <div class="address-list-c">
                <div>
                  <span>{{ item.harvestName }}</span>
                  <span>{{ item.harvestPhone }}</span>
                </div>
                <p>{{ item.province }}{{ item.city }}{{ item.county }}{{ item.detailedAddress }}</p>
              </div>
              <div class="address-list-r">
                <a href="javascript:;" class="btn" v-if="item.defaultStatus == '0'" @click="setDefaultAddress(item)">设为默认地址</a>
                <a href="javascript:;" class="btn" @click="editAddress(item)">编辑</a>
                <a href="javascript:;" class="btn" @click="deleteAddress(item.id)">删除</a>
              </div>
            </li>

          </ul>

          <el-button type="text" @click="isOpen=!isOpen">
            {{isOpen?'展开':'收起'}}
             <i :class="isOpen?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
          </el-button>

        </div>
      </div>


      <div class="alert-info" v-if="alertFlag">
        <div class="alert-info-box">
          <el-form :model="formData" label-width="100px" :rules="formRules" ref="formData">
            <el-form-item label="收货人：" prop="harvestName">
              <el-input placeholder="姓名，如张三" v-model="formData.harvestName"></el-input>
            </el-form-item>
            <el-form-item label="手机：" prop="harvestPhone">
              <el-input placeholder="请填写正确的11位手机号码" v-model="formData.harvestPhone"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="8">
                <el-form-item label="所在地区：" prop="province">
                  <el-select placeholder="请选择省" v-model="formData.province" @change="provinceChange">
                    <el-option v-for="item in provinceOptions" :label="item.name" :key="item.name"
                               :value="item.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6.5">
                <el-form-item style="margin-left: -100px" prop="city">
                  <el-select placeholder="请选择市" v-model="formData.city" @change="cityChange">
                    <el-option v-for="item in cityOptions" :label="item.name" :key="item.name"
                               :value="item.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item style="margin-left: -100px" prop="county">
                  <el-select placeholder="镇/乡" v-model="formData.county">
                    <el-option v-for="item in countyOptions" :label="item" :key="item" :value="item"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="详细地址：" prop="detailedAddress">
              <el-input placeholder="详细地址" v-model="formData.detailedAddress"></el-input>
            </el-form-item>
            <el-form-item label="邮编：" prop="postalCode">
              <el-input placeholder="请填写邮编" v-model="formData.postalCode"></el-input>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input type="textarea" placeholder="有什么疑问可以在这里填写" v-model="formData.remark"></el-input>
            </el-form-item>
          </el-form>
          <div class="operation">
            <button class="btn blue" @click="switchBtn?addressSubmit():editSubmit()">提交</button>
            <button class="btn" style="margin-left: 80px" @click="closeAddress">取消</button>
          </div>
        </div>
      </div>


    </div>
    <Vfooter/>
  </div>
</template>
<script>
import Vfooter from "@/components/footer.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
// 获取信息购买请求接口
import {address, getAddressListByUserId, deleteAddress,updateAddress,setDefaultAddress,addOrder} from "@/api/technologyBuy/index.js";
import mapData from "@/assets/jsondata/map.json";
import {getUserInfo} from "@/utils/auth.js";
//字典管理
import {} from "@/api/dict.js";

export default {
  components: {
    Vfooter,
    Breadcrumb
  },
  data() {
    return {
      formData: {
        harvestName: "",
        harvestPhone: "",
        //市
        city: "",
        //城
        province: "",
        //县
        county: "",
        //详细地址
        detailedAddress: "",
        //邮编
        postalCode: "",
        //备注
        remark: "",
        userId: ""
      },
      provinceOptions: {},
      cityOptions: {},
      countyOptions: {},
      buyInfo: {},
      formRules: {
        harvestName: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        harvestPhone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
        province: [
          {required: true, message: '请选择所在地', trigger: 'change'},
        ],
        city: [
          {required: true, message: '请选择所在地', trigger: 'change'},
        ],
        county: [
          {required: true, message: '请选择所在地', trigger: 'change'},
        ],
        detailedAddress: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
        postalCode: [
          {required: true, message: '请输入邮编', trigger: 'blur'},
        ]
      },
      alertFlag: false,
      switchBtn:false,
      addressList: [],
      orderData:{
        detailedAddress: "",
        harvestName: "",
        harvestPhone: "",
        invoiceHeader: "",
        invoiceStatus: "0",
        money: "",
        orderBrandMealDtoList: [],
        orderType: "1",
        userId:""
      },
      defaultAddress:"",
      isOpen:true
    };
  },
  computed: {},
  created() {
    this.buyInfo = JSON.parse(sessionStorage.getItem('buyInfo'))
    this.provinceOptions = mapData
    this.formData.userId = getUserInfo().userId
    this.orderData.userId = getUserInfo().userId
    this.getAddressListByUserId()
  },
  methods: {
    toHref() {
      if (this.isInvoice == 1){
        if(this.orderData.invoiceHeader =='' || !this.orderData.invoiceHeader){
          return this.$message.error('发票抬头不能为空')
        }
      }

      this.addressList.forEach(el=>{
          if (el.id == this.defaultAddress){
            this.orderData.detailedAddress = el.detailedAddress
            this.orderData.harvestName = el.harvestName
            this.orderData.harvestPhone = el.harvestPhone
          }
      })
      this.orderData.money = this.buyInfo.moneySum
      this.orderData.orderBrandMealDtoList = this.buyInfo.productInfoList

      addOrder(this.orderData).then(res=>{
        if (res.code==200){
          sessionStorage.setItem('payInfo',JSON.stringify(res.data))
          this.$router.push({path:"/technologyBuy/confirmPayment"})
        }
      })
    },
    provinceChange(e) {
      let provinceOptions = this.provinceOptions
      this.formData.city = ''
      this.formData.county = ''
      for (let key in provinceOptions) {
        if (provinceOptions[key].name == e) {
          this.cityOptions = provinceOptions[key].child
        }
      }
    },
    cityChange(e) {
      let cityOptions = this.cityOptions
      this.formData.county = ''
      for (let key in cityOptions) {
        if (cityOptions[key].name == e) {
          this.countyOptions = cityOptions[key].child
        }
      }
    },
    closeAddress() {
      this.alertFlag = false
      this.$refs.formData.resetFields();
    },
    addressSubmit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          address(this.formData).then(res => {
            if (res.code == 200) {
              this.$message.success('保存成功')
              this.alertFlag = false
              this.$refs.formData.resetFields();
              this.getAddressListByUserId()
            }
          })
        }
      })
    },
    getAddressListByUserId() {
      getAddressListByUserId({userId: getUserInfo().userId}).then(res => {
        if (res.code == 200) {
          this.addressList = res.data
          this.defaultAddress = res.data[0].id
        }
      })
    },
    deleteAddress(ids) {
      this.$confirm('是否删除当前地址', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        deleteAddress(ids).then(res => {
            if (res.code == 200){
                this.$message.success('删除成功')
                 this.getAddressListByUserId()
            }
        })
      })
    },
    adddz(){
      this.alertFlag = true
      this.switchBtn = true
    },
    editAddress(item){
      this.formData = item
      this.alertFlag = true
      this.switchBtn = false
      let provinceOptions = this.provinceOptions
      for (let key in provinceOptions) {
        if (provinceOptions[key].name == item.province) {
          this.cityOptions = provinceOptions[key].child
        }
      }
      let cityOptions = this.cityOptions
      for (let key in cityOptions) {
        if (cityOptions[key].name == item.city) {
          this.countyOptions = cityOptions[key].child
        }
      }
    },
    editSubmit(){
      updateAddress(this.formData).then(res=>{
          if (res.code == 200){
            this.$message.success('修改成功')
            this.getAddressListByUserId()
            this.alertFlag = false
          }
      })
    },
    setDefaultAddress(item){
      let data = {
        defaultStatus:1,
        userId:getUserInfo().userId,
        id:item.id
      }

      this.$confirm('是否设置默认地址', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        setDefaultAddress(data).then(res=>{
          if (res.code == 200){
            this.$message.success('修改成功')
            this.getAddressListByUserId()
          }
        })
      })


    }
  },
};
</script>
<style lang="scss" scoped>
.buy-div {
  border: 1px solid #c3c3c3;
  padding: 20px;
  background: #fff;
}

.order-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 28px;
}

.order-info-box {
  width: 980px;
  margin-left: 25px;

}

.oder-info-title {
  border-bottom: 1px solid #333;

  span {
    display: inline-block;
    padding: 8px 5px;
    background: #333;
    color: #fff;
    font-size: 18px;
  }
}

.order-info-list {
  margin-top: 20px;
}

.order-info-list li {
  display: flex;
  padding: 20px 10px;
  margin-bottom: 20px;
  background: #e4e4e4;
}

.oder-info-l {
  width: 460px;
  margin-left: 10px;

  .oder-info-list-title {
    color: #169bd5;
    font-size: 18px;
    margin-top: 39px;
  }

  .oder-info-l-b {
    font-size: 12px;
    margin-top: 32px;
  }
}

.oder-info-c {
  margin-left: 10px;
  width: 230px;

  p {
    color: #ff0000;
  }
}

.oder-info-r {
  margin-left: 10px;
  width: 213px;
}

.oder-info-c p,
.oder-info-r p {
  margin-top: 20px;
  font-size: 20px;
}

.oder-info-text {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-left: 100px;

  .oder-info-text-box {
    font-size: 15px;
  }

  .buy-btn-info {
    margin-top: 52px;
    text-align: center;

    p {
      color: red;
    }
  }

  .buy-btn {
    width: 120px;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
    background: #169bd5;
    cursor: pointer;
  }
}

.buy-line {
  width: 100%;
  height: 1px;
  background: #d2d2d2;
  margin: 20px 0;
}

.invoice-inner {
  display: flex;
  font-size: 15px;
  margin-bottom: 20px;

  .invoice-inner-l {
    width: 121px;
    text-align: right;
  }

  .invoice-inner-r {
    flex: 1;
  }

  .invoice-inner-radio {
    margin-left: 20px;
  }

  .invoice-inner-input {
    width: 100%;
  }
}

.address-top {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid  #d2d2d2;
  a {
    font-size: 15px;
    color: #169BD5;
  }
}

.address-info {
  margin-left: 30px;

  h1 {
    font-size: 15px;
  }

  .address-list {
    background: #fff;
    font-size: 15px;
    padding: 15px 15px;
    &.isOpen{
      overflow: hidden;
      height: 72px;
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .address-list-c {
      flex: 1;
      padding-left: 15px;
    }

    .address-list-r {
      .btn {
        display: inline-block;
        color: #333;
        margin-right: 10px;
      }
    }
  }
}

.alert-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 10;
  display: flex;

  .alert-info-box {
    margin: auto;
    width: 900px;
    border: 1px solid #797979;
    padding: 25px 15px;
    background: #fff;
    border-radius: 10px;
  }
}

.operation {
  text-align: center;

  .btn {
    width: 120px;
    height: 40px;
    cursor: pointer;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid #000;
    background: #fff;
    &.blue {
      border: none;
      background: #169BD5;
      color: #fff;
    }
  }
}
.radioClass{
  width: 20px;
  height: 20px;
}
</style>
