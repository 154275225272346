<template>
  <div
    class="banner-box"
    ref="bannerBox"
    @mouseenter="clearAutoPlay()"
    @mouseleave="autoPlay()"
  >
    <ul
      class="banner-silde"
      :style="'transform: translateX(' + translateX + 'px);'"
    >
      <li v-for="(item,index) in banner" :key="item.title" @click.stop.prevent="clickShowd(item)">
<!--        <a   @click.stop.prevent="gotoUrl(item)">-->
          <img :src="item.pictureUrl" :alt="item.title" style="max-height:352px"/>
          <h1  @click.stop.prevent="clickShowd(item)" style="cursor: pointer;">{{ item.title }}</h1>
          <div class="showad">

          </div>
      </li>
    </ul>

    <div class="banner-btn">
      <span
        v-for="(item, index) in banner"
        :key="index"
        :class="{ ac: index == indexPaly }"
        @click.stop.prevent="bannerBtn(index)"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    banner: {
      type:Array,
      default:[]
    },
    autoTime: {
      type: Number,
      default: 2000,
    },
  },
  data() {
    return {
      indexPaly: 0,
      translateX: 0,
      pWidth: 0,
      playTime: null,
    };
  },
  watch:{
    banner(){
        this.autoPlay()
    }
  },
  mounted() {
    this.autoPlay();
  },
  methods: {
    clickShowd(item){
        if (item.url) {
            window.open(item.url,'_blank')
        }
      // window.open(item.url,'_blank')
    },
    bannerBtn(index) {
      this.indexPaly = index;
      this.translateX = -this.pWidth * index;
    },
    gotoUrl(item){
      if(item.type==2){
        this.$router.push(item.urlLink)
      }else {
        window.open(item.urlLink,'_blank')
      }

    },
    autoPlay() {
      this.pWidth = this.$refs.bannerBox.clientWidth;
      if (this.banner && this.banner.length > 0) {
        let index = 0;
        this.playTime = setInterval(() => {
          index++;
          if (index >= this.banner.length) {
            index = 0;
          }
          this.bannerBtn(index);
        }, this.autoTime);
      }
    },
    clearAutoPlay() {
      clearInterval(this.playTime);
    },
  },
  destroyed() {
    this.clearAutoPlay();
  },
};
</script>
<style lang="scss" scoped>
$heightH: 100%;
$widthW: 100%;
.banner-box {
  margin: auto;
  position: relative;
  //width: 1000px;
  //height: 526.5px;
  /*width: 90%;*/
  /*height: $heightH;*/
  background: #000;
  border-radius: 4px;
  overflow: hidden;
  .banner-silde {
    list-style: none;
    width: $widthW;
    height: $heightH;
    margin: 0;
    padding: 0;
    display: flex;
    transition: all 0.5s;
    li {
      position: relative;
      width: $widthW;
      height: $heightH;
      flex: 0 0 100%;
      img {
        width: $widthW;
        height: $heightH;
      }
      h1 {
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-size: 20px;
        color: #fff;
        /*color: #0D57BC;*/
        z-index: 800;
        margin-bottom: 0;
      }
    }
  }
  .showad {
    background-color: rgba(0,0,0,.32);
    width: 100%;
    height: 60px;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
  .banner-btn {
    position: absolute;
    left: 20px;
    bottom: 10px;
    display: flex;
    span {
      display: block;
      width: 31px;
      height: 6px;
      margin-right: 4px;
      background: #fff;
      /*background: #0D57BC;*/
      opacity: 0.4;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.ac {
         opacity: 0.8;
      }
    }
  }
}
</style>
