<template>
    <div class="login-mask" v-if="flag">
        <div class="login-box">
            <div class="close-btn" @click="close('form')">
                <i class="el-icon-close"></i>
            </div>
           <div v-if="currentTab==1">
               <img class="logo-login" src="@/assets/img/logoComplete.png" alt=""/>
               <el-form ref="form" :model="form" :rules="rules" class="login-form">
                   <template>
                       <el-form-item prop="username">
                           <el-input
                                   v-model="form.username"
                                   prefix-icon="el-icon-user"
                                   placeholder="请输入登录名"
                           ></el-input>
                       </el-form-item>
                       <el-form-item prop="password">
                           <el-input
                                   v-model="form.password"
                                   prefix-icon="el-icon-lock"
                                   placeholder="请输入密码"
                                   type="password"
                                   autocomplete='on'
                                   show-password
                           ></el-input>
                       </el-form-item>
                       <el-form-item prop="code">
                           <el-input v-model="form.code" auto-complete="off" placeholder="验证码" @keyup.enter.native="handleLogin('form')"
                                     style="width: 67%;vertical-align: middle" >
                               <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon"/>
                           </el-input>
                           <div class="login-code">
                               <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                           </div>
                       </el-form-item>
                   </template>
                   <el-form-item class="text-center">
                       <el-button
                               type="primary"
                               @click="handleLogin('form')"
                               style="width: 100%"
                               :loading="loading"
                       >登录
                       </el-button
                       >
                   </el-form-item>
                   <div class="dl-regis-findPass">
                       <span><span>还没有账号，</span><span class="registered" @click="handelRegister('2')">立即注册</span></span>
                       <span  class="findPassword" @click="handelRegister('3')">找回密码</span>
                   </div>
               </el-form>
           </div>
            <div v-if="currentTab==2">
               <Registered @handelRegister="handelRegister"></Registered>
            </div>
            <div v-if="currentTab==3">
                <FindPassword @handelRegister="handelRegister"></FindPassword>
            </div>
        </div>
    </div>
</template>
<script>
    import Cookies from 'js-cookie'
    import store from '@/store/index'
    // import {encrypt, decrypt} from '@/utils/jsencrypt'
    import {login, getCodeImg} from "@/api/login.js";
    import Registered from '@/components/Registered/index.vue'
    import FindPassword from '@/components/FindPassword/index.vue'
    import {
        setUserInfo,
        setUserState,
    } from "@/utils/auth.js";

    export default {
        name: "Vlogin",
        data() {
            // username: "jyc",
            //   password: "123456",
            return {
                //1:登录，2注册，3：找回密码
                currentTab:'1',
                form: {
                    username: "",
                    password: "",
                    phone: "",
                    code: "",
                    uuid: '',
                    remember: false,
                },
                codeUrl: '',
                rules: {
                    username: [{
                        required: true,
                        message: '登录名不能为空',
                        trigger: 'blur'
                    }],
                    password: [{
                        required: true,
                        message: '密码不能为空',
                        trigger: 'blur'
                    }],
                    code: [{
                        required: true,
                        message: '验证码不能为空',
                        trigger: 'blur'
                    }],

                },
                flag: false,
                loading: false,
            };
        },
        components:{
            Registered,
            FindPassword,
        },
        created() {
        //     // let userInfo = getUserInfo();
        //     // this.form.remember = userInfo?userInfo.remember:false;
        //     // this.form.remember = getUserState();
        //     // if (getUserState()) {
        //     //     this.form.username = getSecrecy().username;
        //     //     this.form.password = getSecrecy().password;
        //     // }
            if(store.state.common.loginType){
                this.currentTab=store.state.common.loginType
            }else {
                this.currentTab='1'
            }

            this.getCode()
        },
        mounted() {

        },
        methods: {
            getCode() {
                getCodeImg().then((res) => {
                    this.codeUrl = 'data:image/gif;base64,' + res.img
                    this.form.uuid = res.uuid
                })
            },
            // getCookie() {
            //     console.log(Cookies.get('username'),'Cookies.get(\'username\')')
            //     const username = Cookies.get('username')
            //     const password = Cookies.get('password')
            //     // const rememberMe = Cookies.get('rememberMe')
            //     this.form = {
            //         username: username === undefined ? this.form.username : username,
            //         password: password === undefined ? this.form.password : decrypt(password),
            //         // rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
            //     }
            // },
            handleLogin() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.loading = true
                        // if (this.form.rememberMe) {
                        //     Cookies.set('username', this.form.username, {expires: 30})
                        //     Cookies.set('password', encrypt(this.form.password), {
                        //         expires: 30,
                        //     })
                        //     Cookies.set('rememberMe', this.form.rememberMe, {
                        //         expires: 30,
                        //     })
                        // } else {
                        //     Cookies.remove('username')
                        //     Cookies.remove('password')
                        //     Cookies.remove('rememberMe')
                        // }
                        let form = Object.assign({},this.form)
                        form.password = this.$MD5( form.password)
                        store.dispatch('user/Login', form)
                            .then(() => {
                                this.loading = false
                                this.$message({
                                    showClose: true,
                                    message: "登录成功",
                                    type: "success",
                                });
                                store.dispatch('user/GetInfo').then(res => {
                                        setUserInfo(res.user);
                                        setUserState(true)
                                        window.location.reload()
                                    }
                                )
                                this.flag = false;

                            })
                            .catch(() => {
                                this.loading = false
                                this.getCode()
                            })
                    }
                })
            },
            handelRegister(val){
                 this.currentTab=val
            },
            // findPassword(){
            //     this.currentTab='3'
            // },
            close(formName) {
                this.flag = false;
                // this.$refs[formName].resetFields();
                this.$emit("close");
            },
            show() {
                console.log(this.$store.state["common/loginType"],1213)
                this.flag = true;
            },
            // loginSwitch() {
            //     this.$refs.form.resetFields();
            // },
        },
    };
</script>
<style lang="scss" scoped>
    .login-mask {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.5);
        z-index: 999;
    }

    .login-box {
        position: relative;
        width: 738px;
        background: #ffffff;
        padding-bottom: 40px;
        border-radius: 8px;
        margin: auto;

        .close-btn {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 20px;
            cursor: pointer;
        }

        .logo-login {
            display: block;
            /*width: 213px;*/
            height: 71px;
            margin: 60px auto 40px;
        }

        .login-form {
            width: 360px;
            margin: 0 auto;

            /*.yzm-btn {*/
            /*    position: absolute;*/
            /*    right: 9px;*/
            /*}*/
        }

        .login-code {
            vertical-align: middle;
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            width: 28%;

            img {
                width: 100%;
            }

        }
    }
    .dl-regis-findPass {
        margin-top: -15px;
        color: #333;
        .registered {
            /*float: left;*/
            color: #169BD5;
            cursor: pointer;
        }
        .findPassword {
            float: right;
            color: #169BD5;
            cursor: pointer;
        }
    }
</style>
