<template>
    <div>
        <div class="box-contain">
            <Breadcrumb></Breadcrumb>
            <div class="box-contain-div">
                <div class="box-contain-div-tit">汽车后市场业务介绍</div>
                <div class="aboutUs-p" >
                    <div class="aboutUs-title" >1.概述</div>
                    <div class="aboutUs-content" >
                        中汽数据有限公司·后市场数据室立足于汽车后市场业务，以数据建设为立身之本，打造一个权威、精准、全面的后市场大数据平台，为保险公司、零部件生产企业、
                        零部件经销商等后市场相关企业机构提供综合解决方案。
                    </div>
                </div>
                <div class="aboutUs-p" >
                    <div class="aboutUs-title" >2.主要内容</div>
                    <div class="aboutUs-content" >
                        后市场数据室以多种方式进行汽车后市场相关数据资源整合、挖掘与应用，建立起基于VIN的汽车全产品属性的全景数据资源体系，
                        包含整车数据库、零部件数据库、零部件价格数据库、维修技术信息数据库等。
                    </div>
                </div>
                <div class="aboutUs-p" >
                    <div class="aboutUs-title" >3.能力</div>
                    <div class="aboutUs-content" >
                        （1）汽车后市场整车及零部件数据标准研究制定
                    </div>
                    <div class="aboutUs-content" >
                        （2）汽车后市场整车及零部件数据整合与开发
                    </div>
                    <div class="aboutUs-content" >
                        （3）汽车后市场大数据AI智能算法研究
                    </div>
                    <div class="aboutUs-content" >
                        （4）汽车后市场大数据平台建设
                    </div>
                </div>
                <div class="aboutUs-p" >
                    <div class="aboutUs-title" >4.优势</div>
                    <div class="aboutUs-content" >
                        全面：创建AI-CODE和AI-PARTS数据编码规则，建立适合国内汽车后市场的整车数据体系，通过零部件数据整合开发方法及AI智能大数据算法，
                        形成目前国内后市场领域覆盖度较高的VIN定型及零部件适配数据库。
                    </div>
                    <div class="aboutUs-content" >
                        精准： 构建“一车、一码、一款、一配、一件、一价“数据体系，打通车辆VIN码-款型-配件-价格数据，实现整车到零配件信息的精准查询。
                    </div>
                </div>
                <div class="aboutUs-p" >
                    <div class="aboutUs-title" >5.成果</div>
                    <div class="aboutUs">
                        <img src="../../assets/img/jieshao1.png"/>
                    </div>
                    <div class="aboutUs-content" >
                        联合中国银保信打造的服务于车险理赔业务的行业基础设施-“事故车定损云平台”于2017年正式发布。
                        历经多次版本迭代升级，目前已经与太平财险、阳光财险、大地财险等20余家保险公司达成合作。
                        “事故车定损云平台”的推出给保险行业在降低理赔定损成本、提升定损效率、改善消费者理赔服务体验、促进金融科技等方面带来积极影响。
                    </div>

                    <div class="aboutUs">
                        <img src="../../assets/img/jieshao2.png"/>
                    </div>
                    <div class="aboutUs-content" >
                        针对后市场运营环节下的不同业务场景存在的数据源间壁垒、标准不统一、信息不对称等市场痛点，
                        构建完善的“整-零”数据体系和精准的数据查询逻辑，服务各类零配件供应链企业、金融保险机构、二手车商等，解决运营层面业务推进难题，持续助力后市场行业健康有序发展。
                    </div>
                    <div class="aboutUs-content" >
                        如需了解详情，请查看<a @click.stop.prevent="$router.push('/aboutUs/index')">关于我们</a>。
                    </div>


                </div>

            </div>

        </div>
        <Vfooter/>
    </div>
</template>
<script>
    import Vfooter from "@/components/footer.vue";
    import Breadcrumb from "@/components/breadcrumb.vue";

    export default {

        data() {
            return {

            };
        },
        components: {
            Vfooter,
            Breadcrumb,
        },
        mounted() {
        },
        methods: {},
    };
</script>
<style lang="scss" scoped>
    .aboutUs {
        margin-top: 20px;
        width: 100%;
        text-align: center;
        padding-left: 50px;
        margin-bottom: 40px;

        img {
            width: 80%;
        }
    }
    .aboutUs-p {
        margin-bottom: 10px;

    }
    .aboutUs-title{
        line-height: 30px;
        font-weight: 600;

    }
    .aboutUs-content {
        line-height: 25px;
        text-indent: 2em;
    }
</style>
