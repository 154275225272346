<template>
  <div>
    <div class="other-list">
      <h1 class="other-list-title">反馈内容</h1>
    </div>
    <div class="feedback-item">
        <span class="feedback-item-text">反馈内容：</span>
        <p class="feedback-item-content" v-html="detailsData.feedbackContent"></p>
    </div>
    <div class="feedback-item">
      <span class="feedback-item-text">官方回复：</span>
      <p class="feedback-item-content" v-html="detailsData.replyContent"></p>
    </div>

    <div class="feedback-close-box">
        <button class="feedback-close" @click="closeBtn">关闭</button>
    </div>


  </div>
</template>
<script>
// 获取个人中心请求接口
import { feedbackDetails } from "@/api/personalCenter/index.js";

import {getUserInfo} from "@/utils/auth.js";

export default {
  components: {},
  data() {
    return {
      userInfo:{},
      detailsData:{
        feedbackContent:"暂无信息",
        replyContent:"暂无信息"
      }
    };
  },
  computed: {},
  created() {
    this.userInfo = getUserInfo()
    this.init()
  },
  methods: {
    init(){
      let id = this.$route.query.id
      feedbackDetails(id).then(res=>{
        if (res.code == 200){
          this.detailsData =res.data
        }
      })
    },
    show(item){
      this.$router.push({path:"/personalCenter/feedbackDetails"})
    },
    closeBtn(){
      this.$router.push({path:"/personalCenter/feedback"})
    }

  },
};
</script>
<style lang="scss" scoped>
.other-list {
  .other-list-title {
    margin: 0 20px 25px;
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #555;
    font-weight: bold;
    font-size: 20px;
  }
}
.feedback-item{
  display: flex;
  margin: 0 30px 25px;
  .feedback-item-text{
    font-size: 20px;
    flex: 0 0 113px;
  }
  .feedback-item-content{
    flex: 1;
    border: 1px solid #797979;
    padding: 10px;
    word-break:break-all;
  }
}
.feedback-close-box{
  text-align: center;
  margin-top: 50px;
}
.feedback-close{
  width: 190px;
  height: 54px;
  background: #fff;
  font-size: 20px;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
}
</style>
