<template>
    <div>
        <div class="box-contain">
            <Breadcrumb></Breadcrumb>
            <div class="box-contain-div">
                <div class="box-contain-div-tit">版权说明</div>
                <p class="copyRight" style="text-align: center">
                    机动车维修技术信息网版权所有未经授权禁止复制
                </p>
                <p class="copyRight" style="text-align: center">
                    或建立镜像，否则将追究法律责任
                </p>
            </div>

        </div>
        <Vfooter />
    </div>
</template>
<script>
import Vfooter from "@/components/footer.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
export default {

    data() {
        return {
            content: {},
        };
    },
    components: {
        Vfooter,
        Breadcrumb,
    },
    mounted() {
    },
    methods: {

    },
};
</script>
<style lang="scss" scoped>
  .copyRight {
      line-height: 25px;
      text-indent: 2em;
  }
</style>
