import cookie from '@/plugins/cookie.js'
const TokenKey = 'information-network-client'
import CryptoJS from '@/utils/crypto-js.js'
let key = 'TJzhongqiyan1006';
key = CryptoJS.enc.Utf8.parse(key);

//获取getToken
export function getToken() {
    return localStorage.getItem(TokenKey)
}
//设置getToken
export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}
//删除getToken
export function removeToken() {
    return localStorage.removeItem(TokenKey)
}
//获取用户信息
export function getUserInfo() {
    let userInfo = cookie.get('userInfo')
    if (userInfo){
        const decrypt = CryptoJS.AES.decrypt(userInfo, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedStr)
    }else {
        return JSON.parse(userInfo)
    }


}
//设置用户信息
// export function setUserInfo(data) {
//     return cookie.set('userInfo', JSON.stringify(data),{ expires: "7D" })
// }
export function setUserInfo(data) {
    let userInfoDate =  JSON.stringify(data)
    let content = CryptoJS.enc.Utf8.parse(userInfoDate);
    let encryptResult = CryptoJS.AES.encrypt(content, key, {
        mode: CryptoJS.mode.ECB, //aes加密模式
        padding: CryptoJS.pad.Pkcs7, //填充
    });
    let formData = CryptoJS.enc.Base64.stringify(encryptResult.ciphertext);


    return cookie.set('userInfo', formData)
}
//临时保存用户信息
export function restUserInfo(data) {
    let userInfoDate =  JSON.stringify(data)
    let content = CryptoJS.enc.Utf8.parse(userInfoDate);
    let encryptResult = CryptoJS.AES.encrypt(content, key, {
        mode: CryptoJS.mode.ECB, //aes加密模式
        padding: CryptoJS.pad.Pkcs7, //填充
    });
    let formData = CryptoJS.enc.Base64.stringify(encryptResult.ciphertext);
    return cookie.set('userInfo', formData)
}
//删除用户信息
export function removetUserInfo() {
    return cookie.delete('userInfo')
}
//获取用户登陆状态
export function getUserState(){
    return Boolean(cookie.get('userState'))
}
//设置用户登陆状态
export function setUserState(state){
    return cookie.set('userState',Boolean(state),{expires: "7D"})
}
//临时用户登陆状态
export function  restUserState(state){
    return cookie.set('userState',state)
}
//删除用户登陆状态
export function  removeUserState(state){
    return cookie.delete('userState',state)
}
//保存账号密码
export function setSecrecy(data){
    return cookie.set('secrecy', JSON.stringify(data),{expires: "7D"})
}
//获取
export function getSecrecy(){
    return JSON.parse(cookie.get('secrecy'))
}
//临时保存
export function restSecrecy(data){
    return cookie.set('secrecy', JSON.stringify(data))
}
