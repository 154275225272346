<template>
    <div>
        <div class="box-contain">
            <Breadcrumb></Breadcrumb>
            <div class="box-contain-div">
                <div class="box-contain-div-tit">使用说明</div>
                <div class="instru-titOne">一.用户注册：</div>
                <div class="instru-content">
                    1. 点击网站header的“注册”按钮；<br />
                    2. 进入注册页面（如下）后，
                </div>
                <div class="instru-img">
                    <img src="../../assets/img/userInformatica1.png" />
                </div>
                <div class="instru-content">
                    按要求填写：登录名、密码、确认密码、邮箱、手机号、短信验证码。<br />
                    然后阅读并同意《汽车维修技术注册协议》；<br />
                    最后点击“注册”，如提交信息符合要求，则需根据提示错误内容进行修改；如提交信息符合要求，则会得到注册成功的提示。
                </div>

                <div class="instru-titOne"> 二.忘记密码怎么办？</div>
                <div class="instru-content">
                    当忘记密码，请按以下操作步骤进行密码找回：<br />
                    1 打开网站的登录页面；<br />
                    2 点击登录面板中的“忘记密码？”按钮，进入找回密码流程：
                </div>
                <div class="instru-img">
                    <img src="../../assets/img/userInformatica2.png" />
                </div>
                <div class="instru-content">
                    1) 第一步、“输入注册手机号”：<br />
                    2) 第二步、如果手机号存在，则会进入到“手机验证”，手机验证页面会不完全显示用户名及其已验证的手机号码，点击“获取短信验证码”按钮，该手机号码将收到验证短信，将短信中的验证码（4位数字）输入短信验证码输入框内，点击“提交”按钮；<br />
                    3) 第三步、手机短信验证成功后，进入找回密码“设置新密码”：<br />
                    按要求设置好密码（1、密码符合要求；2、两次输入密码相同）后，点击“找回密码”按钮<br />
                    4) 新密码设置成功后即默认成功登录。
                </div>



            </div>

        </div>
        <Vfooter />
    </div>
</template>
<script>
import Vfooter from "@/components/footer.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
export default {

    data() {
        return {
            content: {},
        };
    },
    components: {
        Vfooter,
        Breadcrumb,
    },
    mounted() {
    },
    methods: {

    },
};
</script>
<style lang="scss" scoped>
  .instru-titOne {
      font-weight: 700;
      line-height: 30px;

  }
    .instru-content {
        line-height: 30px;

    }
    .instru-img {
       padding: 20px 0;
        margin: auto;
        text-align: center;
        img {
            max-width: 90%;

        }
    }
</style>
