<template>
  <div>
    <div class="other-list">
      <h1 class="other-list-title">订单详情</h1>
      <div class="other-list-inner">
          <div  class="other-list-inner-box">
            <h2 class="other-list-inner-h2">订单信息</h2>
            <div class="other-list-inner-text">
              <span>订单编号：{{ detailsData.orderId }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>订单状态：
                   <span v-if="detailsData.orderStaus == '1'">已支付</span><span v-else-if="detailsData.orderStaus == '2'">未支付</span><span v-else>已取消</span>
              </span>
            </div>
            <div class="other-list-inner-text">
              <span>订单时间：{{ detailsData.createTime }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>商品金额：{{ detailsData.money }}</span>
            </div>
          </div>
      </div>
      <h1 class="other-list-table-title">商品信息</h1>
      <div class="other-list-table">
        <el-table :data="tableData" :header-cell-style="{background:'#4b4bfe',color:'#fff'}">
          <el-table-column
              type="index"
              width="50"
              label="序号"></el-table-column>
          <el-table-column
              prop="brandName"
              label="购买商品">
          </el-table-column>
          <el-table-column
              prop="mealName"
              label="套餐"></el-table-column>
          <el-table-column
              label="订单有效期">
            <template slot-scope="{row}">
              {{row.startTime}}至{{row.endTime}}
            </template>
          </el-table-column>
          <el-table-column
              prop="money"
              label="订单金额"></el-table-column>
          <el-table-column
              prop="manufacturerName"
              label="车企"
              width="50"></el-table-column>
          <el-table-column
              label="数量">
            <template>
              1
            </template>
          </el-table-column>
          <el-table-column
              prop="money"
              label="合计"></el-table-column>
        </el-table>
      </div>
    </div>

  </div>
</template>
<script>
// 获取个人中心请求接口
import {  myOrdersDetails } from "@/api/personalCenter/index.js";

import {getUserInfo} from "@/utils/auth.js";
//字典管理
import {} from "@/api/dict.js";

export default {
  components: {},
  data() {
    return {
      tableData: [{orderId:"66",brandName:['4465'],orderStaus:'0'}],
      userInfo:{},
      detailsData:{}
    };
  },
  computed: {},
  created() {
    this.userInfo = getUserInfo()
    this.init()
  },
  methods: {
    init(){
      let orderId = this.$route.query.id
      myOrdersDetails(orderId).then(res=>{
        if (res.code == 200){
          this.detailsData = res.data
          this.tableData =res.data.orderList
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.other-list {
  .other-list-title {
    margin: 0 20px 25px;
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #555;
    font-weight: bold;
    font-size: 20px;
  }
  .other-list-table{
    margin: 0 20px;
  }
}
.other-list-inner{
  font-size: 15px;
  margin: 0 20px 25px;
  border-bottom: 1px solid #ccc;
  .other-list-inner-box{
    margin-left: 20px;
    color: #666;
  }
  .other-list-inner-h2{
    font-size: 20px;
    margin-bottom: 25px;
  }
  .other-list-inner-text{
    margin-bottom: 25px;
    margin-left: 10px;
  }
}
.other-list-table-title{
  margin-left: 40px;
  margin-bottom: 25px;
  font-size: 20px;
}
</style>
