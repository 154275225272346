<template>
<div>
  支付成功
</div>
</template>

<script>
export default {
  name: "success"
}
</script>

<style scoped>

</style>
