

/**
 * @description: 公告，政策法规，行业资讯
 * @param {*}
 * @return {*}
 */
import request from '@/utils/request'
// // 公告列表
// export function announceList(query) {
//     return request({
//         url: '/user/index/noticeList',
//         method: 'get',
//         params:query,
//     })
// }
// 政策法规列表
export function policyList(query) {
    return request({
        url: '/api/user/register',
        method: 'get',
        params:query,
    })
}
// 行业资讯列表
export function informationList(query) {
    return request({
        url: '/api/user/register',
        method: 'get',
        params:query,
    })
}
// 公告文章
export function announceDetail(id) {
    return request({
        url: '/user/index/getNoticeInfo/'+id,
        method: 'get',
    })
}
// 政策法规资讯文章
export function policyDetail(id) {
    return request({
        url: '/user/index/getIndustryInfo/'+id,
        method: 'get',

    })
}
// // 公告文章
// export function informationDetail(query) {
//     return request({
//         url: '/api/user/register',
//         method: 'get',
//         params:query,
//     })
// }
