<template>
  <div>
    <div class="other-list">
      <h1 class="other-list-title">用户反馈</h1>
    </div>
    <div class="feedbacks-btn-box">
      <button class="feedbacks-btn" @click="addBtn">增加反馈</button>
    </div>
    <div class="feedbacks-table">
      <el-table :data="tableData" :header-cell-style="{background:'#4b4bfe',color:'#fff'}" v-loading="loading">
        <el-table-column
            type="index"
            width="50"
            label="序号"></el-table-column>
        <el-table-column
            prop="feedbackType"
            label="反馈类型"
            >
          <template slot-scope="{row}">
            <span v-if="row.feedbackType == '1'">建议</span>
            <span v-else-if="row.feedbackType == '2'">问题反馈</span>
            <span v-else>投诉</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="replyType"
            label="状态"></el-table-column>
        <el-table-column
            prop="updateTime"
            label="反馈时间"></el-table-column>
        <el-table-column
            label="反馈操作"
            width="100">
          <template slot-scope="{row}">
            <a href="javascript:;" @click="show(row)">查看</a>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>
<script>
// 获取个人中心请求接口
import { feedbackList  } from "@/api/personalCenter/index.js";

import {getUserInfo} from "@/utils/auth.js";
//字典管理
import {} from "@/api/dict.js";

export default {
  components: {},
  data() {
    return {
      tableData: [{orderId:"66",brandName:['4465'],orderStaus:'0'}],
      userInfo:{},
      loading:false
    };
  },
  computed: {},
  created() {
    this.userInfo = getUserInfo()
    this.feedbackList()
  },
  methods: {
    feedbackList(){
      this.loading = true
      feedbackList().then(res=>{
          if (res.code == 200){
            this.tableData = res.rows
          }
          this.loading = false
      })
    },
    show(item){
      this.$router.push({path:"/personalCenter/feedbackDetails",query:{id:item.id}})
    },
    addBtn(){
      this.$router.push({path:"/personalCenter/feedbackAdd"})
    }

  },
};
</script>
<style lang="scss" scoped>
.other-list {
  .other-list-title {
    margin: 0 20px 25px;
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #555;
    font-weight: bold;
    font-size: 20px;
  }
}
.feedbacks-btn-box{
    text-align: right;
  margin: 10px 20px;
}
.feedbacks-btn{
    width: 143px;
    height: 43px;
    border: none;
    font-size: 15px;
    background: #0099ff;
    cursor: pointer;
    border-radius: 5px;
  color: #fff;
}
.feedbacks-table{
  margin: 0 20px;
}
</style>
