
import { asyncRoutes, constantRoutes } from "@/router"

export function filterAsyncRouters(routerlist) {
    const routers = []
    // const filterRouter = routerlist.filter(item => {
    //     if (item.visible == '0') {
    //         return item
    //     }
    // })
    // filterRouter.forEach(item => {
    //     asyncRoutes.forEach(citem => {
    //         if (item.name == citem.name) {
    //             citem.meta.id = item.id
    //             routers.push(citem)
    //         }
    //     })
    // });
    asyncRoutes.forEach(citem => {
        routers.push(citem)
    })
    return routers
}


const state = {
    routes: [],
    addRoutes: [],
    pRouterId: ''
}
const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
    },
    SET_ROUTER_ID: (state, id) => {
        state.pRouterId = id
    }
}

const actions = {
    generateRoutes({ commit }, asyncRouters) {
        return new Promise(resolve => {
            let accessedRoutes = filterAsyncRouters(asyncRouters)
            commit('SET_ROUTES', accessedRoutes)
            resolve(accessedRoutes)
        })
    },
    getRouter({ commit, state }, item) {
        let id = state.routes.filter(citem => citem.name == item)
        commit('SET_ROUTER_ID', id[0].meta.id ? id[0].meta.id : 0)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
