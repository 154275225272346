/*
 * @Author: your name
 * @Date: 2021-07-16 13:29:59
 * @LastEditTime: 2021-07-20 14:58:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/main.js
 */
import 'babel-polyfill'
require('es6-promise').polyfill();
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./permission";
// 富文本组件
import Editor from "@/components/Editor"

import './assets/css/style.css'
import './plugins/element.js'
import './plugins/video.js'
//字体图标
import './assets/iconfont/iconfont.css'
import 'font-awesome/css/font-awesome.css'

import SvgIcon from './assets/icons/index.js'

import Vlogin  from './components/login/index.js'

// 分页组件
import Pagination from "./components/Pagination";
import './mixin/global.js'
// 引入图表工具  以及组织架构图标工具
// import  * as G2 from "@antv/g2";
// import * as X6 from "@antv/x6";
import { download } from '@/utils/request'
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad);

import MD5 from "js-md5"
Vue.prototype.$MD5 = MD5;
// 分页组件
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels } from "./utils/ruoyi";
// 全局方法挂载
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectDictLabels = selectDictLabels;
Vue.prototype.download = download;
Vue.component('Pagination', Pagination);
Vue.use(SvgIcon);
Vue.component('Editor', Editor);
Vue.use(Vlogin);
// Vue.prototype.G2 = G2
// Vue.prototype.X6 = X6
Vue.prototype.$Vlogin = Vlogin;
Vue.prototype.$actionUp = process.env.VUE_APP_BASE_API;
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
